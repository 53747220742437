// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-users-list__table-actions__container {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
}

.home-users-list__table-actions {
    display: flex;
    align-items: center;
    gap: 12px;
}

.home-users-list__table-actions svg {
    font-size: 1.5rem;
    cursor: pointer;
    color: #1D71B8;
}

.home-users-list__no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.home-users-list__no-results span {
    font-size: 1.5rem;
}

.home-users-list__search-user svg {
    font-size: 1.5rem;
}

.home-users-list__container .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-users-list/HomeUsersListComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".home-users-list__table-actions__container {\n    display: flex;\n    align-items: center;\n    gap: 16px;\n    justify-content: center;\n}\n\n.home-users-list__table-actions {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n}\n\n.home-users-list__table-actions svg {\n    font-size: 1.5rem;\n    cursor: pointer;\n    color: #1D71B8;\n}\n\n.home-users-list__no-results {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n\n.home-users-list__no-results span {\n    font-size: 1.5rem;\n}\n\n.home-users-list__search-user svg {\n    font-size: 1.5rem;\n}\n\n.home-users-list__container .card-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
