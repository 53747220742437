// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-dynamic-input-detail {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 12px;
}

.home-dynamic-input-detail__questions-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    align-items: center;
}

.home-dynamic-input-detail__question {
    width: 90%;
}

.home-dynamic-input-detail__actions {
    display: flex;
    justify-content: flex-end;
}

.home-dynamic-input-detail__questions-container  svg {
    font-size: 1.5rem;
}

`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-dynamic-form/home-dynamic-input-detail/HomeDynamicInputDetailComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".home-dynamic-input-detail {\n    border: 1px solid #ced4da;\n    border-radius: 0.375rem;\n    padding: 12px;\n}\n\n.home-dynamic-input-detail__questions-container {\n    display: flex;\n    justify-content: space-between;\n    margin-bottom: 24px;\n    align-items: center;\n}\n\n.home-dynamic-input-detail__question {\n    width: 90%;\n}\n\n.home-dynamic-input-detail__actions {\n    display: flex;\n    justify-content: flex-end;\n}\n\n.home-dynamic-input-detail__questions-container  svg {\n    font-size: 1.5rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
