import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const checkUserToken = () => {
      const userToken = localStorage.getItem('token');
      if (!userToken || userToken === 'undefined') {
        setIsLoggedIn(false);
        return navigate('/auth/login');
      }
      setIsLoggedIn(true);
    };
    checkUserToken();
  }, [navigate]);
  return (
    <React.Fragment>
      {
        isLoggedIn ? props.children : null
      }
    </React.Fragment>
  );
}
export default ProtectedRoute;