// React
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// Components
import NavBarComponent from "./shared/components/navbar/NavBarComponent";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem('token');
    if (!userToken || userToken === 'undefined') {
        setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }    
  }
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return (
    <React.Fragment>
      {isLoggedIn && <NavBarComponent />}
      <Outlet />
    </React.Fragment>
  );
}
export default App;