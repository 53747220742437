// React
import React from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { postCreateUser, getUsers } from "../../slice/HomeSlice";

// Bootstrap
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";

// Styles
import './HomeCreateUserModalComponent.styles.css'

function HomeCreateUserModalComponent(props) {

	// React redux states
	const dispatch = useDispatch();
	const loader = useSelector(state => state.home.loader);

	// Component states
	const [validated, setValidated] = React.useState(false);

	const submitUserRegisterData = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			const formElement = document.querySelector('#createUserForm');
			const formData = new FormData(formElement);
			const formDataJSON = Object.fromEntries(formData);
			await dispatch(postCreateUser(formDataJSON));
			await dispatch(getUsers()); //TODO revisar actualizar lista de usarios
		}
    setValidated(true);
  }

	return ( 
		<ModalComponent 
			show={props.show}
			onHide={props.onHide}
			title='Crear usuario'
		>
			<Form
				id="createUserForm" 
				onSubmit={submitUserRegisterData}
				noValidate 
				validated={validated}
			>                    
				<Form.Group className="mb-3">
					<Form.Label>Nombre(s)</Form.Label>
					<Form.Control 
						type="text"
						placeholder=""
						name="first_name"
						defaultValue={''}
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Apellidos</Form.Label>
					<Form.Control 
						type="text"
						placeholder=""
						name="last_name"
						defaultValue={''}
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Correo electrónico</Form.Label>
					<Form.Control 
						type="email"
						placeholder="ej. usuario@mail.com"
						name="email"
						defaultValue={''}
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Usuario</Form.Label>
					<Form.Control 
						type="text"
						placeholder="ej. usuarioInterglobal"
						name="username"
						defaultValue={''}
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Teléfono</Form.Label>
					<Form.Control 
						type="phone"
						placeholder=""
						name="phone"
						defaultValue={''}
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Rol</Form.Label>
					<Form.Select name='role' required>
						<option value="" >Seleccionar rol</option>
						{ props.roles?.map((option) => (
								<option key={option.id} value={option.caption}>{option.caption}</option>    
							))
						}
					</Form.Select>
					<Form.Control.Feedback type="invalid">Seleccione una opción.</Form.Control.Feedback>
				</Form.Group>
				<div className="home-create-user-modal__btns-action">
					<Button variant="secondary" onClick={() => {props.onHide()}}>
							Cancelar
					</Button>
					<Button disabled={loader} variant="primary" type="submit">
							{!loader ? 'Crear' : <Spinner animation="border" variant="light" size="sm" />}
					</Button>
				</div>
			</Form>
		</ModalComponent>
	)
}

export default HomeCreateUserModalComponent;