// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-dashboard__container {
    margin: 32px;   
}

.home-dashboard__card {
    padding: 16px;
    border-radius: 8px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    min-width: 150px;
    color: #3f80ea;
    height: 150px; 
    display: flex;  
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    gap: 24px;
    cursor: pointer;
}

.home-dashboard__card svg {
    color: #232C77;
    font-size: 42px;
}

.home-dashboard__card.title h5 {
    font-size: 24px;
    color: #232C77;
}

.home-dashboard__card.title span {
    color: #232C77;
    text-align: center;
    font-family: Segoe UI;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.home-dashboard__title {
    margin-bottom: 32px;
    color: #05092B;
}

.home-dashboard__card-container {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 32px;
    justify-content: center;
}

.home-dashboard__card h5 {
    font-size: 1rem;
    color: #232C77;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-dashboard/HomeDashboardComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,2CAA2C;IAC3C,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".home-dashboard__container {\n    margin: 32px;   \n}\n\n.home-dashboard__card {\n    padding: 16px;\n    border-radius: 8px;\n    background-color: white;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n    min-width: 150px;\n    color: #3f80ea;\n    height: 150px; \n    display: flex;  \n    flex-direction: column;\n    align-items: center; \n    justify-content: center;\n    gap: 24px;\n    cursor: pointer;\n}\n\n.home-dashboard__card svg {\n    color: #232C77;\n    font-size: 42px;\n}\n\n.home-dashboard__card.title h5 {\n    font-size: 24px;\n    color: #232C77;\n}\n\n.home-dashboard__card.title span {\n    color: #232C77;\n    text-align: center;\n    font-family: Segoe UI;\n    font-size: 18px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n}\n\n.home-dashboard__title {\n    margin-bottom: 32px;\n    color: #05092B;\n}\n\n.home-dashboard__card-container {\n    display: flex;\n    gap: 24px;\n    flex-wrap: wrap;\n    margin-top: 32px;\n    justify-content: center;\n}\n\n.home-dashboard__card h5 {\n    font-size: 1rem;\n    color: #232C77;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
