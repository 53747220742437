// React
import React from "react";
import { createPortal } from "react-dom";

// Bootstrap
import Modal from 'react-bootstrap/Modal';

function ModalComponent (props) {
	if (!props.show) {
			return null;
	}
	return createPortal (
		<Modal 
			id="modal" 
			show={props.show} 
			onHide={() => {props.onHide()}} 
			backdrop="static" 
			size={props.size ? props.size : 'md'}
			fullscreen={props.fullscreen ? true : false}
			centered={props.centered ? true : false}
		>
			<Modal.Header closeButton={props.closeButton ? props.closeButton : false}>
				<Modal.Title>{props.title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{props.children}
			</Modal.Body>  
		</Modal>,
		document.getElementById("modal-root")
	)
}

export default ModalComponent;