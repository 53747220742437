import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../app/auth/slice/AuthSlice';
import homeReducer from '../app/home/slice/HomeSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
  },
});
