// React
import React, { useEffect, useState, useRef } from "react";

// Components
import CustomDropdownSelectComponent from "../../../../shared/components/custom-dropdown-select/CustomDropdownSelectComponent";

// React Bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';

// React Select
import AsyncSelect from 'react-select/async';

// React redux
import { useDispatch, useSelector } from 'react-redux';
import { searchUsers, searchAssets, postTransfer, setClearPostTransferStatus, setIsCreateLocation } from "../../slice/HomeSlice";

// Icons
import { MdCloudUpload, MdClose } from 'react-icons/md';

// Styles
import './HomeTransferAssetComponent.styles.css'

function HomeTransferAssetComponent (props) {
    const { handleNavbarLink } = props;

    const formRef = useRef(null);
    const fileInput = React.createRef();

    // TODO validar campos

    // React redux states
	const dispatch = useDispatch();
    const search_users_result = useSelector(state => state.home.search_users_result);
    const search_assets_result = useSelector(state => state.home.search_assets_result);
    const post_transfer_status = useSelector(state => state.home.status.transfers.post_transfer_status);
    const transfers_form_locations = useSelector(state => state.home.transfers_form_locations);

    // Component states
	const [validated, setValidated] = useState(false);
    const [userResults, setUserResults] = useState([]);
    const [assetsResults, setAssetsResults] = useState([]);
    const [assetIsInvalid, setAssetIsInvalid] = useState(false);
    const [userDeliversOriginIsInvalid, setUserDeliversOriginIsInvalid] = useState(false);
    const [userDeliveryDestinationIsInvalid, setUserDeliveryDestinationIsInvalid] = useState(false);
    const [formFiles, setFormFiles] = useState();
    const [assetsArray, setAssetsArray] = useState([]);
    const [assetsLocationArray, setAssetsLocationArray] = useState([]);
    const [assetLocationOrigin, setAssetLocationOrigin] = useState();
    const [assetLocationDestination, setAssetLocationDestination] = useState();
    
    useEffect(() => {
        let user_results = [];
        if(search_users_result) {
            search_users_result.forEach(user => {
                let obj = {
                    label: `${user.first_name} ${user.last_name} (${user.username})`,
                    value: user.id
                }
                user_results.push(obj);                
            });
            setUserResults(user_results);
        }
        let assets_results = [];
        if(search_assets_result?.results) {
            search_assets_result.results.forEach(asset => {
                let obj = {
                    value: `(${asset.code}) ${asset.name}`,
                    id: asset.id,
                    code: asset.code,
                }
                assets_results.push(obj);                
            });
            setAssetsResults(assets_results);
        }
    },[search_users_result, search_assets_result?.results]);
        
    useEffect(() => {
        if(post_transfer_status === 'succeeded') {
            dispatch(setClearPostTransferStatus());
            handleNavbarLink('manage_transfer');
        }
    }, [dispatch, post_transfer_status, handleNavbarLink]);

    useEffect(() => {
        if(transfers_form_locations?.results) {
            let location_results = [];
            transfers_form_locations.results.forEach(location => {
                let obj = {
                    value: location.name,
                    id: location.id,
                }
                location_results.push(obj);                
            });
            setAssetsLocationArray(location_results);
        }

    }, [transfers_form_locations]);

    const handleAsyncSelectValidations = (formDataJSON) => {
        if(formDataJSON.asset === '') {
            setAssetIsInvalid(true);
        } else { 
            setAssetIsInvalid(false);
        }
        if(formDataJSON.user_delivers_origin === '') {
            setUserDeliversOriginIsInvalid(true);
        } else { 
            setUserDeliversOriginIsInvalid(false);
        }
        if(formDataJSON.user_delivery_destination === '') {
            setUserDeliveryDestinationIsInvalid(true);
        } else { 
            setUserDeliveryDestinationIsInvalid(false);
        }
    }

    const submitForm = async (event) => {
        const formElement = document.querySelector('#transferAsset');
        const formData = new FormData(formElement);
        const filesFormData = new FormData();
        const formDataJSON = Object.fromEntries(formData);        
        handleAsyncSelectValidations(formDataJSON);
        event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
            event.preventDefault();
			event.stopPropagation();
		} else {
            let newAssetsArray = {};
            assetsArray.forEach(asset => {
                newAssetsArray[asset.id] = asset.qty;
            });
            let obj = {
                asset_data: newAssetsArray,
                date_warehouseman_sends: formDataJSON.date_warehouseman_sends,
                user_delivers_origin: formDataJSON.user_delivers_origin,
                origin: assetLocationOrigin.id,
                destination_site: assetLocationDestination.id,
                observations: formDataJSON.observations,
            }
            filesFormData.append('data', JSON.stringify(obj));            
            if(formFiles) {
                for (let file of formFiles) {
                    const image = new File([file], `${file.name}`, {
                        type: ''
                    })
                    filesFormData.append('file', image);
                }
            }
            dispatch(postTransfer(filesFormData));
            //formRef.current.reset();
        }
        setValidated(true);
    }

    const filterOptions = (inputValue, search_type) => {
        if(search_type === 'search_asset') {
            return  assetsResults?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
        }
        if(search_type === 'search_user') {
            return  userResults?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
        }
    }

    const promiseOptions = (inputValue, search_type) => {
        setAssetIsInvalid(false);
        setUserDeliversOriginIsInvalid(false);
        setUserDeliveryDestinationIsInvalid(false);
        if(search_type === 'search_asset' && inputValue !== '') {
            dispatch(searchAssets(inputValue))
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve(filterOptions(inputValue, search_type));
                }, 1000);
            });
        }
        if(search_type === 'search_user' && inputValue !== '') {
            dispatch(searchUsers(inputValue))
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve(filterOptions(inputValue, search_type));
                }, 1000);
            });
        }        
    }

    const handleSearchAsset = (value) => {
        dispatch(searchAssets(value))
    }

    const handleSelectOption = (option) => {
        if(!assetsArray.some(asset => asset.code === option.code)) {
            let obj = {
                id: option.id,
                qty: '1',
                value: option.value,
                code: option.code
            }
            setAssetsArray([...assetsArray, obj]);
        }
    }

    const handleChangeQty = (qty, asset_id) => {
        let newAssetsArray = [];
        assetsArray.forEach(asset => {
            if(asset.id === asset_id) {
                asset.qty = qty;
                newAssetsArray.push(asset);
            } else {
                newAssetsArray.push(asset);
            }
        });
        setAssetsArray(newAssetsArray);
    }

    const handleDeleteAssetQty = (asset_to_delete) => {
        const updatedAssets = assetsArray.filter(asset => asset.id !== asset_to_delete.id); 
        setAssetsArray(updatedAssets);
    }

    return (
        <Form 
            className="home-transfer-asset__container"
            onSubmit={submitForm}
            noValidate 
            validated={validated}
            id="transferAsset"
            ref={formRef}
        >
            <Form.Group className="mb-3">
                <Form.Label>Fecha y hora</Form.Label>
                <Form.Control type="datetime-local" name='date_warehouseman_sends' onKeyDown={(e) => e.preventDefault()} required/>
                <Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" >
                <Form.Label>Mercancía Nùmero de Referncia y Cantidad</Form.Label>
                <CustomDropdownSelectComponent 
                    options={assetsResults}
                    handleSearchInput={ (value) => handleSearchAsset(value) }
                    name='test_search_select' 
                    id={'test_search_select'}
                    placeholder='Buscar mercancia'
                    //maxLength={3}
                    required={false} 
                    disabled={false}
                    //defaultValue={''}
                    noveltyAction={''}
                    handleClearSearchArray={() => console.log('delete')}
                    handleSelectOption={(option) => handleSelectOption(option)}
                    clearValue={true}
                />
                { assetsArray.length > 0 &&
                    <div  className="home-transfer-asset__assets-table">
                        <Table striped responsive bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Codigo</th>
                                    <th>Activo</th>
                                    <th>Cantidad</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                { assetsArray.map((asset, index) => (
                                    <tr key={index} >
                                        <td>{asset.code}</td>
                                        <td>{asset.value}</td>
                                        <td><Form.Control type='number' required defaultValue={'1'} value={asset.qty} min={1} onChange={(e) => handleChangeQty(e.target.value, asset.id)} /></td>
                                        <td className="home-transfer-asset__assets-table__close-icon"><MdClose onClick={() => handleDeleteAssetQty(asset)} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                }
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Información de quien despacha</Form.Label>
                <AsyncSelect 
                    placeholder='Buscar usuario' 
                    name="user_delivers_origin" 
                    noOptionsMessage={()=> 'No hay resultados'} 
                    cacheOptions 
                    defaultOptions 
                    loadOptions={(value) => promiseOptions(value, 'search_user')} 
                    loadingMessage={() => 'Cargando...'}
                    required
                    isClearable
                />
                { userDeliversOriginIsInvalid && <Form.Label className="home-transfer-asset__warning-msg">Campo requerido.</Form.Label>}
            </Form.Group>

            <Form.Group className="mb-3 ">
                <Form.Label>Origen del despacho</Form.Label>
                <div className="home-transfer-asset__create-location">
                    <CustomDropdownSelectComponent 
                        options={assetsLocationArray}
                        handleSearchInput={ (value) => console.log(value) }
                        name='origin' 
                        id={'origin_select'}
                        placeholder='Buscar origen'
                        //maxLength={3}
                        required={true} 
                        disabled={false}
                        //defaultValue={''}
                        noveltyAction={''}
                        handleClearSearchArray={() => console.log('delete')}
                        handleSelectOption={(option) => setAssetLocationOrigin(option)}
                    />                
                    <Button size="sm" onClick={() => props.handleAddLocation('add_origin')}>Agregar origen</Button>
                </div>
            </Form.Group>

            <Form.Group className="mb-3 ">
                <Form.Label>Sitio de destino</Form.Label>
                <div className="home-transfer-asset__create-location">
                    <CustomDropdownSelectComponent 
                        options={assetsLocationArray}
                        handleSearchInput={ (value) => console.log(value) }
                        name='destination_site' 
                        id={'destination_select'}
                        placeholder='Buscar destino'
                        //maxLength={3}
                        required={true} 
                        disabled={false}
                        //defaultValue={''}
                        noveltyAction={''}
                        handleClearSearchArray={() => console.log('delete')}
                        handleSelectOption={(option) => setAssetLocationDestination(option)}
                    />                
                    <Button size="sm" onClick={() => props.handleAddLocation('add_destination')}>Agregar destino</Button>
                </div>
            </Form.Group>

            <Form.Group className="mb-3">
                <Form.Label>Verificación salida portería</Form.Label>
                <Form.Control as="textarea" rows={3} name='observations'/>
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Subir imagenes</Form.Label>
                <div className="home-transfer-asset__input-img">
                    <label htmlFor="inputGroupFile"><MdCloudUpload /></label>
                    <input 
                        style={{display: "none"}}
                        type="file"
                        multiple={true}
                        id="inputGroupFile"
                        name="file"
                        onChange={(e) => setFormFiles(e.target.files)}
                        ref={fileInput}
                        accept="image/*,.pdf"
                    />
                    <Form.Control type="text" placeholder={formFiles?.length > 0 ? `${formFiles?.length} archivos seleccionados` : 'No hay archivos seleccionados'} name='file'/>
                </div>
            </Form.Group>            
            <div className="home-transfer-asset__btn-actions">
                <Button variant="primary" type="submit">Crear Traslado</Button>
            </div>
        </Form>
    )
}

export default HomeTransferAssetComponent;