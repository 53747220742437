// React
import React from "react";
import { useNavigate } from "react-router-dom";

// React redux
import { useSelector, useDispatch } from 'react-redux'
import { patchUpdateUserPassword } from "../../slice/HomeSlice";

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";

// Bootstrap
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// Actions
import { setToken, setAuthStatus } from "../../../auth/slice/AuthSlice";
import { setUser } from "../../slice/HomeSlice";

// Styles
import './HomeUpdatePasswordModalComponent.styles.css'

function HomeUpdatePasswordModalComponent(props) {
	
	// React redux states
	const dispatch = useDispatch();
	const user = useSelector(state => state.home.user);
	const loader = useSelector(state => state.home.loader);

	const [input, setInput] = React.useState({
		password: '',
		confirmPassword: '',
	});
    
	const [error, setError] = React.useState({
		password: '',
		confirmPassword: '',
	});
    
	const onInputChange = (e) => {
		const { name, value } = e.target;
		setInput((prev) => ({
			...prev,
			[name]: value,
		}));
		validateInput(e);
	};
    
	const validateInput = (e) => {  
		let { name, value } = e.target;
		setError((prev) => {
			const stateObj = { ...prev, [name]: '' };
			switch (name) {
				case 'password':
					if (!value) {
						stateObj[name] = 'Campo obligatorio.';
					} else if (input.confirmPassword && value !== input.confirmPassword) {
						stateObj['confirmPassword'] = 'Las contraseñas no coinciden.';
					} else {
						stateObj['confirmPassword'] = input.confirmPassword
							? ''
							: error.confirmPassword;
					}
					break;
				case 'confirmPassword':
					if (!value) {
						stateObj[name] = 'Campo obligatorio.';
					} else if (input.password && value !== input.password) {
						stateObj[name] = 'Las contraseñas no coinciden.';
					}
					break;
				default:
					break;
			}
			return stateObj;
		});
	};
    
	const submitUpdateUserPassword = async (event) => {
		event.preventDefault();
		const formElement = document.querySelector('#updateUserPassword');
		const formData = new FormData(formElement);
		const formDataJSON = Object.fromEntries(formData);
		let password = {
			target: {
				name: 'password',
				value: formDataJSON.password,                
			}
		}
		let confirmPassword = {
			target: {
				name: 'confirmPassword',
				value: formDataJSON.confirmPassword,                
			}
		}
		validateInput(password);
		validateInput(confirmPassword);
		if(!error.password && !error.confirmPassword) {
			let obj = {
				old_password: user.email,
				new_password: input.password,
				user_id: user.id,
			}
			dispatch(patchUpdateUserPassword(obj));
		}
	}
        
	const navigate = useNavigate();
	const logout = () => {
		localStorage.clear();
		dispatch(setToken(undefined));
		dispatch(setUser(undefined));
		dispatch(setAuthStatus(''));
		navigate('/auth/login');
	}
	return(
		<ModalComponent
			show={props.show}
			onHide={props.onHide}
			title='Actualizar contraseña'
		>
			<div className="home-update-password__text" >Antes de continuar por favor actualice su contraseña</div>
			<Form
				id="updateUserPassword" 
				onSubmit={submitUpdateUserPassword}
			>
				<Form.Group className="mb-3">
					<Form.Label>Nueva contraseña</Form.Label>
					<Form.Control 
						type="password"
						name="password"
						value={input.password}
						onChange={onInputChange}
						onBlur={validateInput}  
						isInvalid={error.password}               
					/>
					{error.password && <Form.Control.Feedback type="invalid">{error.password}</Form.Control.Feedback>}
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Confirmar contraseña</Form.Label>
					<Form.Control 
						type="password"
						name="confirmPassword"
						value={input.confirmPassword}
						onChange={onInputChange}
						onBlur={validateInput}
						isInvalid={error.confirmPassword}
					/>                 
						{error.confirmPassword && <Form.Control.Feedback type="invalid">{error.confirmPassword}</Form.Control.Feedback>}
				</Form.Group>
				<div className="home-update-password__btns-action">
					<Button variant="secondary" onClick={() => {props.onHide(); logout();}}>Salir</Button>
					<Button disabled={loader}  variant="primary" type="submit">{!loader ? 'Guardar' : <Spinner animation="border" variant="light" size="sm" />}</Button>
				</div>
			</Form>				
		</ModalComponent>
	)
}

export default HomeUpdatePasswordModalComponent;