
// React
import React, { useRef } from 'react';
import { connect } from 'react-redux';

// React redux
import { useSelector, useDispatch } from 'react-redux';
import {
	getUser,
	setIsCreateUser,
	setIsUpdateUserPassword,
	setOnCloseToastMessage,
	getNoveltyTypes,
	setIsEditNovelty,
	setNoveltyForm,
	getNoveltyFormByType,
	getNovelties,
	getEditNovelty,
	setIsNoveltyForm,
	setEditNoveltyForm,
	setIsCarouselModal,
	setIsAssetModal,
	getAssets,
	getAsset,
	setAssetActionType,
	getTransfers,
	setIsTranfer,
	setIsViewTransfer,
	getTransfersFormLocations,
	setIsCreateLocation,
	setIsRoleAction,
	getUsers,
	getUserRoles,
	setClearGeomapCoordinates,
} from '../slice/HomeSlice';

// Components
import HomeNavbarComponent from '../components/home-navbar/HomeNavbarComponent';
import HomeDynamicComposeFormComponent from '../components/home-dynamic-form/home-dynamic-compose-form/HomeDynamicComposeFormComponent';
import HomeNoveltiesTableComponent from '../components/home-novelties-table/HomeNoveltiesTableComponent';
import HomeDashboardComponent from '../components/home-dashboard/HomeDashboardComponent';
import HomeAssetsListComponent from '../components/home-assets-list/HomeAssetsListComponent';
import HomeTransferAssetComponent from '../components/home-transfer-asset/HomeTransferAssetComponent';
import HomeTransfersListComponent from '../components/home-transfers-list/HomeTransfersListComponent';
import HomeUsersListComponent from '../components/home-users-list/HomeUsersListComponent';

// Modals
import HomeUpdatePasswordModalComponent from '../components/home-update-password-modal/HomeUpdatePasswordModalComponent';
import HomeCreateUserModalComponent from '../components/home-create-user-modal/HomeCreateUserModalComponent';
import HomeEditNoveltyModalComponent from '../components/home-edit-novelty-modal/HomeEditNoveltyModalComponent';
import HomeCarouselModalComponent from '../components/home-carousel-modal/HomeCarouselModalComponent';
import HomeAssetsModalComponent from '../components/home-assets-modal/HomeAssetsModalComponent';
import HomeFinalizeTransferModalComponent from '../components/home-finalize-transfer-modal/HomeFinalizeTransferModalComponent';
import HomeViewFinalizedTransferModalComponent from '../components/home-view-finalized-transfer-modal/HomeViewFinalizedTransferModalComponent';
import HomeCreateTransferLocationModalComponent from '../components/home-create-transfer-location-modal/HomeCreateTransferLocationModalComponent';
import HomeRoleActionModalComponent from '../components/home-role-action-modal/HomeRoleActionModalComponent';
import HomeGeomapComponent from '../components/home-geomap/HomeGeomapComponent';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
import Col from 'react-bootstrap/Col';
import { Form } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

// Styles
import './HomeView.styles.css';

function HomeView(props) {

	const formRef = useRef(null);
	// React home redux states
	const dispatch = useDispatch();
	const home_user = useSelector(state => state.home.user);
	const auth_token = useSelector(state => state.auth.token);
	const is_create_user = useSelector(state => state.home.modals.is_create_user);
	const user_roles = useSelector(state => state.home.user_roles);
	const is_update_user_password = useSelector(state => state.home.modals.is_update_user_password);
	const is_toast_message = useSelector(state => state.home.is_toast_message);
	const toast_message_type = useSelector(state => state.home.toast_message_type);
	const toast_message = useSelector(state => state.home.toast_message);
	const novelty_types = useSelector(state => state.home.novelty_types);
	const is_edit_novelty = useSelector(state => state.home.modals.is_edit_novelty);
	const novelty_form = useSelector(state => state.home.novelty_form);
	const edit_novelty_form = useSelector(state => state.home.edit_novelty_form);
	const is_novelty_form = useSelector(state => state.home.is_novelty_form);
	const is_carousel = useSelector(state => state.home.modals.is_carousel);
	const is_asset = useSelector(state => state.home.modals.is_asset);
	const is_transfer = useSelector(state => state.home.modals.is_transfer);
	const is_view_transfer = useSelector(state => state.home.modals.is_view_transfer);
	const is_create_location = useSelector(state => state.home.modals.is_create_location);
	const is_role_action = useSelector(state => state.home.modals.is_role_action);

	// Component states
	const [navLink, setNavLink] = React.useState('dashboard');
	const [noveltyId, setNoveltyId] = React.useState('');
	const [noveltyQuestions, setNoveltyQuestions] = React.useState([]);
	const [noveltyAction, setNoveltyAction] = React.useState('');
	const [galleryFiles, setGalleryFiles] = React.useState();
	const [transferId, setTransferId] = React.useState('');
	const [viewFinalizedTransfer, setViewFinalizedTransfer] = React.useState();
	const [isTransferGallery, setIsTransfersGallery] = React.useState(false);
	const [noveltyExternalId, setNoveltyExternalId] = React.useState('');
	const [addLocationType, setAddLocationType] = React.useState('');
	const [transferIdRoleAction, setTransferIdRoleAction] = React.useState('');

	React.useEffect(() => {
		if (!home_user) {
			dispatch(getUser());
			dispatch(getNoveltyTypes());
		}
	}, [dispatch, auth_token, home_user]);

	React.useEffect(() => {
		if (home_user?.is_verified === false) {
			dispatch(setIsUpdateUserPassword(true));
		}
	}, [home_user?.is_verified, dispatch]);

	const handleSelectedNovelty = (external_id) => {
		setNoveltyExternalId(external_id);
		if(external_id){
			dispatch(getNoveltyFormByType(external_id));
		} else {
			dispatch(setNoveltyForm(undefined));
			dispatch(setIsNoveltyForm(false));
		}		
	}

	const handleNavbarLink = (link) => {
		setNavLink(link);
		if(link === 'admin_users') {
			dispatch(getUsers());
			dispatch(getUserRoles());
		}
		if(link === 'create_novelty' || link === 'create_format') {
			dispatch(setNoveltyForm(undefined));
			dispatch(setIsNoveltyForm(false));
		}
		if(link === 'novelties_list') {			
			dispatch(getNovelties());
			dispatch(getUsers());
		}
		if(link === 'new_asset') {
			dispatch(getAssets());
			dispatch(setAssetActionType('new_asset'));
			dispatch(setIsAssetModal(true));
		}
		if(link === 'list_assets') {
			dispatch(getAssets());
		}
		if(link === 'manage_transfer') {
			dispatch(getTransfers());
		}
		if(link === 'transfer_asset') {
			dispatch(getTransfersFormLocations());
		}
		if(link === 'geo_map') {
			dispatch(setClearGeomapCoordinates())
		}
	}

	const handleNoveltyActions = (novelty_id, novelty_questions, action, novelty_external_id) => { 
		setNoveltyExternalId(novelty_external_id);
		setNoveltyAction(action);
		setNoveltyQuestions(novelty_questions);
		setNoveltyId(novelty_id);
		dispatch(getEditNovelty(novelty_id));
		dispatch(setIsEditNovelty(true)); 
	}

	const handleViewFiles = (files, index, form_type) => {
		let gallery_files = {
			files: files,
			index: index,
			form_type: form_type.caption,
		}
		setGalleryFiles(gallery_files);
		dispatch(setIsCarouselModal(true));
	}

	const handleAssetsTableAction = (asset_id, action_type) => {
		dispatch(setAssetActionType(action_type));
		dispatch(getAsset(asset_id));
		dispatch(setIsAssetModal(true));		
	}

	const handleFinalizeTransfer = (transfer_id) => {
		dispatch(getTransfersFormLocations());
		setTransferId(transfer_id);
		dispatch(setIsTranfer(true));

	}

	const handleViewFinalizedTransfer = (transfer) => {
		setViewFinalizedTransfer(transfer);
		dispatch(setIsViewTransfer(true));
	}

	return (
		<React.Fragment>
			{/* Component modals */}
			<HomeUpdatePasswordModalComponent
				show={is_update_user_password}
				onHide={() => { dispatch(setIsUpdateUserPassword(false)) }}
			/>
			<HomeCreateUserModalComponent
				show={is_create_user}
				onHide={() => { dispatch(setIsCreateUser(false)) }}
				roles={user_roles}
			/>
			<HomeEditNoveltyModalComponent 
				show={is_edit_novelty}
				onHide={() => { dispatch(setIsEditNovelty(false)) }}
				form_data={edit_novelty_form}
				handleOnCancelBtn={() => {  
					dispatch(setEditNoveltyForm(undefined));
					//formRef.current.reset(); TODO
					dispatch(setIsEditNovelty(false)); 
					setNoveltyAction('');
				}}
				novelty_id={noveltyId}
				handleResetForm={() => {
					setNoveltyAction('');
					//formRef.current.reset(); TODO
					//setFormNovelty(false); TODO
				}}
				noveltyQuestions={noveltyQuestions}
				noveltyAction={noveltyAction}
				handleViewFiles={(files, index, form_type) => {handleViewFiles(files, index, form_type)}}
				novelty_external_id={noveltyExternalId}
			/>
			<HomeCarouselModalComponent 
				show={is_carousel}
				onHide={() => { 
					dispatch(setIsCarouselModal(false));
					setIsTransfersGallery(false);
				}}
				galleryFiles={galleryFiles}
				noveltyAction={noveltyAction}	
				isTransferGallery={isTransferGallery}			
			/>
			<HomeAssetsModalComponent 
				show={is_asset}
				onHide={() => { dispatch(setIsAssetModal(false)) }}
			/>
			<HomeFinalizeTransferModalComponent 
				show={is_transfer}
				onHide={() => { dispatch(setIsTranfer(false)) }}
				transfer_id={transferId}
				handleAddLocation={(location_type) => {
					setAddLocationType(location_type);
					dispatch(setIsCreateLocation(true));
				}}
			/>
			<HomeViewFinalizedTransferModalComponent 
				show={is_view_transfer}
				onHide={() => { dispatch(setIsViewTransfer(false)) }}
				transfer={viewFinalizedTransfer}
				handleOnHide={() => {
					setViewFinalizedTransfer();
					dispatch(setIsViewTransfer(false));
				}}
			/>
			<HomeCreateTransferLocationModalComponent 
				show={is_create_location}
				onHide={() => { 
					setAddLocationType('');
					dispatch(setIsCreateLocation(false));
				}}
				locationType={addLocationType}
			/>
			<HomeRoleActionModalComponent
				show={is_role_action}
				onHide={() => { dispatch(setIsRoleAction(false)) }}
				transferId={transferIdRoleAction}
			/>

			<Row className='home__container'>
				<Col lg={2}>
					<HomeNavbarComponent 
					handleNavbarLink={(link) => { handleNavbarLink(link) }} 
					navLink={navLink}
				/>
				</Col>
				<Col lg={10} className='home__home-container' >
					{ navLink === 'dashboard' &&
						<HomeDashboardComponent 
							handleNavbarLink={ (dashboard_type) => { handleNavbarLink(dashboard_type) } }
						/>
					}
					{ navLink === 'admin_users' &&
						<HomeUsersListComponent />
					}
					{ (navLink === 'create_novelty' || navLink === 'create_format') &&
						<div className='home__create-novelty'>
							<Card 
								bg={'light'}
								key={'Light'}
								text={'dark'}
							>
								<Card.Header>Tipos de novedad</Card.Header>
								<Card.Body>
									<Card.Title>Seleccione un tipo de novedad</Card.Title>
									<div>
										<Form ref={formRef}>
											<Form.Group>
												<Form.Select onChange={(e) => { handleSelectedNovelty(e.target.value) }}>
													<option value="">Seleccionar</option>
													{ novelty_types?.map(novelty_type => (
														<option key={novelty_type.id} value={novelty_type.external_id ? novelty_type.external_id : ''} >{novelty_type.caption}</option>
													))}
												</Form.Select>
											</Form.Group>
										</Form>
									</div>
								</Card.Body>
							</Card>
							{ is_novelty_form &&
								<HomeDynamicComposeFormComponent
									handleOnCancelBtn={() => {  
										dispatch(setNoveltyForm(undefined));
										dispatch(setIsNoveltyForm(false));
										formRef.current.reset();
									}}	
									handleResetForm={() => { formRef.current.reset() }}
									form_data={novelty_form}
									novelty_external_id={noveltyExternalId}
									noveltyAction={noveltyAction}
								/>
							}
						</div>					
					}
					{ navLink === 'novelties_list' &&
						<HomeNoveltiesTableComponent 
							handleNoveltyActions={(novelty_id, novelty_questions, action, novelty_external_id) => { handleNoveltyActions(novelty_id, novelty_questions, action, novelty_external_id) }}
							novelty_types={novelty_types}
						/>
					}
					{ (navLink === 'list_assets' || navLink === 'new_asset' ) &&
						<HomeAssetsListComponent 
							handleAssetsTableAction={(asset_id, action_type) => { handleAssetsTableAction(asset_id, action_type) }}
						/>
					}
					{ navLink === 'transfer_asset' &&
						<HomeTransferAssetComponent 
							handleNavbarLink={ (navLink) => { handleNavbarLink(navLink) } }
							handleAddLocation={(location_type) => {
								setAddLocationType(location_type);
								dispatch(setIsCreateLocation(true));
							}}
						/>
					}
					{ navLink === 'manage_transfer' &&
						<HomeTransfersListComponent 
							handleFinalizeTransfer={ (transfer_id) => {handleFinalizeTransfer(transfer_id)} }
							handleViewFinalizedTransfer={ (transfer) => {handleViewFinalizedTransfer(transfer)} }
							handleViewTransferFiles={ (transfer_files) => {
								setIsTransfersGallery(true);
								let form_type = {
									caption: '',									
								}
								handleViewFiles(transfer_files, 0, form_type);
							} }
							handleRoleAction={ (transfer_id) => {
								setTransferIdRoleAction(transfer_id);
								dispatch(setIsRoleAction(true));
							}}
						/>
					}
					{ navLink === 'geo_map' &&
						<HomeGeomapComponent />
					}
				</Col>
			</Row>
			<Toast
				onClose={() => { dispatch(setOnCloseToastMessage()) }}
				show={is_toast_message}
				delay={5000}
				autohide
				className='home__toast'
				bg={toast_message_type}
				animation
			>
				<Toast.Body className="text-white">{toast_message}</Toast.Body>
			</Toast>
		</React.Fragment>
	)
}

// Redux mapping
const mapStateToProps = state => {
	return {
		state
	}
}

export default connect(mapStateToProps)(HomeView);
