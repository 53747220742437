import axios from "axios";
import { api_url } from "../../../util/GlobalData";

export const UserApi = () => {
	return axios.get(`${api_url}/users/me/`, {
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}  
	});
}

export const GetUsersApi = () => {
	return axios.get(`${api_url}/users/`, {
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}  
	});
}

export const GetUserRolesApi = () => {
	return axios.get(`${api_url}/role-types/`, {
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PostCreateUserApi = (form_data) => {
	return axios.post(`${api_url}/auth/register/`, 
	form_data,
	{
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PatchUpdateUserPasswordApi = (form_data) => {
	return axios.patch(`${api_url}/users/${form_data.user_id}/`, 
	{
		old_password: form_data.old_password,
		new_password: form_data.new_password
	},
	{
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetNoveltyTypesApi = () => {
	return axios.get(`${api_url}/novelty-types/`,
	{
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetNoveltyFormByTypeApi = (external_id, location_filter = null) => {
	return axios.get(`${api_url}/get-form/?external_id=${external_id}&filter_location=${location_filter ? location_filter : ''}`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PostAnswersNoveltyApi = (novelty_form) => {
	return axios.post(`${api_url}/post-form/`,
	novelty_form,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetNovelitesApi = (next_previous_page) => {
	return axios.get( next_previous_page ? next_previous_page : `${api_url}/novelty/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetFiltersNovelitesApi = (query_params) => {
	return axios.get(`${api_url}/novelty/?${query_params}`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetEditNoveltyApi = (novelty_id, location_filter = null) => {
	return axios.get(`${api_url}/novelty/${novelty_id}/?location=${location_filter ? location_filter : ''}`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PatchEditNoveltyApi = (novelty_form) => {
	return axios.patch(`${api_url}/patch-form/`,
	novelty_form,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const DeleteNoveltyFileApi = (novelty_file_id) => {
	return axios.delete(`${api_url}/novelty-file/${novelty_file_id}/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PostAssetApi = (asset) => {
	return axios.post(`${api_url}/asset/`,
	asset,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetAssetsApi = (next_previous_page) => {
	return axios.get( next_previous_page ? next_previous_page : `${api_url}/asset/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetAssetApi = (asset_id) => {
	return axios.get(`${api_url}/asset/${asset_id}/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PatchAssetApi = (asset) => {
	return axios.patch(`${api_url}/asset/${asset.asset_id}/`,
	asset.asset,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const SearchUsersApi = (word) => {
	return axios.get(`${api_url}/users/?search=${word}`, {
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}  
	});
}

export const SearchAssetApi = (word) => {
	return axios.get(`${api_url}/asset/?search=${word}`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PostTransferApi = (transfer) => {
	return axios.post(`${api_url}/transfer/`,
	transfer,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetTransfersApi = (next_previous_page) => {
	return axios.get(next_previous_page ? next_previous_page : `${api_url}/transfer/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetQueryTransfersApi = (query) => {
	return axios.get(`${api_url}/transfer/${query}`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const PatchTransferApi = (transfer) => {
	return axios.patch(`${api_url}/transfer/${transfer.id}/`,
	transfer.transfer,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const getNoveltiesToExportApi = (export_query) => {
	return axios.get(`${api_url}/novelty-filter/?${export_query}`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const deleteNoveltyApi = (novelty_id) => {
	return axios.delete(`${api_url}/novelty/${novelty_id}/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const deleteNoveltiesApi = (novelties_array) => {
	return axios.delete(`${api_url}/bulk-delete/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		},
		data: {
			novelties: novelties_array,
		}
	});
}

export const getTransfersFormLocationsApi = () => {
	return axios.get(`${api_url}/origin/`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const postTransfersFormLocationsApi = (location) => {
	return axios.post(`${api_url}/origin/`,
	location,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const patchTransferWareHouseManApi = (form_data) => {
	return axios.patch(`${api_url}/transfer-warehouseman/${form_data.transfer_id}/`,
	form_data.body,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const patchUpdateUserRoleApi = (form_data) => {
	return axios.patch(`${api_url}/role/${form_data.user_role_id}/`,
	{
		role_type: form_data.role_type,
	},	
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const patchChangeUserPasswordApi = (form_data) => {
	return axios.patch(`${api_url}/users/${form_data.user_id}/change-password/`,
	{
		new_password: form_data.new_password
	},
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

export const GetMapCoordinatesApi = (data) => {
	return axios.get(`${api_url}/geomap/?name=${data.name}&date_inicial=${data.date_inicial}&date_final=${data.date_final}`,
	{		
		headers: {
			Authorization: `Token ${localStorage.getItem('token')}`
		}
	});
}

