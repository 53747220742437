// React
import React from "react";

// Bootstrap
import Form from 'react-bootstrap/Form';

// Components
import CustomDropdownSelectComponent from "../../../../../shared/components/custom-dropdown-select/CustomDropdownSelectComponent.js";

// React redux
import { useDispatch } from 'react-redux';
import { getNoveltyFormByType, getEditNovelty } from '../../../slice/HomeSlice.js';

function HomeDynamicListComponent({
    id,
    caption,
    is_required,
    answers,
    noveltyAction,
    question_jsonSchema,
    novelty_external_id,
    novelty_id,
    form_data_detail,
}) {
    
    // React home redux states
	const dispatch = useDispatch();

    // Component states
    const [selectList, setSelectList] = React.useState([]);

    React.useEffect(() => {
        let location_results = [];
        if(noveltyAction !== 'edit_novelty' &&
            noveltyAction !== 'view_novelty' && 
            answers && 
            question_jsonSchema?.entity_name && 
            question_jsonSchema?.entity_name === "Location") {
                answers.forEach(location => {
                    let obj = {
                        label: `${location.caption}`,
                        value: location.caption
                    }
                    location_results.push(obj);                
                });
                setSelectList(location_results);
        }
        if(noveltyAction !== 'view_novelty' &&
            noveltyAction === 'edit_novelty' && 
            answers.answers && 
            question_jsonSchema?.entity_name && 
            question_jsonSchema?.entity_name === "Location") {
                answers.answers.forEach(location => {
                    let obj = {
                        label: `${location.caption}`,
                        value: location.caption
                    }
                    location_results.push(obj);                
                });
                setSelectList(location_results);
        }
    }, [answers]);

    const handleDefaultValue = () => {
        if(answers && 
            (noveltyAction === 'view_novelty' || noveltyAction === 'edit_novelty') && 
            answers.answers && 
            question_jsonSchema?.entity_name !== "Location" ) {
                let default_value = {};
                default_value = answers.answers?.find(option => (option.is_checked === true || option.is_default === true ));
                return default_value?.caption;
        } else if(answers && 
            (noveltyAction === 'view_novelty' || noveltyAction === 'edit_novelty') && 
            answers.answers && question_jsonSchema?.entity_name && 
            question_jsonSchema?.entity_name === "Location") {
                let default_value = {};
                default_value = form_data_detail.find(option => option.question_type === 'ExternalData');
                return default_value?.answers[0].value;
        } else {
            return ''
        } 
    }

    const handleSearchInput = async (value) => {  
        if(noveltyAction === 'edit_novelty') {
            let obj = {
                novelty_id: novelty_id,
                location_filter: value,
            }            
            await dispatch(getEditNovelty(obj));
        } else {
            let obj = {
                external_id: novelty_external_id,
                location_filter: value,
            }
            await dispatch(getNoveltyFormByType(obj));
        }        
    }

    return (
        <Form.Group key={`${id}_list`} className="mb-3">
            <Form.Label>{caption}</Form.Label>
            { question_jsonSchema?.entity_name && question_jsonSchema?.entity_name === 'Location' 
                ?
                <CustomDropdownSelectComponent 
                    options={selectList}
                    handleSearchInput={ (value) => handleSearchInput(value) }
                    name={id} 
                    id={id}
                    maxLength={3}
                    required={is_required} 
                    disabled={noveltyAction === 'view_novelty' ? true : false }
                    defaultValue={handleDefaultValue()}
                    noveltyAction={noveltyAction}
                    handleClearSearchArray={() => setSelectList([])}
                />
                :
                <Form.Select 
                    name={id} 
                    id={id} 
                    required={is_required} 
                    defaultValue={handleDefaultValue()} 
                    disabled={noveltyAction === 'view_novelty' ? true : false } 
                > 
                    <option value=''>Seleccione una opción</option>
                    { answers && answers?.length > 0 && answers?.map((item, index) => (
                        <option 
                            key={`${index}_list-create`}
                            className="baloa-username list_dynamic_container_Item"
                            value={item.caption} 
                        >
                            {item.caption}
                        </option>
                        ))
                    }
                    { answers && answers.answers && answers.answers?.map((item, index) => (
                        <option 
                            key={`${index}_list-edit`}
                            className="baloa-username list_dynamic_container_Item"
                            value={item.caption} 
                        >
                            {item.caption}
                        </option>
                        ))
                    }
                </Form.Select>
            }
            <Form.Control.Feedback type="invalid">
                Campo requerido
            </Form.Control.Feedback>
        </Form.Group>
    )
}

export default HomeDynamicListComponent;