// React
import React from 'react';

import { connect } from 'react-redux';

// Components
import AuthLoginComponent from '../components/auth-login/AuthLoginComponent';

// Styles
import './AuthView.styles.css';

function AuthView (props) { 
  return (
    <AuthLoginComponent />
  )  
}

const mapStateToProps = state => {
  return {
      state
  }
}

export default connect(mapStateToProps)(AuthView);
