// React
import React from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { getTransfers } from '../../../home/slice/HomeSlice';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Bootstrap
import Table from 'react-bootstrap/Table';
import { Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';

// Styles
import './HomeTransfersListComponent.styles.css'


function HomeTransfersListComponent(props) {

   	// React home redux states
    const dispatch = useDispatch();
	const transfers = useSelector(state => state.home.transfers);
    const home_user = useSelector(state => state.home.user);

    // Component states
    const [transferNumber, setTransferNumber] = React.useState('');
    const [transferDate, setTransferDate] = React.useState('');
    const [transferState, setTransferState] = React.useState('');
    const [filterIsOn, setFilterIsOn] = React.useState(false);
    const [dateWarehousemanSends, setDateWarehosemanSends ] = React.useState(true);
    const [dateOfArrivalReception, setDateOfArrivalReception ] = React.useState(false);   
    const [dateType, setDateType] = React.useState('date_warehouseman_sends');

    React.useEffect(() => {
        let init_param = '?';
        let query_params = '';
        if(filterIsOn && (transferNumber !== '' || transferDate !== '' || transferState !== '')) {
            if(transferNumber !== '' ) {
                query_params += init_param.concat(`transfer_number=${transferNumber}`)
            }
            if(transferState !== '' ) {
                query_params += init_param.concat(`state=${transferState}`)
            }
            if(transferDate !== '' && dateType === 'date_warehouseman_sends' ) {
                query_params += init_param.concat(`date_warehouseman_sends=${transferDate}`)
            }
            if(transferDate !== '' && dateType === 'date_of_arrival_reception' ) {
                query_params += init_param.concat(`date_of_arrival_reception=${transferDate}`)
            }

            // Find the index of the first occurrence of ?
            const firstQuestionMarkIndex = query_params.indexOf('?');

            // Create the modified string by replacing all subsequent ? with &
            const modifiedString = query_params.slice(0, firstQuestionMarkIndex + 1) + query_params.slice(firstQuestionMarkIndex + 1).replace(/\?/g, '&');

            let obj = {
                query_params: modifiedString
            }
            dispatch(getTransfers(obj));
        }

        if(!filterIsOn && transferNumber === '' && transferDate === '' && transferState === '') {
            dispatch(getTransfers());
        }

    }, [transferNumber, transferDate, transferState]);

    const handleAssetsList = (list) => {
        const names = [];
        for (const key in list.asset_data) {
            if (list.asset_data.hasOwnProperty(key)) {
                names.push({name: list.asset_data[key].name, qty: list.asset_data[key].quantity});
            }
        }
        return (
            <div>
                { names.map((name, index) => (
                    <div key={index} className="home-transfer-list__assets-list">
                        <h6>{name.name}</h6>
                        <div>{name.qty}</div>
                    </div>                     
                ))}                
            </div>
        );
    }

    const handleRadioChecked = (value, type) => {
        setDateType(type);
        setTransferDate('');
        if(type === 'date_warehouseman_sends') {
            if(value === 'on') {
                setDateWarehosemanSends(true);
                setDateOfArrivalReception(false);
            } else {
                setDateWarehosemanSends(false);
                setDateOfArrivalReception(true);
            }
        } else {
            if(value === 'on') {
                setDateWarehosemanSends(false);
                setDateOfArrivalReception(true);
            } else {
                setDateWarehosemanSends(true);
                setDateOfArrivalReception(false);
            }
        }
    }

    return (
        <div className="home-assets-list-table__container">
            
                <Card>
                    <Card.Header>Lista de Traslados</Card.Header>
                    <Card.Body>
                        <Accordion defaultActiveKey="0" >
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Filtrar traslados</Accordion.Header>
                                <Accordion.Body>
                                    <Form className="home-transfer-list__filters-container">
                                        <div>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Número Traslado</Form.Label>
                                                <Form.Control 
                                                    type="number"
                                                    min={0}
                                                    onChange={(e) => {
                                                        setFilterIsOn(true);
                                                        setTransferNumber(e.target.value)}
                                                    } 
                                                    name='transfer_number'
                                                    value={transferNumber}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Estado</Form.Label>
                                                <Form.Select 
                                                    aria-label="Default select example" 
                                                    onChange={(e) => {
                                                        setFilterIsOn(true);
                                                        setTransferState(e.target.value)}
                                                    }
                                                    value={transferState}
                                                    >
                                                    <option>Filtrar por estado</option>
                                                    <option value="Active">Activos</option>
                                                    <option value="Finalized">Finalizados</option>
                                                    <option value="Finished_With_Remarks">Finalizados con observaciones</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div>
                                            <Form.Group>
                                                <Form.Check onChange={ e => handleRadioChecked(e.target.value, 'date_warehouseman_sends') } type='radio' checked={dateWarehousemanSends} id={`novelty-creation-date`} label={`buscar por fecha de despacho`}/>
                                                <Form.Check onChange={ e => handleRadioChecked(e.target.value, 'date_of_arrival_reception') } type='radio' checked={dateOfArrivalReception} id={`novelty-reported-date`} label={`buscar por fecha de recibo`}/>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Fecha</Form.Label>
                                                <Form.Control 
                                                    type="date"
                                                    value={transferDate}
                                                    onChange={(event) => {
                                                        setFilterIsOn(true);
                                                        setTransferDate(event.target.value)
                                                    }
                                                }/>
                                            </Form.Group>
                                        </div>
                                        <Button 
                                            onClick={() => {
                                                setTransferNumber('');
                                                setTransferDate('');
                                                setTransferState('');
                                                setDateType('date_warehouseman_sends');
                                                setFilterIsOn(false);
                                            }}
                                        >
                                            Limpiar consulta
                                        </Button>

                                    </Form> 
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        { transfers?.results?.length > 0 &&
                            <Table striped bordered hover responsive className="home-transfers-list__table-container mt-3">
                                <thead>
                                    <tr>
                                        <th>Numero de transferencia</th>
                                        <th>Usuario</th>
                                        <th>Fecha transferencia</th>
                                        <th>Activos</th>
                                        <th>Información de quien despacha</th>
                                        <th>Origen del despacho</th>  
                                        <th>Sitio de destino</th> 
                                        <th>Observaciones</th>
                                        <th>Estado</th>
                                        <th>Acciones</th> 
                                    </tr>
                                </thead>
                                <tbody>
                                    { transfers?.results?.map((transfer) => (
                                        <tr key={transfer.id} id={transfer.id}>
                                            <td>{transfer.transfer_number}</td>
                                            <td>{transfer.user_delivers_origin}</td>
                                            <td>{moment(transfer.date_warehouseman_sends).format('MMMM DD YYYY, h:mm a')}</td>
                                            <td>{handleAssetsList(transfer)}</td>
                                            <td>{transfer.user_delivers_origin}</td>
                                            <td>{transfer.origin}</td>
                                            <td>{transfer.destination_site}</td>
                                            <td>{transfer.observations}</td>
                                            <td>{transfer.transfer_status.some(status => status.type_status_transfer === 'Finalized') ?  'Finalizado' : 'Activo'}</td>
                                            <td>
                                                <div className="home-transfer-list__btn-actions__container">
                                                    <Button 
                                                        size="sm" 
                                                        variant="secondary" 
                                                        onClick={() => {props.handleViewTransferFiles(transfer.transfer_files)}}
                                                        className="home-transfer-list__btn-actions"
                                                    >
                                                        Ver archivos
                                                    </Button>

                                                    { (transfer.transfer_status.some(status => status.type_status_transfer === 'Active')) && home_user?.role !== 'Vigilante' &&
                                                        <Button 
                                                            size="sm" 
                                                            variant="primary" 
                                                            onClick={() => {props.handleFinalizeTransfer(transfer.id)}}
                                                            className="home-transfer-list__btn-actions"
                                                        >
                                                            Finalizar
                                                        </Button>
                                                    }   

                                                    { (transfer.transfer_status.some(status => status.type_status_transfer === 'Finalized') ||
                                                        transfer.transfer_status.some(status => status.type_status_transfer === 'Finished_With_Remarks')) && 
                                                        home_user?.role !== 'Vigilante' &&
                                                        <Button 
                                                            size="sm" 
                                                            variant="primary" 
                                                            onClick={() => {props.handleViewFinalizedTransfer(transfer)}}
                                                            className="home-transfer-list__btn-actions"
                                                        >
                                                            Ver
                                                        </Button>
                                                    }

                                                    { home_user?.role === 'Vigilante' &&
                                                        <Button 
                                                            size="sm" 
                                                            variant="primary" 
                                                            onClick={() => {props.handleRoleAction(transfer.id)}}
                                                            className="home-transfer-list__btn-actions"
                                                            disabled={transfer?.authorized_exit_guard ? transfer?.authorized_exit_guard : false}
                                                        >
                                                            Autorizar salida
                                                        </Button>
                                                    }
                                                </div>
                                            </td>                                            
                                        </tr>
                                    ))}
                                </tbody>                        
                            </Table>
                        }
                        { transfers?.results?.length === 0 &&
                            <div className="home-transfers-list__no-transfer-msg"><h5>No hay transferencias</h5></div>
                        }
                        <div className="home-novelties-table__actions-container">
                            { transfers?.previous && <Button variant="primary" onClick={() => { dispatch(getTransfers(transfers.previous)) }}>Anterior</Button> }
                            { transfers?.next && <Button variant="primary" onClick={() => { dispatch(getTransfers(transfers.next)) }}>Siguiente</Button> }
                        </div>
                    </Card.Body>
                </Card>
            
        </div>
    )
}

export default HomeTransfersListComponent;