// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-finalize-transfer-modal__btns-action {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
}

.home-finalize-transfer-modal__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.home-transfer-asset__create-location .dropdown-menu.show {
    max-width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-finalize-transfer-modal/HomeFinalizeTransferModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".home-finalize-transfer-modal__btns-action {\n    display: flex;\n    justify-content: flex-end;\n    gap: 16px;\n}\n\n.home-finalize-transfer-modal__label {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.home-transfer-asset__create-location .dropdown-menu.show {\n    max-width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
