// React
import React from "react";

// Bootstrap
import { Button, Form } from "react-bootstrap";

// Components
import HomeDynamicInputComponent from "../home-dynamic-input/HomeDynamicInputComponent";

// Icons
import { MdDelete } from 'react-icons/md';

// Styles
import './HomeDynamicInputDetailComponent.styles.css';


function HomeDynamicInputDetailComponent ({
    form_question,
    id,
    is_required,
    answers,
    noveltyAction,
    validated,
    caption,
    formRef, 
    handleDetailAnswers   
}) {

    // Component States
    const [detailArray, setDetailArray] = React.useState([]);
    const [answerInfo, setAnswerInfo] = React.useState({});

    const [detailAnswerArray, setDetailAnswerArray] = React.useState([])


    // TODO Fix hook console warning
    React.useMemo(() => {
        if(detailArray.length === 0) {
            if(form_question.jsonSchema?.subform && !noveltyAction) {
                setDetailArray(prev => [...prev, form_question.jsonSchema?.subform] )
            }
            if(form_question.jsonSchema?.subform && (noveltyAction === 'edit_novelty' || noveltyAction === 'view_novelty')) {
                handleDetailAnswers(answers.answers);
                let newDetailArray = []
                    const subFormCopy = form_question.jsonSchema?.subform?.map((object, i) => {
                        const answer_obj = {
                            "answers": [],
                            "date_field": null,
                            "text_answer": null,
                            "file_document": null,
                            "question_type": null,
                            "boolean_answer": null
                        }
                        if(object.jsonSchema?.only_numbers === false){
                            answer_obj.text_answer = answers.answers[i]?.text;
                            answer_obj.question_type = 'Text';
                        }
                        if(object.jsonSchema.date_format) {
                            if(object.jsonSchema.date_format === 'Date') {
                                answer_obj.date_field = answers.answers[i]?.date;
                                answer_obj.question_type = 'Date'
                            }
                            if(object.jsonSchema.date_format === 'DateTime') {
                                answer_obj.date_field = answers.answers[i]?.date;
                                answer_obj.question_type = 'Date'
                            }
                            if(object.jsonSchema.date_format === 'Time') {
                                answer_obj.date_field = answers.answers[i]?.time;
                                answer_obj.question_type = 'Date'
                            }            
                        }
    
                        const newObject = { ...object };
                        newObject.answers = answer_obj;
                        return newObject;
                    })
                    newDetailArray.push(subFormCopy)
                    setDetailArray(newDetailArray )
            }
        }
    }, [form_question.jsonSchema.subform]);

    

    React.useEffect(() => {
        const answersArray = [];
        for (const key in answerInfo) {
            if (answerInfo.hasOwnProperty(key)) {
                const index = key.split('_')[1];
                const value = answerInfo[key];
                
                if (!answersArray[index]) {
                    answersArray[index] = {};
                }                
                const property = key.split('_')[0];
                answersArray[index][property] = value;
            }
        }
        const nonEmptyObjects = [];
        answersArray.forEach(obj => {
            if (Object.keys(obj).length > 0) {
              nonEmptyObjects.push(obj);
            }
          });
        setDetailAnswerArray(nonEmptyObjects);
        handleDetailAnswers(nonEmptyObjects);
    }, [answerInfo]);


    const handleNewDetailArray = (detailAnswerArrayCopy) => {
        let newDetailArray = []
            for(let i=0; i<detailAnswerArrayCopy.length; i++) {
                const subFormCopy = form_question.jsonSchema?.subform?.map(object => {
                    const answer_obj = {
                        "answers": [],
                        "date_field": null,
                        "text_answer": null,
                        "file_document": null,
                        "question_type": null,
                        "boolean_answer": null
                    }
                    if(object.jsonSchema?.only_numbers === false){
                        answer_obj.text_answer = detailAnswerArrayCopy[i]?.text;
                        answer_obj.question_type = 'Text';
                    }
                    if(object.jsonSchema.date_format) {
                        if(object.jsonSchema.date_format === 'Date') {
                            answer_obj.date_field = detailAnswerArrayCopy[i]?.time;
                            answer_obj.question_type = 'Date'
                        }
                        if(object.jsonSchema.date_format === 'DateTime') {
                            answer_obj.date_field = detailAnswerArrayCopy[i]?.time;
                            answer_obj.question_type = 'Date'
                        }
                        if(object.jsonSchema.date_format === 'Time') {
                            answer_obj.date_field = detailAnswerArrayCopy[i]?.time;
                            answer_obj.question_type = 'Date'
                        }            
                    }

                    const newObject = { ...object };
                    newObject.answers = answer_obj;
                    return newObject;
                })
                newDetailArray.push(subFormCopy)
                setDetailArray(newDetailArray )
            }

            const newAnswer = {};
            for (let i = 0; i < detailAnswerArrayCopy.length; i++) {
                const object = detailAnswerArrayCopy[i];
                newAnswer[`text_${i}`] = object?.text;
                newAnswer[`time_${i}`] = object?.time;
            }
            setAnswerInfo(newAnswer);
    }

    const handleDeleteDetail = (index_questions) => {
        const detailArrayCopy = [...detailArray];
        const answerInfoCopy = { ...answerInfo };  
        const detailAnswerArrayCopy = [...detailAnswerArray];
        detailArrayCopy.splice(index_questions, 1);
        detailAnswerArrayCopy.splice(index_questions, 1);
        for (let key in answerInfoCopy) {
          if (key.includes(index_questions)) {
            delete answerInfoCopy[key];
          }
        }
        setDetailArray(detailArrayCopy);
        setAnswerInfo(answerInfoCopy);
        handleNewDetailArray(detailAnswerArrayCopy)
    };
      

    const handleInputType = (question, value, index) => {
        let set_input_type = 'text';
        if(question.jsonSchema && question.jsonSchema.only_numbers) {
            set_input_type = 'number';
        } else {
            set_input_type = 'text';
        }
        if(question.jsonSchema && question.jsonSchema.date_format) {
            if(question.jsonSchema.date_format === 'Date') {
                set_input_type = 'date';
            }
            if(question.jsonSchema.date_format === 'DateTime') {
                set_input_type = 'datetime-local';
            }
            if(question.jsonSchema.date_format === 'Time') {
                set_input_type = 'time';
            }            
        }
        return set_input_type;
    }

    

    const handleOnChange = (question, value, index_questions) => {
        let input_type = `${handleInputType(question, value, index_questions)}_${index_questions}`;
        let input_id = `inputId_${index_questions}`;
        let caption = `caption_${index_questions}`
        if(input_type.includes('datetime-local')) {
            input_type = `datetimeLocal_${index_questions}`
        }
        let obj = {
            [input_type]: value,
            [input_id]: question.uuid,
            [caption]: question.caption,
        }
        setAnswerInfo(prevState => ({
            ...prevState,
            ...obj
        }));
    }

    return (   
        <div className="home-dynamic-input-detail__container mb-3">
            <Form.Label>{caption}</Form.Label>
            { !noveltyAction &&                     
                <div className="home-dynamic-input-detail"> 
                    { detailArray?.map((detail_questions, index_questions) => (
                        <div className="home-dynamic-input-detail__questions-container" key={index_questions}>
                            <div className="home-dynamic-input-detail__question" >
                                { detail_questions?.map((question, index_question) => (                                    
                                    <HomeDynamicInputComponent 
                                        key={`${id}_${index_questions}_${index_question}_subquestion`} 
                                        form_question={question}
                                        id={id}
                                        caption={question?.caption}
                                        is_required={(is_required && index_questions === 0 ? true : false )}
                                        validated={validated}
                                        answers={ question?.answers ? question?.answers : null }
                                        noveltyAction={noveltyAction}
                                        onChange={(event) => {handleOnChange(question, event.target.value, index_question)}}
                                    />                                 
                                ))}
                            </div>
                            {/* { form_question.jsonSchema.detailType === 'many' && index_questions !== 0 && <MdDelete onClick={() => {handleDeleteDetail(index_questions)}} /> } */}
                            
                        </div>
                    ))} 
                    
                    
                    {/* { form_question.jsonSchema.detailType === 'many' && 
                        <div className="home-dynamic-input-detail__actions">
                            <Button onClick={() => {setDetailArray(prev => [...prev, form_question.jsonSchema?.subform] )}}>agregar</Button>
                        </div>
                    } */}
                </div>
            }
            { (noveltyAction === 'edit_novelty' || noveltyAction === 'view_novelty') &&
                <div className="home-dynamic-input-detail"> 
                    { detailArray?.map((detail_questions, index_questions) => (
                        <div className="home-dynamic-input-detail__questions-container" key={index_questions}>
                            <div className="home-dynamic-input-detail__question" >
                                { detail_questions?.map((question, index_question) => (                                    
                                    <HomeDynamicInputComponent 
                                        key={`${id}_${index_question}_subquestion`} 
                                        form_question={question}
                                        id={id}
                                        caption={question?.caption}
                                        is_required={(is_required && index_questions === 0 ? true : false )}
                                        validated={validated}
                                        answers={question?.answers}
                                        noveltyAction={noveltyAction}
                                        onChange={(event) => {handleOnChange(question, event.target.value, index_questions)}}
                                    />                                    
                                ))}
                            </div>
                            {/* se comenta codigo, no es claro requerimiento funcion de elminar un nuevo elemento */}
                            {/* { form_question.jsonSchema.detailType === 'many' && index_questions !== 0 && <MdDelete onClick={() => {handleDeleteDetail(index_questions)}} /> }                             */}
                        </div>
                    ))} 
                    {/* se comenta codigo, no es claro requerimiento funcion de agregar un nuevo elemento */}
                    {/* { (form_question.jsonSchema.detailType === 'many' && noveltyAction === 'edit_novelty') && 
                        <div className="home-dynamic-input-detail__actions">
                            <Button onClick={() => {setDetailArray(prev => [...prev, form_question.jsonSchema?.subform] )}}>agregar</Button>
                        </div>
                    } */}
                </div>
            }
        </div> 
                    
    )
}

export default HomeDynamicInputDetailComponent;
