// React
import React, { useEffect } from "react";

// Bootstrap
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';

// React redux
import { useSelector } from 'react-redux';

// Icons
import { MdOutlineTune, MdAssignmentAdd, MdInventory2, MdMenu, MdPeople, MdMap } from 'react-icons/md';

//Styles
import './HomeNavbarComponent.styles.css'

function HomeNavbarComponent(props) {
  
  // Component states
  const [linkSelected, setLinkSelected] = React.useState('');  
  const [show, setShow] = React.useState(false);

  // React home redux states
	const home_user = useSelector(state => state.home.user);

  useEffect(() => {
    if(props.navLink) {
      setLinkSelected(props.navLink);
    }
  }, [props.navLink]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleNavbarLink = (link, device) => {
    if(device === 'mobile') {
      handleClose();
    }
    setLinkSelected(link);
    props.handleNavbarLink(link);

  }

	return (
    <React.Fragment>
      <div className="home-navbar__container" >
        <Accordion defaultActiveKey="0" flush alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header className={`home-navbar__dashboard-link ${linkSelected === 'dashboard' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('dashboard', 'desktop') }}><MdOutlineTune />Panel de Control</Accordion.Header>
          </Accordion.Item>
        </Accordion>
        { home_user?.role === 'Admin' &&
          <Accordion defaultActiveKey="1" flush alwaysOpen>
            <Accordion.Item eventKey="1">
              <Accordion.Header><MdPeople />Usuarios</Accordion.Header>
              <Accordion.Body>
                <Nav className="flex-column"> 
                  <Nav.Link className={`${linkSelected === 'admin_users' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('admin_users', 'desktop') }} >Admin Usuarios</Nav.Link>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
        { (home_user?.role === 'Supervisor' || home_user?.role === 'User' || home_user?.role === 'Admin') &&
          <Accordion defaultActiveKey="2" flush alwaysOpen>
            <Accordion.Item eventKey="2">
              <Accordion.Header><MdAssignmentAdd />Novedades</Accordion.Header>
              <Accordion.Body>
                <Nav className="flex-column"> 
                  <Nav.Link className={`${linkSelected === 'create_novelty' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('create_novelty', 'desktop') }} >Crear Novedad</Nav.Link>
                  <Nav.Link className={`${linkSelected === 'novelties_list' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('novelties_list', 'desktop') }} >Lista Novedades</Nav.Link>
                              
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }  
        { home_user?.role === 'Formatos' &&
          <Accordion defaultActiveKey="2" flush alwaysOpen>
            <Accordion.Item eventKey="2">
              <Accordion.Header><MdAssignmentAdd />Formatos</Accordion.Header>
              <Accordion.Body>
                <Nav className="flex-column"> 
                  <Nav.Link className={`${linkSelected === 'create_format' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('create_format', 'desktop') }} >Crear Formato</Nav.Link>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
        { (home_user?.role === 'Despachador' || home_user?.role === 'Recibidor' || home_user?.role === 'Admin') &&
          <Accordion defaultActiveKey="3" flush alwaysOpen>
            <Accordion.Item eventKey="3">
              <Accordion.Header><MdInventory2 />Activos</Accordion.Header>
              <Accordion.Body>
                <Nav className="flex-column"> 
                  <Nav.Link className={`${linkSelected === 'new_asset' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('new_asset', 'desktop') }} >Crear Activo</Nav.Link> 
                  <Nav.Link className={`${linkSelected === 'list_assets' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('list_assets', 'desktop') }} >Lista Activos</Nav.Link>             
                  <Nav.Link className={`${linkSelected === 'transfer_asset' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('transfer_asset', 'desktop') }} >Crear Traslado</Nav.Link>
                  <Nav.Link className={`${linkSelected === 'manage_transfer' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('manage_transfer', 'desktop') }} >Gestionar Traslado</Nav.Link>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        } 
        { home_user?.role === 'Vigilante' &&
          <Accordion defaultActiveKey="3" flush alwaysOpen>
            <Accordion.Item eventKey="3">
              <Accordion.Header><MdInventory2 />Activos</Accordion.Header>
              <Accordion.Body>
                <Nav className="flex-column"> 
                  <Nav.Link className={`${linkSelected === 'manage_transfer' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('manage_transfer', 'desktop') }} >Gestionar Traslado</Nav.Link>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
        { home_user?.role === 'Admin' &&
          <Accordion defaultActiveKey="4" flush alwaysOpen>
            <Accordion.Item eventKey="4">
              <Accordion.Header><MdMap />Geomapa</Accordion.Header>
              <Accordion.Body>
                <Nav className="flex-column"> 
                  <Nav.Link className={`${linkSelected === 'geo_map' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('geo_map', 'desktop') }} >Motorizados</Nav.Link>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
      </div>
      <div className="home-navbar__mobile-container">
        <div className="home-navbar__mobile-menu">
          <MdMenu onClick={() => {handleShow()}}/>
        </div>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Interglobal</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Accordion defaultActiveKey="0" flush alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="home-navbar__dashboard-link" onClick={() => { handleNavbarLink('dashboard', 'mobile') }}><MdOutlineTune />Panel de Control</Accordion.Header>
              </Accordion.Item>
            </Accordion>
            { home_user?.role === 'Admin' &&
              <Accordion defaultActiveKey="1" flush alwaysOpen>
                <Accordion.Item eventKey="1">
                  <Accordion.Header><MdPeople />Usuarios</Accordion.Header>
                  <Accordion.Body>
                    <Nav className="flex-column"> 
                      <Nav.Link className={`${linkSelected === 'admin_users' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('admin_users', 'mobile') }} >Admin Usuarios</Nav.Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
            { (home_user?.role === 'Supervisor' || home_user?.role === 'User' || home_user?.role === 'Admin') &&
              <Accordion defaultActiveKey="2" flush alwaysOpen>
                <Accordion.Item eventKey="2">
                  <Accordion.Header><MdAssignmentAdd />Novedades</Accordion.Header>
                  <Accordion.Body>
                    <Nav className="flex-column"> 
                      <Nav.Link className={`${linkSelected === 'create_novelty' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('create_novelty', 'mobile') }} >Crear Novedad</Nav.Link>
                      <Nav.Link className={`${linkSelected === 'novelties_list' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('novelties_list', 'mobile') }} >Lista Novedades</Nav.Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
            { home_user?.role === 'Formatos' &&
              <Accordion defaultActiveKey="2" flush alwaysOpen>
                <Accordion.Item eventKey="2">
                  <Accordion.Header><MdAssignmentAdd />Formatos</Accordion.Header>
                  <Accordion.Body>
                    <Nav className="flex-column"> 
                      <Nav.Link className={`${linkSelected === 'create_format' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('create_format', 'mobile') }} >Crear Formato</Nav.Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
            { (home_user?.role === 'Despachador' || home_user?.role === 'Recibidor' || home_user?.role === 'Admin') &&
              <Accordion defaultActiveKey="3" flush alwaysOpen>
                <Accordion.Item eventKey="3">
                  <Accordion.Header><MdInventory2 />Activos</Accordion.Header>
                  <Accordion.Body>
                    <Nav className="flex-column"> 
                      <Nav.Link className={`${linkSelected === 'new_asset' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('new_asset', 'mobile') }} >Crear Activo</Nav.Link> 
                      <Nav.Link className={`${linkSelected === 'list_assets' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('list_assets', 'mobile') }} >Lista Activos</Nav.Link>             
                      <Nav.Link className={`${linkSelected === 'transfer_asset' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('transfer_asset', 'mobile') }} >Crear Traslado</Nav.Link>
                      <Nav.Link className={`${linkSelected === 'manage_transfer' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('manage_transfer', 'mobile') }} >Gestionar Traslado</Nav.Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
            { home_user?.role === 'Vigilante' &&
              <Accordion defaultActiveKey="3" flush alwaysOpen>
                <Accordion.Item eventKey="3">
                  <Accordion.Header><MdInventory2 />Activos</Accordion.Header>
                  <Accordion.Body>
                    <Nav className="flex-column"> 
                      <Nav.Link className={`${linkSelected === 'manage_transfer' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('manage_transfer', 'mobile') }} >Gestionar Traslado</Nav.Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
            { home_user?.role === 'Admin' &&
              <Accordion defaultActiveKey="4" flush alwaysOpen>
                <Accordion.Item eventKey="4">
                  <Accordion.Header><MdMap />Geomapa</Accordion.Header>
                  <Accordion.Body>
                    <Nav className="flex-column"> 
                      <Nav.Link className={`${linkSelected === 'geo_map' ? 'selected' : '' }`} onClick={() => { handleNavbarLink('geo_map', 'mobile') }} >Motorizados</Nav.Link>
                    </Nav>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            }
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </React.Fragment>
  )
}

export default HomeNavbarComponent;