import React from "react";

// Bootstrap
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './HomeViewFinalizedTransferModalComponent.styles.css'

export default function HomeViewFinalizedTransferModalComponent (props) {
    const handleAssetsList = () => {
        if(props.transfer?.asset_data) {
            const names = [];
            for (const key in props.transfer.asset_data) {
                if (props.transfer.asset_data.hasOwnProperty(key)) {
                    names.push({name: props.transfer.asset_data[key].name, qty: props.transfer.asset_data[key].quantity});
                }
            }
            return (
                <div>
                    { names.map((name, index) => (
                        <div key={index} className="home-transfer-list__assets-list">
                            <div>{name.name}</div>
                            <div>{name.qty}</div>
                        </div>                     
                    ))}                
                </div>
            );
        }
        
    }
    return (
        <ModalComponent 
            show={props.show}
            onHide={props.onHide}
            title='Resumen Transferencia'
        >

            <Table striped bordered hover>
                <tbody>
                    <tr>
                        <td><h6>Fecha Creación Transferencia</h6></td>
                        <td>{moment(props.transfer?.transfer_status?.find(transfer => transfer.type_status_transfer === 'Created')?.date_time).format("MMMM D YYYY, h:mm:ss a")}</td>
                    </tr>
                    <tr>
                        <td><h6>Fecha Finalización Transferencia</h6></td>
                        <td>{moment(props.transfer?.transfer_status?.find(transfer => transfer.type_status_transfer === 'Finalized')?.date_time).format("MMMM D YYYY, h:mm:ss a")}</td>
                    </tr>
                    <tr>
                        <td><h6>Activo(s) Transferido(s)</h6></td>
                        <td>{handleAssetsList()}</td>
                    </tr>
                    <tr>
                        <td><h6>Usuario Entrega en Origen</h6></td>
                        <td>{props.transfer?.user_delivers_origin}</td>
                    </tr>
                    <tr>
                        <td><h6>Usuario Recibe en Destino</h6></td>
                        <td>{props.transfer?.user_receives_destination}</td>
                    </tr>
                    <tr>
                        <td><h6>Obesrvaciones Origen</h6></td>
                        <td>{props.transfer?.observations}</td>
                    </tr>
                    <tr>
                        <td><h6>Obesrvaciones Destino</h6></td>
                        <td>{props.transfer?.location_income}</td>
                    </tr>
                </tbody>
            </Table>
            <div className="home-view-finalized-transfer__actions">
                <Button onClick={() => {props.handleOnHide()}}>Cerrar</Button>
            </div>
        </ModalComponent>
    )
}