// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-dynamic-compose-form__container.card {
    width: 100%;
    box-shadow: 0px 4px 10px 0px rgba(25, 33, 92, 0.10);
}

.home-dynamic-compose-form__actions-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
}

.home-dynamic-compose-form__input.hide {
    display: none;
}

.home-dynamic-compose-form__view-novelty-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-dynamic-form/home-dynamic-compose-form/HomeDynamicComposeFormComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mDAAmD;AACvD;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf","sourcesContent":[".home-dynamic-compose-form__container.card {\n    width: 100%;\n    box-shadow: 0px 4px 10px 0px rgba(25, 33, 92, 0.10);\n}\n\n.home-dynamic-compose-form__actions-container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 12px;\n    margin-top: 24px;\n}\n\n.home-dynamic-compose-form__input.hide {\n    display: none;\n}\n\n.home-dynamic-compose-form__view-novelty-actions {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
