import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

// Bootsrap
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { getMapCoordinates, setClearGeomapCoordinates } from '../../../home/slice/HomeSlice';

import 'mapbox-gl/dist/mapbox-gl.css';

import './HomeGeomapComponent.styles.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZXBhbGF1IiwiYSI6ImNsbnoyMDl4ajAyd3oyeHMzampkbmFnYWwifQ.b1IdhZxWJCN2TdQmymv9-g';

export default function HomeGeomapComponent() {

    // React home redux states
    const dispatch = useDispatch();
    const geomap_coordinates = useSelector(state => state.home.geomap_coordinates);
    const devices = {
        "M-1": "M-1",
        "M-2": "M-2",
        "M-3": "M-3",
        "M-4": "M-4",
        "M-5": "M-5",
        "M-6": "M-6",
        "M-8": "M-8",
        "M-10": "M-10",
        "M-18": "M-18",
        "M-20": "M-20",
        "M-22": "M-22",
        "M-23": "M-23",
        "M-28": "M-28",
        "M-31": "M-31",
        "M-35": "M-35",
        "M-37": "M-37",
        "M-38": "M-38",
        "M-43": "M-43",
        "M-45": "M-45",
        "M-47": "M-47",
        "M-48": "M-48", 
        "M-51": "M-51",
        "M-54": "M-54",
        "M-61": "M-61",
        "M-64": "M-64",
        "M-68": "M-68",
        "M-72": "M-72",
        "M-73": "M-73",
        "M-75": "M-75",
        "M-76": "M-76",
        "M-79": "M-79",
        "M-80": "M-80",
        "M-81": "M-81",
        "M-84": "M-84",
        "M-85": "M-85",
    }

    const deviceValues = Object.values(devices);
    const [device, setDevice] = React.useState('');
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);

    useEffect(() => {
        if(geomap_coordinates.coordinates) {
            let ubication_pk = [];
            geomap_coordinates.ubication_pk.forEach((element, index) => {
                ubication_pk.push(
                    {
                        'type': 'Feature',
                        'properties': {
                            'description': `<strong>Ubicacion PK</strong><p>${element}</p>`                        
                        },
                        'geometry': {
                            'type': 'Point',
                            'coordinates': geomap_coordinates.coordinates[index]
                        }
                    },
                )                
            });
            const map = new mapboxgl.Map({
                container: 'map',
                style: 'mapbox://styles/mapbox/streets-v12',
                center: geomap_coordinates.coordinates[0],
                zoom: 14,
            });    
            map.on('load', () => {
                map.addSource('route', {
                    type: 'geojson',
                    data: {
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: 'LineString',
                            coordinates: geomap_coordinates.coordinates,
                        },
                    },
                });    
                map.addLayer({
                    id: 'route',
                    type: 'line',
                    source: 'route',
                    layout: {
                        'line-join': 'round',
                        'line-cap': 'round',
                    },
                    paint: {
                        'line-color': 'red',
                        'line-width': 2,
                    },
                });

                map.addSource('places', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: ubication_pk  
                    }
                        
                });
                map.addLayer({
                    'id': 'places',
                    'type': 'circle',
                    'source': 'places',
                    'paint': {
                        'circle-color': '#4264fb',
                        'circle-radius': 7,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#ffffff'
                    }
                });
                // Create a popup, but don't add it to the map yet.
                const popup = new mapboxgl.Popup({
                    closeButton: false,
                    closeOnClick: false
                });
                map.on('mouseenter', 'places', (e) => {
                    // Change the cursor style as a UI indicator.
                    map.getCanvas().style.cursor = 'pointer';                    
                    // Copy coordinates array.
                    const coordinates = e.features[0].geometry.coordinates.slice();
                    const description = e.features[0].properties.description;                    
                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }                    
                    // Populate the popup and set its coordinates
                    // based on the feature found.
                    popup.setLngLat(coordinates).setHTML(description).addTo(map);
                });
                
                map.on('mouseleave', 'places', () => {
                    map.getCanvas().style.cursor = '';
                    popup.remove();
                });
            });            
        }    
        if(device !== '' && startDate !== '' && endDate !== '') {
            setDataLoaded(true);
        }            
    }, [geomap_coordinates]);

  const handleSubmitData = async (event) => {
    event.preventDefault();
    await dispatch(setClearGeomapCoordinates());
    let data = {
        name: device,
        date_inicial: startDate,
        date_final: endDate
    }
    if(device !== '' && startDate !== '' && endDate !== '') {
        setLoading(true);
        await dispatch(getMapCoordinates(data));
        setLoading(false);
    }    
  }

  return (
    <div className='home-geomap__container'>
        <div className='home-geomap__filters'>
            <h1 className='mb-1'>Geomapa</h1>
            <div className='home-geomap__form-statics__container'>
                <Form className='home-geomap__form' id='form-geomap' onSubmit={handleSubmitData}>
                    <Form.Group>
                        <Form.Label>Motorizados</Form.Label>
                        <Form.Select name='geo-select-device' size='sm' aria-label="Default select example" onChange={(e) => setDevice(e.target.value)} required={true}>
                            <option>Seleccione opcion</option>
                            { deviceValues.map((device, index) => (
                                    <option key={index} value={device}>{device}</option>
                                ))
                            }
                        </Form.Select>                
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha inicio</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            id="geo-date-start"
                            name='geo-date-start' 
                            onChange={(e) => setStartDate(e.target.value)}      
                            size='sm'
                            required={true} 
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Fecha fin</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            id="geo-date-end"
                            name='geo-date-start' 
                            onChange={(e) => setEndDate(e.target.value)} 
                            size='sm'
                            required={true}               
                        />
                    </Form.Group>
                    <Button size='sm' type='submit'>Consultar</Button>
                </Form>
                { Object.keys(geomap_coordinates).length > 0 &&
                    <div className='home-geomap__form-statics'>
                        <div className='home-geomap__form-statics__row'><h5>Puesto:</h5><span>{geomap_coordinates.data[0]?.personnel__caption}</span></div>
                        <div className='home-geomap__form-statics__row'><h5>Esquema:</h5><span>{geomap_coordinates.data[0]?.start_location__name} al {geomap_coordinates?.data[0].final_location__name}</span></div>
                        <div className='home-geomap__form-statics__row'><h5>Distancia:</h5><span>{geomap_coordinates.data[0]?.kilometres}</span></div>
                        <div className='home-geomap__form-statics__row'><h5>Vel promedio:</h5><span>{geomap_coordinates?.average_speed_kph.toFixed(2)} km/h</span></div>
                        <div className='home-geomap__form-statics__row'><h5>Numero de recorridos:</h5><span>{geomap_coordinates?.routes}</span></div>
                        <div className='home-geomap__form-statics__row'><h5>Kilometros recorridos en turno:</h5><span>{geomap_coordinates?.total_distance_km.toFixed(2)} Km</span></div>
                    </div>
                }
            </div>
        </div>
        <div className='home-geomap__map'> 
            { loading && 
                <div className='home-geomap__map__loader'><Spinner animation="border" variant="secondary" size="md" /></div> 
            }
            { Object.keys(geomap_coordinates).length > 0 &&
                <div id="map"></div>
            }
            { Object.keys(geomap_coordinates).length === 0 && !loading &&
                <div className='home-geomap__map__no-data'><h4>{!dataLoaded ? 'Realice una consulta' : 'No hay datos disponibles con la consulta realizada'}</h4></div>
            }
        </div>
    </div>    
  );
}
