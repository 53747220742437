import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Apis
import { LoginApi } from '../apis/AuthApi';

export const getToken = createAsyncThunk (
  'auth/getToken',
  async (credentials) => {
    const response = await LoginApi(credentials);
    return response.data;
  }
);

export const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    token: undefined,
    status: '',
    error: '',
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAuthStatus: (state, action) => {
      state.status = action.payload;
    }   
  },
  extraReducers: {
    [getToken.pending]: (state) => {
      state.status = 'loading';
    },
    [getToken.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.token = action.payload.token;
    },
    [getToken.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setToken, setAuthStatus } = AuthSlice.actions

export default AuthSlice.reducer
