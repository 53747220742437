// React
import React from "react";

// Bootstrap
import { Form } from "react-bootstrap";

function HomeDynamicInputCheckComponent ({
    id,
    caption,
    question_type,
    is_required,
    answers,
    onhandleCheked
}) {

    const handleChecked = (event) => {
        onhandleCheked({ id: event.target.value, status: event.target.checked })
    }
    
    return (    
        <React.Fragment>
            <Form.Label>{caption}</Form.Label>
            { answers.length > 0 && answers.map((answer) => (
                <Form.Group key={answer.id} className="mb-3" controlId={answer.id}>
                    <Form.Check                         
                        type="checkbox" 
                        label={answer.caption} 
                        name={answer.id} 
                        defaultValue={answer.id}
                        defaultChecked={answer.is_default}
                        onChange={(event) => handleChecked(event)}
                        feedback="Campo obligatorio."
                        feedbackType="invalid"
                        required={is_required}
                    />
                </Form.Group>
                ))            
            }
        </React.Fragment>            
    )
}

export default HomeDynamicInputCheckComponent
