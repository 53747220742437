import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

// Boostrap
import Dropdown from 'react-bootstrap/Dropdown';
import Col from 'react-bootstrap/Col';
import { Row } from "react-bootstrap";
import Image from 'react-bootstrap/Image';

// Actions
import { setToken, setAuthStatus } from "../../../app/auth/slice/AuthSlice";
import { setUser, setIsCreateUser, getUserRoles } from "../../../app/home/slice/HomeSlice";

// Icons
import { MdPermIdentity, MdOutlineExpandMore } from 'react-icons/md';

// Assets
import logoWhite from '../../../assets/images/logo-white.png'

// Styles
import './NavBarComponent.styles.css'

const NavBarComponent = () => {
  // React redux
  const dispatch = useDispatch();
  const home_user = useSelector(state => state.home.user);

  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear();
    dispatch(setToken(undefined));
    dispatch(setUser(undefined));
    dispatch(setAuthStatus(''));
    navigate('/auth/login');
  }

  const handleCreateUser = () => {
    dispatch(getUserRoles());
    dispatch(setIsCreateUser(true));
  }

  return (
    <Row className="nav-bar" >
      <Col className="nav-bar__logo" lg={2} md={2} sm={4} xs={4}><Image src={logoWhite} alt='interglobal logo' className=''/></Col>
      <Col className="nav-bar__container" lg={10} md={10} sm={8} xs={8}>
        <div className="nav-bar__actions">
          <MdPermIdentity className="nav-bar__user-img"/>
          <Dropdown align="start">
            <Dropdown.Toggle  id="dropdown-basic" >
            <div className="nav-bar__username"> <span>{home_user?.username}</span>  <MdOutlineExpandMore className="nav-bar__expand-more" /> </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={logout}>Salir</Dropdown.Item>
              {home_user?.role === 'Admin' && <Dropdown.Item onClick={() => {handleCreateUser()}} >Crear Usuario</Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Col>
    </Row>        
  );
}

export default NavBarComponent;