
// React
import React, { useRef } from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { postAnswersNovelty, setNoveltyForm, patchEditNovelty, deleteNoveltyFile, } from "../../../slice/HomeSlice";

// Components
import HomeDynamicInputCheckComponent from "../home-dynamic-input-check/HomeDynamicInputCheckComponent";
import HomeDynamicInputComponent from "../home-dynamic-input/HomeDynamicInputComponent";
import HomeDynamicInputFileComponent from "../home-dynamic-input-file/HomeDynamicInputFileComponent";
import HomeDynamicListComponent from "../home-dynamic-list/HomeDynamicListComponent";
import HomeDynamicBooleanComponent from "../home-dynamic-boolean/HomeDynamicBooleanComponent";
import HomeDynamicGeolocationComponent from "../home-dynamic-geolocation/HomeDynamicGeolocationComponent";
import HomeDynamicInputDetailComponent from "../home-dynamic-input-detail/HomeDynamicInputDetailComponent";

// Bootstrap
import Button from 'react-bootstrap/Button';
import { Form } from "react-bootstrap";
import Card from 'react-bootstrap/Card';

// Styles
import './HomeDynamicComposeFormComponent.styles.css'

function HomeDynamicComposeFormComponent (props) {
    const formRef = useRef(null);
    // React home redux states
    const dispatch = useDispatch();
    const home_user = useSelector(state => state.home.user);

    // Component states
    const [validated, setValidated] = React.useState(false);
    const [formFiles, setFormFiles] = React.useState();
    const [detailAnswers, setDetailAnswers] = React.useState([]);
    const [orderedFormData, setOrderedFormData] = React.useState();

    const handleDeleteNoveltyFile = (novelty_file_id) => {
        dispatch(deleteNoveltyFile(novelty_file_id));
    }

    React.useEffect(() =>{
        if(props.form_data) {
            const orderedQuestions = [...props.form_data?.questions];        
            const { questions, ...copiedFormData } = props.form_data;
            orderedQuestions.sort((a, b) => a.orderPosition - b.orderPosition);
            copiedFormData.questions = orderedQuestions;
            setOrderedFormData(copiedFormData);
        }
    }, [props.form_data]);


    const handleComposeForm = (question, index) => {
        const { uuid, caption, formField, answers, isRequired, hiden } = question;
        switch (formField.name) {
            case "Text":
            case "Date":
            case "Email":
            case "Phone":
            case "Number":
                return (
                    <div key={`${uuid}_${index}`} className={`home-dynamic-compose-form__input ${hiden ? 'hide' : ''}`}>
                        <HomeDynamicInputComponent 
                            key={`${uuid}_${index}`} 
                            form_question={question}
                            id={question.uuid}
                            caption={caption}
                            is_required={isRequired}
                            validated={validated}
                            answers={answers}
                            noveltyAction={props.noveltyAction}
                        />                        
                    </div>
                );
            case "Single_choice":
            case "Boolean":
                return (
                    <div key={`${uuid}_${index}`} className={`home-dynamic-compose-form__input ${hiden ? 'hide' : ''}`}>
                        <HomeDynamicBooleanComponent 
                            key={`${uuid}_${index}`}
                            form_question={question}
                            id={question.uuid}
                            name={question.uuid}
                            defaultValue={question.value}
                            noveltyAction={props.noveltyAction}
                        />                        
                    </div>
                );
                        
            case "File":
                return (
                    <div key={`${uuid}_${index}`} className={`home-dynamic-compose-form__input ${hiden ? 'hide' : ''}`}>
                        <HomeDynamicInputFileComponent 
                            key={`${uuid}_${index}`} 
                            id={question.uuid}
                            caption={caption}
                            question_type={formField}
                            multiple={false}
                            is_required={isRequired}
                            handleFiles={(files) => {setFormFiles(files)}}
                            answers={answers}
                            validated={validated}
                            noveltyAction={props.noveltyAction}
                            handleViewFiles={props.handleViewFiles}
                            form_data={props.form_data}
                            handleDeleteNoveltyFile={(novelty_file_id) => { handleDeleteNoveltyFile(novelty_file_id) }}
                        />
                    </div>
                );
            case "Multi_choice":
                return (
                    <div key={`${uuid}_${index}`} className={`home-dynamic-compose-form__input ${hiden ? 'hide' : ''}`}>
                        <HomeDynamicInputCheckComponent
                            id={question.uuid}
                            caption={caption}
                            question_type={formField}
                            is_required={isRequired}
                            onhandleCheked={()=>{}}
                            validated={validated}
                        />                        
                    </div>
                );
            case "List":
            case "ExternalData":
                return(
                    <div key={`${uuid}_${index}`} className={`home-dynamic-compose-form__input ${hiden ? 'hide' : ''}`}>
                        <HomeDynamicListComponent 
                            id={question.uuid}
                            caption={caption}
                            question_type={formField}
                            is_required={isRequired}
                            answers={answers} 
                            noveltyAction={props.noveltyAction}  
                            question_jsonSchema={question.jsonSchema} 
                            novelty_external_id={props.novelty_external_id}    
                            novelty_id={props.novelty_id}
                            form_data_detail={props.form_data_detail}
                        />                        
                    </div>
                );
            case "Location":                
                return (
                    <div key={`${uuid}_${index}`} className={`home-dynamic-compose-form__input ${hiden ? 'hide' : ''}`}>
                        <HomeDynamicGeolocationComponent 
                            id={question.uuid}
                        />
                    </div>
                );
            case "Detail":                
                return (
                    <div key={`${uuid}_${index}`} className={`home-dynamic-compose-form__input ${hiden ? 'hide' : ''}`}>
                        <HomeDynamicInputDetailComponent 
                            key={`${uuid}_${index}`} 
                            form_question={question}
                            id={question.uuid}
                            caption={caption}
                            is_required={isRequired}
                            validated={validated}
                            answers={answers}
                            noveltyAction={props.noveltyAction}
                            formRef={formRef}
                            handleDetailAnswers={(answersInfo) => { handleDetailAnswers(answersInfo) }}
                            
                        />
                    </div>
                );
            default:
                break;               
        }
    }

    const handleDetailAnswers = (answersInfo)  => {
        setDetailAnswers((prevArray) => {
            const newArray = [...prevArray];
            answersInfo.forEach(newObject => {
              const index = newArray.findIndex(obj => obj.inputId === newObject.inputId);
                if (index !== -1) {
                    // If inputId exists, replace the value
                    newArray[index] = newObject;
                } else {
                    // If inputId doesn't exist, add a new object
                    newArray.push(newObject);
                }
            });      
            return newArray;
          });
    }

    const handleBuildAnswerQuestion = (form_answers) => {
        let answers = [];
        props.form_data?.questions.forEach(question => {
            if(form_answers.hasOwnProperty(question.uuid) && question.formField.name === 'Detail' && question.jsonSchema.subform) {                
                const matchingIdsArray = [];
                question.jsonSchema.subform.forEach(subformItem => {
                    const matchingObject = detailAnswers.find(obj => obj.inputId === subformItem.uuid);
                    if (matchingObject) {
                        if(matchingObject.hasOwnProperty('text')){
                            matchingIdsArray.push({ 
                                text: matchingObject.text ,
                                caption: matchingObject.caption,
                                inputId: matchingObject.inputId
                            });
                        }
                        if(matchingObject.hasOwnProperty('date')){
                            matchingIdsArray.push({ 
                                date: matchingObject.date,
                                caption: matchingObject.caption,
                                inputId: matchingObject.inputId
                            });
                        }
                        if(matchingObject.hasOwnProperty('datetimeLocal')){
                            matchingIdsArray.push({ 
                                date: matchingObject.datetimeLocal,
                                caption: matchingObject.caption,
                                inputId: matchingObject.inputId
                            });
                        }                        
                    }
                });
                answers.push(buildAnswerObject(question, matchingIdsArray)); 
            }             
            if(form_answers.hasOwnProperty(question.uuid) && question.formField.name !== 'Detail') {
                let answer = form_answers[question.uuid]
                answers.push(buildAnswerObject(question, answer));
            }
        });
        return answers;
    }

    const buildAnswerObject = (question, answer) => {
        switch (question.formField.name) {
            case "Text":
            case "Email":
            case "Phone":
            case "Number":                
                return {
                    question_type: question.formField.name,
                    text_answer: answer,
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: [],
                    caption: question.caption,                    
                };
            case "Date" :
                return {
                    question_type: question.formField.name,
                    text_answer: null,
                    file_document: null,
                    date_field: answer,
                    boolean_answer: null,
                    answers: [],
                    caption: question.caption,

                }
            case "Boolean" :
                return {
                    question_type: question.formField.name,
                    text_answer: null,
                    file_document: null,
                    date_field: null,
                    boolean_answer: answer,
                    answers: [],
                    caption: question.caption,    
                }
            case "List" :
            case "ExternalData":
                return {
                    question_type: question.formField.name,
                    text_answer: null,
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: [{
                        value: answer,
                        is_checked: true,
                    }],
                    caption: question.caption,    
                }
            case "Location" :
                return {
                    question_type: question.formField.name,
                    text_answer: answer,
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: [],
                    caption: question.caption,    
                }
            case "File" :
                return {
                    question_type: question.formField.name,
                    text_answer: null,
                    file_document: question.answers?.file_document && props.noveltyAction === 'edit_novelty' ? question.answers?.file_document : null,
                    date_field: null,
                    boolean_answer: null,
                    answers: [],
                    caption: question.caption,    
                }
            case "Detail" :
                return {
                    question_type: question.formField.name,
                    text_answer: null,
                    file_document: null,
                    date_field: null,
                    boolean_answer: null,
                    answers: answer ? answer : null,
                    caption: question.caption,
                }      
            default:
                break;
        }
    }  
    
    const handleOnSubmit = (event) => {           
        const form = event.currentTarget;       
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            const formElement = document.querySelector('#composeForm');
            const formData = new FormData(formElement);
            const filesFormData = new FormData();
            const formAnswers = Object.fromEntries(formData);
            let answers = handleBuildAnswerQuestion(formAnswers);
            let submit_form = {
                form: props.form_data.id,
                novelty_id: props.novelty_id ? props.novelty_id : null,
                user: {
                    id: home_user.id,
                    name: home_user.username,
                    email: home_user.email
                },
                answers: answers,
            }
            if(props.novelty_id) {
                // if novelty is edited
                filesFormData.append('data', JSON.stringify(submit_form));                
                if(formFiles) {
                    for (let file of formFiles) {
                        const image = new File([file], `${file.name}`, {
                            //type: 'image/png'
                            type: ''
                        })
                        filesFormData.append('file', image);
                    }
                }
                dispatch(patchEditNovelty(filesFormData));
            } else {
                // if novelty is created 
                //TODO check file type
                filesFormData.append('data', JSON.stringify(submit_form));                
                if(formFiles) {
                    for (let file of formFiles) {
                        const image = new File([file], `${file.name}`, {
                            //type: 'image/png'
                            type: ''
                        })
                        filesFormData.append('file', image);
                    }
                }
                dispatch(postAnswersNovelty(filesFormData));
            }
            props.handleResetForm();
        }      
        setValidated(true);           
    }
    return (
        <Card className="home-dynamic-compose-form__container">
            <Card.Header>{props.form_data?.caption}</Card.Header>
            <Card.Body>
                <Form
                    id="composeForm"
                    onSubmit={handleOnSubmit}
                    noValidate 
                    validated={validated}
                    ref={formRef}
                >
                    { orderedFormData?.questions.map((question, index) => handleComposeForm(question, index)) }
                    <div className="home-dynamic-compose-form__actions-container">
                        { props.noveltyAction === 'view_novelty' && 
                            <div className="home-dynamic-compose-form__view-novelty-actions">
                                <Button 
                                    variant="primary"
                                    type="button"
                                    onClick={() => {
                                        const URL = `http://35.225.45.239/v1/api/news-report/["${props.novelty_id}"]/`
                                        window.open(URL, '_blank');
                                    }}
                                >Exportar</Button>
                                <Button variant="secondary" type="button" onClick={() => {props.handleOnCancelBtn()}}>Cerrar</Button>
                            </div>                            
                        }
                        { props.noveltyAction !== 'view_novelty' && <Button variant="secondary" type="button" onClick={() => {props.handleOnCancelBtn()}}>Cancelar</Button>}
                        { props.noveltyAction !== 'view_novelty' && <Button type="submit">Guardar</Button>}
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}

export default HomeDynamicComposeFormComponent;