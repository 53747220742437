// React
import React from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { patchUpdateUserRole, patchChangeUserPassword, getUsers, setIsCreateUser } from '../../../home/slice/HomeSlice';

// Bootstrap
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

// React Icons
import { MdClear, MdSave, MdSearch, MdClose } from "react-icons/md";

// Component Styles
import './HomeUsersListComponent.styles.css';

function HomeUsersListComponent(props) {

  // React home redux states
  const dispatch = useDispatch();
  const users_list = useSelector(state => state.home.users_list);
  const user_roles = useSelector(state => state.home.user_roles);

  // Component states
  const [users, setUsers] = React.useState([]);
  const [check, setCheck] = React.useState('');
  const [isChecked, setIsChecked] = React.useState(false);
  const [roleId, setRoleId] = React.useState('');
  const [userRoleId, setUserRoleId] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [searchWord, setSearchWord] = React.useState('');

  React.useEffect(() => {
    if(users_list) {
      setUsers(users_list);
    }
  }, [users_list]);

  const handleSaveChanges = async (user_id) => {
    if(roleId && userRoleId) {
      let object = {
        user_role_id: userRoleId,
        role_type: roleId,
      }
      await dispatch(patchUpdateUserRole(object));
    }
    if(newPassword) {
      let object = {
        user_id: user_id,
        new_password: newPassword,
      }
      await dispatch(patchChangeUserPassword(object));
    }    
    setIsChecked(false);
    setRoleId('');
    setUserRoleId('');
    setNewPassword('');
    await dispatch(getUsers());
  }

  const handleSearchUser = (word) => {
    setSearchWord(word)
    const foundUsers = [];
    for (const user of users_list) {
        if (user.hasOwnProperty("username") && user.username.toLowerCase() === word.toLowerCase()) {
            foundUsers.push(user);
        }
    }
    for (const user of users_list ) {
      const username = user.username.toLowerCase(); 
      if (username.startsWith(word.toLowerCase())) {
        foundUsers.push(user)
      }
    }
    setUsers(foundUsers);
  }

  const clearSearch = () => {
    setSearchWord(''); 
    setUsers(users_list);
    setIsChecked(false);
    setRoleId('');
    setUserRoleId('');
    setNewPassword(''); 
  }

  return (
    <Card className="m-3 home-users-list__container">
      <Card.Header>
        <Form>
          <InputGroup className="mb-3 mt-3 home-users-list__search-user">
            <Form.Control
              placeholder="Buscar usuario"
              aria-label="Buscar usuario"
              aria-describedby="basic-addon2"
              onChange={(e) => handleSearchUser(e.target.value)}
              value={searchWord}
            />
            <InputGroup.Text id="basic-addon2">{ searchWord.length > 0 ? <MdClose onClick={() => {clearSearch() }}/> : <MdSearch />}</InputGroup.Text>
          </InputGroup>
        </Form>
        <Button onClick={() => dispatch(setIsCreateUser(true))}>Crear usuario</Button>
      </Card.Header>
      <Card.Body>
        <Table striped bordered hover responsive className="text-center">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Nombre(s)</th>
              <th>Apellidos</th>
              <th>Teléfono</th>
              <th>Email</th>
              <th>Rol</th>
              <th>Contraseña</th>
              <th>Editar</th>            
            </tr>
          </thead>
          <tbody>
            { users?.map((user, index) => 
                <tr key={index}>
                  <td>{user.username}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.phone}</td>
                  <td>{user.email}</td>
                  <td>
                    { (isChecked && index === check)
                      ?
                      <Form.Select size="sm" aria-label="Default select example" onChange={(event) => { setRoleId(event.target.value); setUserRoleId(user.role_id); }}>
                        <option>Seleccione un rol</option>
                        { user_roles?.map((role, index) => (
                          <option key={index} value={role.id}>{role.caption}</option>
                        ))}
                      </Form.Select>
                      :
                      user.role
                    }
                  </td>  
                  <td>
                    { (isChecked && index === check)
                      ?
                      <Form.Control size="sm" type="text"  className="text-center" onChange={ (event) => setNewPassword(event.target.value) } />                  
                      :
                      <div>&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;</div>
                    }                  
                  </td>
                  <td className="home-users-list__table-actions__container">
                    <Form.Check 
                      checked={ isChecked && (index === check) ? true : false } 
                      aria-label="option 1" 
                      onChange={() => {
                        setCheck(index);
                        setIsChecked(true);
                      }} 
                    />
                    { isChecked && (index === check) && 
                      <div className="home-users-list__table-actions">
                        <MdClear onClick={() => {
                          setIsChecked(false);
                          setRoleId('');
                          setUserRoleId('');
                          setNewPassword('');                        
                        }}
                      />
                        <MdSave onClick={ () => handleSaveChanges(user.id) } />
                      </div>
                    }
                  </td>             
                </tr>
              ) 
            }
          </tbody>
          
        </Table>
        { users.length === 0 && <div className="home-users-list__no-results"><span>No se encontraron resultados</span></div>}
      </Card.Body>
    </Card>
  )
}

export default HomeUsersListComponent;