// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-bar__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
}

.nav-bar__container button#dropdown-basic {
    background-color: transparent;
    color: #555d68;
    border: none;
    padding: 0;
}

.nav-bar__container .dropdown-toggle::after {
    display: none;
}

.nav-bar__actions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-right: 32px;
}

.nav-bar__actions .nav-bar__user-img {
    font-size: 32px;
    color: #19215C;
}

.nav-bar__expand-more {
    font-size: 24px;
    color: #1C1B1F;
}

.nav-bar__logo {
    background-color: #19215C;  
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;  
}

.nav-bar__username {
    color: #19215C;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    }

.nav-bar {
    position: fixed;
    width: 100%;
    height: 80px;
    z-index: 998;
}

@media (max-width: 991px) { 
    .nav-bar__logo {
        background-color: white;
    }
    .nav-bar__logo h4 {
        display: none;
    }
}


`, "",{"version":3,"sources":["webpack://./src/shared/components/navbar/NavBarComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;AAC3B;;AAEA;IACI,6BAA6B;IAC7B,cAAc;IACd,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB;;AAEJ;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".nav-bar__container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    background-color: white;\n}\n\n.nav-bar__container button#dropdown-basic {\n    background-color: transparent;\n    color: #555d68;\n    border: none;\n    padding: 0;\n}\n\n.nav-bar__container .dropdown-toggle::after {\n    display: none;\n}\n\n.nav-bar__actions {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n    margin-right: 32px;\n}\n\n.nav-bar__actions .nav-bar__user-img {\n    font-size: 32px;\n    color: #19215C;\n}\n\n.nav-bar__expand-more {\n    font-size: 24px;\n    color: #1C1B1F;\n}\n\n.nav-bar__logo {\n    background-color: #19215C;  \n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 12px;  \n}\n\n.nav-bar__username {\n    color: #19215C;\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    }\n\n.nav-bar {\n    position: fixed;\n    width: 100%;\n    height: 80px;\n    z-index: 998;\n}\n\n@media (max-width: 991px) { \n    .nav-bar__logo {\n        background-color: white;\n    }\n    .nav-bar__logo h4 {\n        display: none;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
