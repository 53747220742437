// React
import React from "react";

// Bootstrap
import { Form } from "react-bootstrap";

function HomeDynamicGeolocationComponent(props) {
    const [geolocation, setGeolocation] = React.useState('');

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error, options);
    });

    const options = {
		enableHighAccuracy: true,
		timeout: 5000,
		maximumAge: 0,
	};
	  
    function success(pos) {
        const crd = pos.coords;        
        setGeolocation(`${crd.latitude}, ${crd.longitude}`);
    }
    
    function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    return (
        <Form.Group className="mb-3" >
            <Form.Control 
                type='text'
                name={props.id}
                defaultValue={geolocation}
            />
        </Form.Group>
    )
}

export default HomeDynamicGeolocationComponent;