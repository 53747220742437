// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/login-bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container.container {
    display: flex;
    gap: 64px;
    align-items: center;
    height: 100vh;
    justify-content: center;
    max-width: 100%;
    background-color: #DFE5F9;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;   
}

.login-container__image {
    width: 20%;
}

.login__card {
    padding: 24px 48px;

}

.login__btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fade.toast.login__toast.show {
    position: fixed;
    bottom: 10px;
    left: 10px;
}

.auth-login__title {
    margin-bottom: 24px;
}

@media (max-width: 1024px) and (orientation: portrait) { 
    .login-container.container {
        flex-direction: column;
    }

    .login-container__image {
        width: 25%;
    }
}

@media (max-width: 640px) and (orientation: landscape) { 
    .login-container__image {
        width: 40%;
    }
}

`, "",{"version":3,"sources":["webpack://./src/app/auth/components/auth-login/AuthLoginComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,yBAAyB;IACzB,yDAA+D;IAC/D,4BAA4B;IAC5B,wBAAwB;IACxB,0BAA0B;AAC9B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,sBAAsB;IAC1B;;IAEA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".login-container.container {\n    display: flex;\n    gap: 64px;\n    align-items: center;\n    height: 100vh;\n    justify-content: center;\n    max-width: 100%;\n    background-color: #DFE5F9;\n    background-image: url('../../../../assets/images/login-bg.png');\n    background-repeat: no-repeat;\n    background-size: contain;\n    background-position: right;   \n}\n\n.login-container__image {\n    width: 20%;\n}\n\n.login__card {\n    padding: 24px 48px;\n\n}\n\n.login__btn-action {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.fade.toast.login__toast.show {\n    position: fixed;\n    bottom: 10px;\n    left: 10px;\n}\n\n.auth-login__title {\n    margin-bottom: 24px;\n}\n\n@media (max-width: 1024px) and (orientation: portrait) { \n    .login-container.container {\n        flex-direction: column;\n    }\n\n    .login-container__image {\n        width: 25%;\n    }\n}\n\n@media (max-width: 640px) and (orientation: landscape) { \n    .login-container__image {\n        width: 40%;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
