// React
import React from "react";

// React redux
import { useDispatch } from 'react-redux';
import { deleteNoveltyFile } from "../../slice/HomeSlice";

// Bootstrap
import { Button } from "react-bootstrap";
import { Carousel } from "react-bootstrap";

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";

// Styles
import './HomeCarouselModalComponent.styles.css'

function HomeCarouselModalComponent(props) {

    // React home redux states
    const dispatch = useDispatch();
    
    // Component states
    const [index, setIndex] = React.useState();
    const [noveltyFileId, setNoveltyFileId] = React.useState();

    React.useMemo(() => {
        if(props.galleryFiles){
            setIndex(props.galleryFiles?.index);
        }
    }, [props.galleryFiles]);
    
    const handleSelect = (selectedIndex) => {
        setNoveltyFileId(props.galleryFiles.files[selectedIndex].novelty_file_id);
        setIndex(selectedIndex);
    }

    const handleDeleteNoveltyFile = () => {
        dispatch(deleteNoveltyFile(noveltyFileId));
    }

	return ( 
		<ModalComponent 
			show={props.show}
			onHide={props.onHide}
			title={props.isTransferGallery ? 'Imagenes' : `Archivos Novedad ${props.galleryFiles?.form_type}`}
            size='lg'
            centered={true}
        >
            { props.galleryFiles &&
                <Carousel className='home-carousel-modal' interval={null} activeIndex={index} onSelect={handleSelect}>
                    { props.galleryFiles.files?.map((file, index) => (                        
                        <Carousel.Item key={index}>
                            { !props.isTransferGallery && file.url && file.url.includes('.pdf') &&                                
                                <object data={file.url} type="application/pdf" width="100%" height={700}>
                                    <h4>
                                        Este navegador no soporta este archivo.
                                        Alternativamente <a href={file.url} target="_blank" rel="noreferrer" >descargue/consulte aqui.</a>
                                    </h4>
                                </object>
                            }
                            { !props.isTransferGallery && file.url && !file.url.includes('.pdf') &&
                                <img
                                    className="d-block w-100"
                                    src={file.url}
                                    alt={`img_${index}`}
                                />
                            }
                            { props.isTransferGallery && file.transfer_file &&
                                <img
                                    className="d-block w-100"
                                    src={file.transfer_file}
                                    alt={`img_${index}`}
                                />
                            }
                        </Carousel.Item>
                    ))
                    }
                </Carousel>
            }
            <div className={`home-carousel-modal__actions`}>
                <Button variant="secondary" onClick={props.onHide}>Cerrar</Button>
                {props.noveltyAction === 'edit_novelty' && <Button variant="danger" onClick={() => { handleDeleteNoveltyFile() }}>Eliminar</Button>}
            </div>
		</ModalComponent>
	)
}

export default HomeCarouselModalComponent;