// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-update-password__btns-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}

.home-update-password__text {
    margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-update-password-modal/HomeUpdatePasswordModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".home-update-password__btns-action {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 12px;\n}\n\n.home-update-password__text {\n    margin-bottom: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
