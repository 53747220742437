// React
import React from "react";

// React redux
import { useDispatch } from 'react-redux';
import { postTransfersFormLocations, getTransfersFormLocations } from "../../slice/HomeSlice";

// Bootstrap
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";

// Styles
import './HomeCreateTransferLocationModalComponent.styles.css'

function HomeCreateTransferLocationModalComponent(props) {

	// React redux states
	const dispatch = useDispatch();

	const handleSubmit = async (event) => {
		event.preventDefault();
		const formElement = document.querySelector('#createTransferLocation');
		const formData = new FormData(formElement);
		const formDataJSON = Object.fromEntries(formData);
		await dispatch(postTransfersFormLocations(formDataJSON));
		dispatch(getTransfersFormLocations());
		props.onHide();
	}

	return ( 
		<ModalComponent 
			show={props.show}
			onHide={props.onHide}
			title={props.locationType === 'add_origin' ? 'Origen del despacho' : 'Sitio de destino'}
		>
			<Form
				id="createTransferLocation" 
				onSubmit={handleSubmit}
				noValidate 
			>                    
				<Form.Group className="mb-3">
					<Form.Label>{props.locationType === 'add_origin' ? 'Agregar origen' : 'Agregar destino'}</Form.Label>
					<Form.Control 
						type="text"
						placeholder=""
						name="name"
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>

				<div className="home-create-user-modal__btns-action">
					<Button variant="secondary" onClick={() => {props.onHide()}}>
							Cancelar
					</Button>
					<Button variant="primary" type="submit">Crear</Button>
				</div>
			</Form>
		</ModalComponent>
	)
}

export default HomeCreateTransferLocationModalComponent;