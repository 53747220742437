import React from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { searchUsers, patchTransfer, getTransfers } from "../../slice/HomeSlice";

// React Select
import AsyncSelect from 'react-select/async';

// Bootstrap
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";
import CustomDropdownSelectComponent from "../../../../shared/components/custom-dropdown-select/CustomDropdownSelectComponent";

// Icons
import { MdCloudUpload } from 'react-icons/md';

// Styles
import './HomeFinalizeTransferModalComponent.styles.css'

export default function HomeFinalizeTransferModalComponent (props) {

    const fileInput = React.createRef();

    // React redux states
	const dispatch = useDispatch();
    const loader = useSelector(state => state.home.loader);
    const search_users_result = useSelector(state => state.home.search_users_result);
    const transfers_form_locations = useSelector(state => state.home.transfers_form_locations);

	// Component states
	const [validated, setValidated] = React.useState(false);
    const [userResults, setUserResults] = React.useState([]);
    const [counter, setCounter] = React.useState(0);
    const [assetsLocationArray, setAssetsLocationArray] = React.useState([]);
    const [assetLocationOrigin, setAssetLocationOrigin] = React.useState();
    const [formFiles, setFormFiles] = React.useState();
    const [transferState, setTransferState] = React.useState('');

    React.useEffect(() => {
        let user_results = [];
        if(search_users_result) {
            search_users_result.forEach(user => {
                let obj = {
                    label: `${user.first_name} ${user.last_name} (${user.username})`,
                    value: user.id
                }
                user_results.push(obj);                
            });
            setUserResults(user_results)
        }        
    },[search_users_result]);

    React.useEffect(() => {
        if(transfers_form_locations?.results) {
            let location_results = [];
            transfers_form_locations.results.forEach(location => {
                let obj = {
                    value: location.name,
                    id: location.id,
                }
                location_results.push(obj);                
            });
            setAssetsLocationArray(location_results);
        }
    }, [transfers_form_locations]);

    const submitFinalizeTransferForm = async (event) => {
        const filesFormData = new FormData();
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			const formElement = document.querySelector('#finalizeTransferForm');
			const formData = new FormData(formElement);
			const formDataJSON = Object.fromEntries(formData);
            let formDataObj = {
                date_of_arrival_reception: formDataJSON.date_of_arrival_reception,
                user_receives_destination: formDataJSON.user_receives_destination,
                receiving_site: assetLocationOrigin.id,
                location_income: formDataJSON.location_income,
                state: transferState,
            }
            filesFormData.append('data', JSON.stringify(formDataObj));            
            if(formFiles) {
                for (let file of formFiles) {
                    const image = new File([file], `${file.name}`, {
                        type: ''
                    })
                    filesFormData.append('file', image);
                }
            }
            let obj = {
                id: props.transfer_id,
                transfer: filesFormData
            }
            await dispatch(patchTransfer(obj));
            await dispatch(getTransfers());
            setCounter(0);
		}
        setTransferState('');
        setValidated(true);
    }

    const filterOptions = (inputValue) => {
        return  userResults?.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));
    }

    const promiseOptions = (inputValue) => {
        if(inputValue !== '') {
            dispatch(searchUsers(inputValue))
            return new Promise((resolve) => {
                setTimeout(() => {
                resolve(filterOptions(inputValue));
                }, 1000);
            });
        }        
    }


    return (
        <ModalComponent 
            show={props.show}
            onHide={props.onHide}
            title='Finalizar transferencia'
        >
            <Form
                id="finalizeTransferForm" 
                onSubmit={submitFinalizeTransferForm}                
            >                   
                <Form.Group className="mb-3">
                    <Form.Label>Fecha llegada portería</Form.Label>
                    <Form.Control type="datetime-local" name='date_of_arrival_reception' required/>
                    <Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Usuario que recibe</Form.Label>
                    <AsyncSelect name="user_receives_destination" required cacheOptions defaultOptions loadOptions={(value) => promiseOptions(value, 'search_user')} />
                </Form.Group>
                <Form.Group className="mb-3 ">
                    <Form.Label>Sitio donde se recibe</Form.Label>
                    <div className="home-transfer-asset__create-location">
                        <CustomDropdownSelectComponent 
                            options={assetsLocationArray}
                            handleSearchInput={ (value) => console.log(value) }
                            name='receiving_site' 
                            id={'origin_select'}
                            placeholder='Buscar destino'
                            //maxLength={3}
                            required={true} 
                            disabled={false}
                            //defaultValue={''}
                            noveltyAction={''}
                            handleClearSearchArray={() => console.log('delete')}
                            handleSelectOption={(option) => setAssetLocationOrigin(option)}                            
                        />                
                        <Button size="sm" onClick={() => props.handleAddLocation('add_destination')}>Agregar destino</Button>
                    </div>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Estado</Form.Label>
                    <Form.Select 
                        aria-label="Default select example" 
                        onChange={(e) => {
                            setTransferState(e.target.value)}
                        }
                        value={transferState}
                        required={true}
                    >
                        <option>Filtrar por estado</option>
                        <option value="Active">Activos</option>
                        <option value="Finalized">Finalizados</option>
                        <option value="Finished_With_Remarks">Finalizados con observaciones</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <div className="home-finalize-transfer-modal__label">
                        <Form.Label>Observaciones</Form.Label>
                        <span>{`${counter}/100`}</span>
                    </div>
                    <Form.Control maxLength={100} as="textarea" rows={3} name='location_income' onChange={(e) => setCounter(e.target.value.length)}/>
                </Form.Group>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Subir imagenes</Form.Label>
                    <div className="home-transfer-asset__input-img">
                        <label htmlFor="inputGroupFile"><MdCloudUpload /></label>
                        <input 
                            style={{display: "none"}}
                            type="file"
                            multiple={true}
                            id="inputGroupFile"
                            name="file"
                            onChange={(e) => setFormFiles(e.target.files)}
                            ref={fileInput}
                            accept="image/*,.pdf"
                        />
                        <Form.Control type="text" placeholder={formFiles?.length > 0 ? `${formFiles?.length} archivos seleccionados` : 'No hay archivos seleccionados'} name='file'/>
                    </div>
                </Form.Group>
                <div className="home-finalize-transfer-modal__btns-action">
                    <Button 
                        variant="secondary"
                        onClick={() => {
                            props.onHide();
                            setCounter(0);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button disabled={loader} variant="primary" type="submit">
                            {!loader ? 'Finalizar' : <Spinner animation="border" variant="light" size="sm" />}
                    </Button>
                </div>
            </Form>
        </ModalComponent>
    )
}