// React
import React from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { getEditNovelty, setEditNoveltyStatus, } from '../../slice/HomeSlice.js';

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";
import HomeDynamicComposeFormComponent from "../home-dynamic-form/home-dynamic-compose-form/HomeDynamicComposeFormComponent";

function HomeEditNoveltyModalComponent(props) {

    // React home redux states
	const dispatch = useDispatch();
    const delete_novelty_file_status = useSelector(state => state.home.status.novelty_form.delete_novelty_file_status);

    React.useEffect(() => {
        if(delete_novelty_file_status === 'succeeded') {
            dispatch(getEditNovelty(props.novelty_id));
            dispatch(setEditNoveltyStatus(''));
        }

    }, [delete_novelty_file_status, props.novelty_id, dispatch]);

    return (
        <ModalComponent
            show={props.show}
            onHide={props.onHide}
            title={props.noveltyAction === 'edit_novelty' ? `Editar novedad ${props.form_data?.novelty_type}` : `Novedad ${props.form_data?.novelty_type} `}   
            size='lg'     
        >
            <HomeDynamicComposeFormComponent 
                form_data={props.form_data?.detail.master}
                handleOnCancelBtn={props.handleOnCancelBtn}
                form_answers={props.form_answers}
                novelty_id={props.novelty_id}
                handleResetForm={props.handleResetForm}
                noveltyQuestions={props.noveltyQuestions}
                noveltyAction={props.noveltyAction}
                handleViewFiles={props.handleViewFiles}
                novelty_external_id={props.novelty_external_id}
                form_data_detail={props.form_data?.detail.detail}
            />
        </ModalComponent>
    )
}

export default HomeEditNoveltyModalComponent;