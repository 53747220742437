// React
import React from "react";
import { useNavigate } from "react-router-dom";

// React redux
import { useSelector, useDispatch } from 'react-redux'
import { getToken } from '../../slice/AuthSlice'

// Bootstrap
import { Button, Col, Container, Form, FormGroup, FormLabel } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';

// Styles
import './AuthLoginComponent.styles.css'

function AuthLoginComponent (props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth_status = useSelector(state => state.auth.status)
  const auth_token = useSelector(state => state.auth.token)
  
  
  const [show, setShow] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    if(auth_status === 'succeeded') {
      setLoader(false);      
      localStorage.clear();
      localStorage.setItem('token', auth_token);
      setTimeout(() => {
          navigate('/');
      }, 500);
    }
    if(auth_status === 'failed') {
      setShow(true)
      setLoader(false);      
    }
  }, [auth_status, auth_token, navigate ]);
  

  const submitLoginForm = async (event) => {
    setLoader(true);
    event.preventDefault();
    const formElement = document.querySelector('#loginForm');
    const formData = new FormData(formElement);
    const formDataJSON = Object.fromEntries(formData);
    
    await dispatch(getToken(formDataJSON));

  }

  return (
    <React.Fragment>
      <Container className="login-container" >
        <Card className="login__card">
          <Col >
            <h2 className="auth-login__title">Bienvenido!</h2>
            <Form id="loginForm" onSubmit={submitLoginForm}>
              <FormGroup className="mb-3">
                <FormLabel htmlFor={'login-username'}>Usuario</FormLabel>
                <input type={'text'} className="form-control" id={'login-username'} name="username" required />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel htmlFor={'login-password'}>Contraseña</FormLabel>
                <input type={'password'} className="form-control" id={'login-password'} name="password" required />
              </FormGroup>
              <div className="d-grid">
                <Button disabled={loader}  variant="primary" type="submit"  id="login-btn" className="login__btn">{!loader ? 'Ingresar' : <Spinner animation="border" variant="light" size="sm" /> }</Button>
                
              </div> 
            </Form>
          </Col>
        </Card>
        
      </Container>
      <Toast 
        onClose={() => setShow(false)}
        show={show}
        delay={5000}
        autohide
        className='login__toast'
        bg="danger"
        animation
      >
          <Toast.Body className="text-white">Las credenciales proporcionadas no coinciden!</Toast.Body>
      </Toast>
      
    </React.Fragment>
  );
}

export default AuthLoginComponent;