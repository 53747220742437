// React
import React from "react";

// React redux
import { useDispatch, useSelector } from 'react-redux';
import { postAsset, getAssets, setAsset, patchAsset } from "../../slice/HomeSlice";

// Bootstrap
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";

// Styles
import './HomeAssetsModalComponent.styles.css'

function HomeAssetsModalComponent(props) {

	// React redux states
	const dispatch = useDispatch();
	const loader = useSelector(state => state.home.loader);
    const post_asset_status = useSelector(state => state.home.status.assets.post_asset_status);
	const patch_asset_status = useSelector(state => state.home.status.assets.patch_asset_status);
	const asset = useSelector(state => state.home.asset);
	const asset_action_type = useSelector(state => state.home.asset_action_type);

	// Component states
	const [validated, setValidated] = React.useState(false);
	
    React.useEffect(() => {
        if(post_asset_status === 'succeeded' || patch_asset_status === 'succeeded') {
            dispatch(getAssets());
        }
    }, [post_asset_status, patch_asset_status, dispatch]);

	const submitAsset = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			const formElement = document.querySelector('#assetForm');
			const formData = new FormData(formElement);
			const formDataJSON = Object.fromEntries(formData);
            if(asset_action_type === 'new_asset') {
				await dispatch(postAsset(formDataJSON));	
			}
			if(asset_action_type === 'edit_asset') {
				let asset_data = {
					asset: formDataJSON,
					asset_id: asset.id
				}
				await dispatch(patchAsset(asset_data));
			}
		}
    setValidated(true);
  }

	return ( 
		<ModalComponent 
			show={props.show}
			onHide={props.onHide}
			title='Crear activo'
		>
			<Form
				id="assetForm" 
				onSubmit={submitAsset}
				noValidate 
				validated={validated}
			>                    
				<Form.Group className="mb-3">
					<Form.Label>Nombre del activo</Form.Label>
					<Form.Control 
						type="text"
						placeholder=""
						name="name"
						defaultValue={asset?.name}
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>
				<Form.Group className="mb-3">
					<Form.Label>Código</Form.Label>
					<Form.Control 
						type="text"
						placeholder=""
						name="code"
						defaultValue={asset?.code}
						required
					/>
					<Form.Control.Feedback type="invalid">Campo requerido.</Form.Control.Feedback>
				</Form.Group>
				<div className="home-assets-modal__btns-action">
					<Button variant="secondary" onClick={() => {dispatch(setAsset(undefined)); props.onHide()}}>{ asset_action_type === 'view_asset' ? 'Cerrar' : 'Cancelar' }</Button>
					{ asset_action_type === 'new_asset' &&
						<Button disabled={loader} variant="primary" type="submit">
							{!loader ? 'Crear' : <Spinner animation="border" variant="light" size="sm" />}
						</Button>
					}
					{ asset_action_type === 'edit_asset' &&
						<Button disabled={loader} variant="primary" type="submit">
							{!loader ? 'Guardar' : <Spinner animation="border" variant="light" size="sm" />}
						</Button>
					}
				</div>
			</Form>
		</ModalComponent>
	)
}

export default HomeAssetsModalComponent;