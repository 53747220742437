// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown-select__container {
    display: flex;
    align-items: center;
}

.custom-dropdown-select__clear svg {
    font-size: 1.2rem;

}

.custom-dropdown-select__clear {
    padding: 0.37rem 0.75rem;
    border: 1px solid #ced4da;
    cursor: pointer;
    border-radius: 0 0.375rem 0.375rem 0;
}

.custom-dropdown-select__container .form-control {
    border-radius: 0.375rem 0 0 0.375rem;
    border-top: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    border-right: 0;
}

.dropdown-menu.show {
    width: max-content;
    max-height: 200px;
    overflow-y: scroll;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.dropdown-item.custom-dropdown-select__msg {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/custom-dropdown-select/CustomDropdownSelectComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;;AAErB;;AAEA;IACI,wBAAwB;IACxB,yBAAyB;IACzB,eAAe;IACf,oCAAoC;AACxC;;AAEA;IACI,oCAAoC;IACpC,6BAA6B;IAC7B,8BAA8B;IAC9B,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,8CAA8C;AAClD;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".custom-dropdown-select__container {\n    display: flex;\n    align-items: center;\n}\n\n.custom-dropdown-select__clear svg {\n    font-size: 1.2rem;\n\n}\n\n.custom-dropdown-select__clear {\n    padding: 0.37rem 0.75rem;\n    border: 1px solid #ced4da;\n    cursor: pointer;\n    border-radius: 0 0.375rem 0.375rem 0;\n}\n\n.custom-dropdown-select__container .form-control {\n    border-radius: 0.375rem 0 0 0.375rem;\n    border-top: 1px solid #ced4da;\n    border-left: 1px solid #ced4da;\n    border-bottom: 1px solid #ced4da;\n    border-right: 0;\n}\n\n.dropdown-menu.show {\n    width: max-content;\n    max-height: 200px;\n    overflow-y: scroll;\n    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);\n}\n\n.dropdown-item.custom-dropdown-select__msg {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
