// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-transfer-list__btn-actions {
    min-width: 90px;
}

.home-transfer-list__filters-container {
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.home-transfer-list__btn-actions__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.home-transfer-list__btn-actions__container .btn {
    padding: 0 4px;
    min-width: 125px;
}

.home-transfer-list__assets-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
}

.home-transfers-list__table-container thead tr {
    text-align: center;
}

.home-transfers-list__table-container tbody tr {
    text-align: center;
}

.home-transfers-list__no-transfer-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    color: #19215C;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-transfers-list/HomeTransfersListComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;;AAEjC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,cAAc;AAClB","sourcesContent":[".home-transfer-list__btn-actions {\n    min-width: 90px;\n}\n\n.home-transfer-list__filters-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n\n}\n\n.home-transfer-list__btn-actions__container {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: center;\n    justify-content: center;\n}\n\n.home-transfer-list__btn-actions__container .btn {\n    padding: 0 4px;\n    min-width: 125px;\n}\n\n.home-transfer-list__assets-list {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    gap: 12px;\n}\n\n.home-transfers-list__table-container thead tr {\n    text-align: center;\n}\n\n.home-transfers-list__table-container tbody tr {\n    text-align: center;\n}\n\n.home-transfers-list__no-transfer-msg {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 300px;\n    color: #19215C;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
