// React
import React from "react";

// Boostrap
import Form from 'react-bootstrap/Form';

// Styles
import './HomeDynamicBooleanComponent.styles.css'

function HomeDynamicBooleanComponent(props) {
    return (
        <Form.Group>
            <Form.Label>{props.form_question.caption}</Form.Label>
            <Form.Check
                label="Sí"
                name={props.name}
                type="radio"
                id={props.id}
                value='True'
                disabled={props.noveltyAction === 'view_novelty' ? true : false}
            />
            <Form.Check
                label="No"
                name={props.name}
                type="radio"
                id={props.id}
                value='False'
                disabled={props.noveltyAction === 'view_novelty' ? true : false}
            />
        </Form.Group>
    )
}

export default HomeDynamicBooleanComponent;