// React
import React from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { getNovelties, getFiltersNovelites, getNoveltiesToExport, setNoveltiesToExport, deleteNovelty, deleteNovelties } from '../../../home/slice/HomeSlice';

// Bootstrap
import Table from 'react-bootstrap/Table';
import { Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Spinner from 'react-bootstrap/Spinner';
import Toast from 'react-bootstrap/Toast';

// Components
import CustomDropdownSelectComponent from "../../../../shared/components/custom-dropdown-select/CustomDropdownSelectComponent";

// Icons
import { MdModeEdit, MdSearch, /* MdDelete */ MdPriorityHigh } from 'react-icons/md';

// Moment
import * as moment from 'moment';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// SheetJS CE Docs
import { utils, writeFileXLSX } from 'xlsx';

// Styles
import './HomeNoveltiesTableComponent.styles.css';

// URL app
import { api_url } from "../../../../util/GlobalData";


function HomeNoveltiesTableComponent(props) {

    // React home redux states
    const dispatch = useDispatch();
    const novelties = useSelector(state => state.home.novelties);
    const home_user = useSelector(state => state.home.user);
    const novelties_to_export = useSelector(state => state.home.novelties_to_export);
    const users_list = useSelector(state => state.home.users_list);

    // Component states
    const [searchWord, setSearchWord] = React.useState('');
    const [dateFrom, setDateFrom] = React.useState('');
    const [dateTo, setDateTo] = React.useState('');
    const [dateExcelFrom, setDateExcelFrom] = React.useState('');
    const [dateExcelTo, setDateExcelTo] = React.useState('');
    const [dateReportFrom, setDateReportFrom] = React.useState('');
    const [dateReportTo, setDateReportTo] = React.useState('');
    const [isAnySearch, setIsAnySearch] = React.useState(false);
    const [isNoveltyTypeSearch, setIsNoveltyTypeSearch] = React.useState(true);
    const [anySearch, setAnySearch] = React.useState('');
    const [noveltyTypeSearch, setNoveltyTypeSearch] = React.useState('');
    const [isSelectAll, setIsSelectAll] = React.useState(false);
    const [noveltiesCopy, setNoveltiesCopy] = React.useState();
    const [noveltiesSelection, setNoveltiesSelection] = React.useState([]);
    const [loader, setLoader] = React.useState(false);
    const [creationDate, setCreationDate] = React.useState(true);
    const [reportDate, setReportDate] = React.useState(false);
    const [usersList, setUsersList] = React.useState([]);
    const [userExcelFilter, setUserExcelFilter] = React.useState('');
    const [noveltyTypeExcelFilter, setNoveltyTypeExcelFilter] = React.useState('');
    const [showToast, setShowToast] = React.useState(false);
    const [clearUser, setClearUser] = React.useState('');

    React.useEffect(() => {
        let query_params = `search=${searchWord}&date_from=${dateFrom}&date_to=${dateTo ? dateTo : dateFrom}&date_from_report=${dateReportFrom}&date_to_report=${dateReportTo ? dateReportTo : dateReportFrom}`
        dispatch(getFiltersNovelites(query_params));
    }, [searchWord, dateFrom, dateTo, dateReportFrom, dateReportTo, dispatch]);

    React.useEffect(() => {
        if (users_list) {
            let new_users_list = [];
            users_list.forEach(user => {
                let obj = {
                    value: user.username,
                    id: user.username,
                }
                new_users_list.push(obj);
            });
            setUsersList(new_users_list);
        }
    }, [users_list]);

    React.useEffect(() => {
        if (novelties) {
            let novelty_obj = {
                next: novelties.next,
                previous: novelties.previous,
                results: novelties.results.reduce((acc, novelty) => {
                    let novelty_obj = { ...novelty };
                    novelty_obj.checked = false;
                    acc.push(novelty_obj);
                    return acc;
                }, []),
            };
            setNoveltiesCopy(novelty_obj);
        }
    }, [novelties]);

    React.useEffect(() => {
        let new_array = [];
        const keyNameMap = {
            "novelty_type": "Tipo de novedad",
            "user": "Usuario",
            "reporting_time": "Hora del reporte",
            "reporting_date": "Fecha del reporte"
        };
        if (novelties_to_export) {
            if (novelties_to_export.length > 0) {
                novelties_to_export.forEach((novelty) => {
                    const newObject = {};
                    for (const key in novelty) {
                        if (novelty[key] !== undefined && novelty[key] !== null) {
                            if (key !== "data") {
                                const newKeyName = keyNameMap[key] || key;
                                newObject[newKeyName] = novelty[key];
                            } else {
                                const nestedKeys = Object.keys(novelty[key]);
                                for (const nestedKey of nestedKeys) {
                                    newObject[nestedKey] = novelty[key][nestedKey];
                                }
                            }
                        }
                    }
                    new_array.push(newObject);
                });
                const ws = utils.json_to_sheet(new_array);
                const wb = utils.book_new();
                utils.book_append_sheet(wb, ws, "Novelties");
                writeFileXLSX(wb, "novedades_interglobal.xlsx");
                dispatch(setNoveltiesToExport());
                setDateExcelFrom('');
                setDateExcelTo('');
                setNoveltyTypeExcelFilter('');
                setUserExcelFilter('');
                handleClearUserList();
                setClearUser('cleared');
            } else {
                setShowToast(true);
            }
            setLoader(false);
        }
    }, [novelties_to_export, dispatch]);

    const handleTableFilter = (value, query_param) => {
        setNoveltiesSelection([]);
        setIsSelectAll(false);
        switch (query_param) {
            case 'any_search':
                setAnySearch(value);
                setSearchWord(value);
                break;
            case 'novelty_search':
                setNoveltyTypeSearch(value);
                setSearchWord(value);
                break;
            case 'date_from':
                if (creationDate) {
                    setDateFrom(value);
                } else {
                    setDateReportFrom(value);
                }
                break;
            case 'date_to':
                if (creationDate) {
                    setDateTo(value);
                } else {
                    setDateReportTo(value);
                }
                break;
            default:
                break;
        }
    }

    const handleExportExcel = (value, query_param) => {
        switch (query_param) {
            case 'date_from':
                setDateExcelFrom(value);
                break;
            case 'date_to':
                setDateExcelTo(value);
                break;
            default:
                break;
        }
    }

    const handleCheck = (check) => {
        if (check === 'novelty_search') {
            setIsAnySearch(false);
            setIsNoveltyTypeSearch(true);
        }
        if (check === 'any_search') {
            setIsAnySearch(true);
            setIsNoveltyTypeSearch(false);
        }
    }

    const handleSelectNoveltiesSingles = (is_checked, novelty_id) => {
        setIsSelectAll(false);
        let novelties_array = [];
        let novelties_id_array = [];
        const updatedNoveltiesCopy = { ...noveltiesCopy }
        noveltiesCopy.results.map((novelty) => {
            if (novelty.id === novelty_id) {
                novelty.checked = is_checked;
                novelties_array.push(novelty);
            } else {
                novelties_array.push(novelty);
            }
            return null;
        });
        updatedNoveltiesCopy.results = novelties_array;
        novelties_array.map(novelty => {
            if (novelty.checked) {
                novelties_id_array.push(`"${novelty.id}"`)
            }
            return null;
        })
        setNoveltiesCopy(updatedNoveltiesCopy);
        setNoveltiesSelection(novelties_id_array);
    }

    const handleSelectAllNovelties = (is_checked) => {
        setIsSelectAll(is_checked);
        let novelties_array = [];
        let novelties_id_array = [];
        const updatedNoveltiesCopy = { ...noveltiesCopy }
        noveltiesCopy.results.map((novelty) => {
            novelty.checked = is_checked;
            novelties_array.push(novelty);
            novelties_id_array.push(`"${novelty.id}"`);
            return null;
        });
        updatedNoveltiesCopy.results = novelties_array;
        setNoveltiesCopy(updatedNoveltiesCopy);
        if (is_checked === false) {
            setNoveltiesSelection([]);
        } else {
            setNoveltiesSelection(novelties_id_array);
        }
    }

    const handleExportSelection = () => {
        const URL = `${api_url}/news-report/[${noveltiesSelection}]/`
        window.open(URL, '_blank');
    }

    const handleNextPrevious = (button_type, next_previous) => {
        setNoveltiesSelection([]);
        setIsSelectAll(false);
        if (button_type === 'next') {
            console.log(next_previous)
            if (next_previous.startsWith("http://")) {
                // Add "s" after "http://"
                let new_url = "https://" + next_previous.slice(7);
                dispatch(getNovelties(new_url));
            } else {
                // URL is already using "https://" or doesn't start with "http://"
                let new_url = next_previous;
                dispatch(getNovelties(new_url));
            }
        }
        if (button_type === 'previous') {
            if (next_previous.startsWith("http://")) {
                // Add "s" after "http://"
                let new_url = "https://" + next_previous.slice(7);
                dispatch(getNovelties(new_url));
            } else {
                // URL is already using "https://" or doesn't start with "http://"
                let new_url = next_previous;
                dispatch(getNovelties(new_url));
            }
        }
    }

    const handleClearFilters = () => {
        setIsSelectAll(false);
        setNoveltiesSelection([]);
        setSearchWord('');
        setAnySearch('');
        setNoveltyTypeSearch('');
        setDateFrom('');
        setDateTo('');
        setDateReportFrom('');
        setDateReportTo('');
        dispatch(getNovelties());
    }

    const handleDownloadExcel = async () => {
        setLoader(true);
        let export_query = ``
        if (dateExcelFrom !== '' && dateExcelTo !== '' && userExcelFilter === '' && noveltyTypeExcelFilter === '') {
            export_query = `date_from_report=${dateExcelFrom}&date_to_report=${dateExcelTo}`
        }
        if (dateExcelFrom !== '' && dateExcelTo !== '' && userExcelFilter !== '' && noveltyTypeExcelFilter === '') {
            export_query = `date_from_report=${dateExcelFrom}&date_to_report=${dateExcelTo}&user=${userExcelFilter}`
        }
        if (dateExcelFrom !== '' && dateExcelTo !== '' && userExcelFilter === '' && noveltyTypeExcelFilter !== '') {
            export_query = `date_from_report=${dateExcelFrom}&date_to_report=${dateExcelTo}&novelty_type=${noveltyTypeExcelFilter}`
        }
        if (dateExcelFrom !== '' && dateExcelTo !== '' && userExcelFilter !== '' && noveltyTypeExcelFilter !== '') {
            export_query = `date_from_report=${dateExcelFrom}&date_to_report=${dateExcelTo}&user=${userExcelFilter}&novelty_type=${noveltyTypeExcelFilter}`
        }
        await dispatch(getNoveltiesToExport(export_query));
    }

    const handleDisabledBtn = () => {
        if (!loader && dateExcelFrom !== '' && dateExcelTo !== '') {
            return false;
        } else {
            return true;
        }
    }

    const handleDeleteSelection = async () => {
        let array_to_delete = [];
        noveltiesSelection.forEach(id => {
            let clean_id = id.replace(/\\/g, '').replace(/"/g, '');
            array_to_delete.push(clean_id);
        });
        if (noveltiesSelection.length === 1) {
            await dispatch(deleteNovelty(array_to_delete[0]));
            dispatch(getNovelties());
        } else {
            await dispatch(deleteNovelties(array_to_delete));
            dispatch(getNovelties());
        }
    }

    const handleRadioChecked = (value, type) => {
        setDateFrom('');
        setDateTo('');
        setDateReportFrom('');
        setDateReportTo('');
        if (type === 'creation-date') {
            if (value === 'on') {
                setCreationDate(true);
                setReportDate(false);
            } else {
                setCreationDate(false);
                setReportDate(true);
            }
        } else {
            if (value === 'on') {
                setCreationDate(false);
                setReportDate(true);
            } else {
                setCreationDate(true);
                setReportDate(false);
            }
        }
    }

    const handleSearchUser = (query) => {
        query = query.toLowerCase();
        const filteredUsers = usersList.filter((user) => {
            const username = user.value.toLowerCase();
            return username.includes(query);
        });
        setUsersList(filteredUsers);
    }

    const handleClearUserList = () => {
        if (users_list) {
            let new_users_list = [];
            users_list.forEach(user => {
                let obj = {
                    value: user.username,
                    id: user.username,
                }
                new_users_list.push(obj);
            });
            setUsersList(new_users_list);
        }
    }

    return (
        <div className="home-novelties-table__container">
            <Card>
                <Card.Header>Lista de Novedades</Card.Header>
                <Card.Body>
                    <Accordion defaultActiveKey="0" className="mb-3">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Filtrar novedades</Accordion.Header>
                            <Accordion.Body>
                                <Form className="home-novelties-table__filters-container">
                                    <div className="home-novelties-table__text-filters">
                                        <Form.Group className="home-novelties-table__form-group">
                                            <Form.Check aria-label="option 1" checked={isNoveltyTypeSearch} onChange={() => { }} onClick={() => handleCheck('novelty_search')} />
                                            <div>
                                                <Form.Label>Tipo de novedad</Form.Label>
                                                <Form.Select disabled={isAnySearch} value={noveltyTypeSearch} onChange={(e) => handleTableFilter(e.target.value, 'novelty_search')}>
                                                    <option value="">Seleccionar</option>
                                                    {props.novelty_types?.map(novelty_type => (
                                                        <option key={novelty_type.id} >{novelty_type.caption}</option>
                                                    ))}
                                                </Form.Select>
                                            </div>
                                        </Form.Group>

                                        <Form.Group className="home-novelties-table__form-group">
                                            <Form.Check aria-label="option 2" checked={isAnySearch} onChange={() => { }} onClick={() => handleCheck('any_search')} />
                                            <div className="home-novelties-table__any-search">
                                                <Form.Label>Buscar por palabra</Form.Label>
                                                <Form.Control value={anySearch} disabled={isNoveltyTypeSearch} placeholder="Buscar..." type="text" onChange={(event) => handleTableFilter(event.target.value, 'any_search')} />
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="home-novelties-table__filters-dates">
                                        <Form.Group>
                                            <Form.Check onChange={e => handleRadioChecked(e.target.value, 'creation-date')} type='radio' checked={creationDate} id={`novelty-creation-date`} label={`buscar por fecha de creación`} />
                                            <Form.Check onChange={e => handleRadioChecked(e.target.value, 'report-date')} type='radio' checked={reportDate} id={`novelty-reported-date`} label={`buscar por fecha reportada`} />
                                        </Form.Group>
                                        <div className="home-novelties-table__filters-dates__input-dates">
                                            <Form.Group>
                                                <Form.Label>Desde</Form.Label>
                                                <Form.Control value={dateFrom || dateReportFrom} type="date" onChange={(event) => handleTableFilter(event.target.value, 'date_from')} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Hasta</Form.Label>
                                                <Form.Control value={dateTo || dateReportTo} type="date" onChange={(event) => handleTableFilter(event.target.value, 'date_to')} />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <Button
                                        size="sm"
                                        onClick={() => { handleClearFilters() }}
                                    >
                                        Limpiar consulta
                                    </Button>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Exportar novedades (Excel)</Accordion.Header>
                            <Accordion.Body>
                                <Form className="home-novelties-table__export-excel__container">
                                    <div>
                                        <Form.Group>
                                            <Form.Label>Desde</Form.Label>
                                            <Form.Control value={dateExcelFrom} type="date" onChange={(event) => handleExportExcel(event.target.value, 'date_from')} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Hasta</Form.Label>
                                            <Form.Control value={dateExcelTo} disabled={dateExcelFrom === ''} type="date" onChange={(event) => handleExportExcel(event.target.value, 'date_to')} />
                                        </Form.Group>
                                    </div>
                                    <div>
                                        <Form.Group className="">
                                            <Form.Label>Tipo de novedad</Form.Label>
                                            <Form.Select value={noveltyTypeExcelFilter} onChange={(e) => setNoveltyTypeExcelFilter(e.target.value)}>
                                                <option value="">Seleccionar</option>
                                                {props.novelty_types?.map(novelty_type => (
                                                    <option key={novelty_type.id}  >{novelty_type.caption}</option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Filtrar usuario</Form.Label>
                                            <CustomDropdownSelectComponent
                                                options={usersList}
                                                handleSearchInput={(value) => handleSearchUser(value)}
                                                name='search_by_user'
                                                id={'search_by_user'}
                                                placeholder=''
                                                //maxLength={3}
                                                required={false}
                                                disabled={false}
                                                //defaultValue={''}
                                                noveltyAction={''}
                                                handleClearSearchArray={() => {
                                                    setUserExcelFilter('');
                                                    handleClearUserList();
                                                }}
                                                returnClear={() => { setClearUser('') }}
                                                handleSelectOption={(option) => setUserExcelFilter(option.value)}
                                                clearAfterSubmit={clearUser}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="home-novelties-table__export-excel__export-btn">
                                        <Toast onClose={() => setShowToast(false)} show={showToast} bg={'danger'} delay={3000} autohide className="home-novelties-table__toast-msg">
                                            <Toast.Body >No se encontraron resultados</Toast.Body>
                                        </Toast>
                                        <Button disabled={handleDisabledBtn()} size="sm" variant="primary" type="button" onClick={() => { handleDownloadExcel() }}>
                                            {!loader ? 'Exportar' : <Spinner animation="border" variant="light" size="sm" />}
                                        </Button>
                                    </div>
                                </Form>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Table className="home-novelties-table__table" striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Tipo de novedad</th>
                                <th>Usuario</th>
                                <th>Fecha creación</th>
                                {/* <th>Compañía</th>
                                     <th>Ubicación</th>
                                    <th>Reporta</th>
                                    <th>Vía</th>
                                    <th>Sector</th> */}
                                <th>Acciones</th>
                                <th className="home-novelties-table__form-check" >
                                    <Form.Check
                                        type='checkbox'
                                        id={`chkbox-all`}
                                        label={isSelectAll ? 'Deseleccionar todos' : `Seleccionar todos`}
                                        onChange={(e) => handleSelectAllNovelties(e.target.checked)}
                                        checked={isSelectAll}
                                    />
                                    <Button disabled={noveltiesSelection.length > 0 ? false : true} size="sm" type="button" onClick={() => handleExportSelection()}>Exportar selección</Button>
                                    {home_user?.role === 'Admin' && <Button disabled={noveltiesSelection.length > 0 ? false : true} size="sm" type="button" onClick={() => handleDeleteSelection()}>Eliminar selección</Button>}
                                </th>
                            </tr>
                        </thead>
                        {noveltiesCopy?.results?.length > 0 &&
                            <tbody>
                                {noveltiesCopy?.results?.map((novelty, index) => (
                                    <tr key={novelty.id}>
                                        <td className={`${novelty.novelty_without_closing ? "home-novelties-table__warning" : ""}`}>{novelty.novelty_without_closing ? <MdPriorityHigh /> : <></>}</td>
                                        <td className={`${novelty.novelty_without_closing ? "home-novelties-table__warning" : ""}`}>{novelty.novelty_type}</td>
                                        <td className={`${novelty.novelty_without_closing ? "home-novelties-table__warning" : ""}`}>{novelty.user}</td>
                                        <td className={`${novelty.novelty_without_closing ? "home-novelties-table__warning" : ""}`}>{moment(novelty.reporting_time).format('MMMM DD YYYY, h:mm a')}</td>
                                        {/*<td>{novelty.company ? novelty.company : ''}</td>
                                             <td>{novelty.location ? novelty.location : ''}</td>
                                            <td>{novelty.who_reports ? novelty.who_reports : ''}</td>
                                            <td>{novelty.via ? novelty.via : ''}</td>
                                            <td>{novelty.sector ? novelty.sector : ''}
                                            </td> */}
                                        <td>
                                            <div className="home-novelties-table__action">
                                                {(home_user?.role === 'Admin' || home_user?.role === 'Supervisor') && <MdModeEdit onClick={() => { props.handleNoveltyActions(novelty.id, novelty.novelty_template.questions, 'edit_novelty', novelty.novelty_template.id) }} />}
                                                <MdSearch onClick={() => { props.handleNoveltyActions(novelty.id, novelty.novelty_template.questions, 'view_novelty', novelty.novelty_template.id) }} />
                                                {/* <MdDelete onClick={() => { console.log('delete_novelty') }}/> */}
                                            </div>
                                        </td>
                                        <td>
                                            <Form.Check
                                                id={`check_${index}`}
                                                checked={novelty.checked}
                                                aria-label={`check_${index}`}
                                                onChange={(e) => handleSelectNoveltiesSingles(e.target.checked, novelty.id)}
                                            />
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        }

                    </Table>
                    {noveltiesCopy?.results?.length === 0 &&
                        <div className="home-novelties-table__no-records">
                            <h4>No se encontraron registros</h4>
                        </div>
                    }
                    <div className="home-novelties-table__actions-container">
                        {noveltiesCopy?.previous && <Button variant="primary" onClick={() => { handleNextPrevious('previous', noveltiesCopy.previous) }}>Anterior</Button>}
                        {noveltiesCopy?.next && <Button variant="primary" onClick={() => { handleNextPrevious('next', noveltiesCopy.next) }}>Siguiente</Button>}
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default HomeNoveltiesTableComponent;