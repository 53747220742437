import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// Apis
import { 
  UserApi,
  GetUsersApi,
  GetUserRolesApi,
  PostCreateUserApi,
  PatchUpdateUserPasswordApi,
  GetNoveltyTypesApi,
  GetNoveltyFormByTypeApi,
  PostAnswersNoveltyApi,
  GetNovelitesApi,
  GetFiltersNovelitesApi,
  GetEditNoveltyApi,
  PatchEditNoveltyApi,
  DeleteNoveltyFileApi,
  PostAssetApi,
  GetAssetsApi,
  GetAssetApi,
  PatchAssetApi,
  SearchUsersApi,
  SearchAssetApi,
  PostTransferApi,
  GetTransfersApi,
  PatchTransferApi,
  getNoveltiesToExportApi,
  deleteNoveltyApi,
  deleteNoveltiesApi,
  getTransfersFormLocationsApi,
  postTransfersFormLocationsApi,
  GetQueryTransfersApi,
  patchTransferWareHouseManApi,
  patchUpdateUserRoleApi,
  patchChangeUserPasswordApi,
  GetMapCoordinatesApi,
} from '../apis/HomeApi'

export const getUser = createAsyncThunk (
  'home/getUser',
  async () => {
    const response = await UserApi();
    return response.data;
  }
);

export const getUsers = createAsyncThunk (
  'home/getUsers',
  async () => {
    const response = await GetUsersApi();
    return response.data;
  }
);

export const getUserRoles = createAsyncThunk (
  'home/getUserRoles',
  async () => {
    const response = await GetUserRolesApi();
    return response.data;
  }
);

export const postCreateUser = createAsyncThunk (
  'home/postCreateUser',
  async (form_data, { rejectWithValue }) => {
    try {
      const response = await PostCreateUserApi(form_data);
      return response.data;      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }    
  }
);

export const patchUpdateUserPassword = createAsyncThunk (
  'home/patchUpdateUserPassword',
  async (form_data) => {
    const response = await PatchUpdateUserPasswordApi(form_data);
    return response.data;
  }
);

export const getNoveltyTypes = createAsyncThunk (
  'home/getNoveltyTypes',
  async () => {
    const response = await GetNoveltyTypesApi();
    return response.data;
  }
);

export const getNoveltyFormByType = createAsyncThunk (
  'home/getNoveltyFormByType',
  async (external_id) => {
    if(typeof external_id === 'object') {
      const response = await GetNoveltyFormByTypeApi(external_id.external_id, external_id.location_filter);
      return response.data;
    } else {
      const response = await GetNoveltyFormByTypeApi(external_id);
      return response.data;
    }
  }
);

export const postAnswersNovelty = createAsyncThunk (
  'home/postAnswersNovelty',
  async (novelty_form, { rejectWithValue }) => {
    try {
      const response = await PostAnswersNoveltyApi(novelty_form);
      return response.data;      
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const postCreateUser = createAsyncThunk (
//   'home/postCreateUser',
//   async (form_data, { rejectWithValue }) => {
//     try {
//       const response = await PostCreateUserApi(form_data);
//       return response.data;      
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }    
//   }
// );

export const getNovelties = createAsyncThunk (
  'home/getNovelties',
  async (next_previous_page) => {
    const response = await GetNovelitesApi(next_previous_page);
    return response.data;
  }
);

export const getFiltersNovelites = createAsyncThunk (
  'home/getFiltersNovelties',
  async (query_params) => {
    const response =  await GetFiltersNovelitesApi(query_params);
    return response.data;
  }
)

export const getEditNovelty = createAsyncThunk (
  'home/getEditNovelty',
  async (novelty_id) => {
    // console.log('novelty_id ', novelty_id)
    if(typeof novelty_id === 'object') {
      const response = await GetEditNoveltyApi(novelty_id.novelty_id, novelty_id.location_filter);
      return response.data;
    } else {
      const response = await GetEditNoveltyApi(novelty_id);
      return response.data;
    }
  }
);

export const patchEditNovelty = createAsyncThunk (
  'home/patchEditNovelty',
  async (novelty_form) => {
    const response = await PatchEditNoveltyApi(novelty_form);
    return response.data;
  }
);

export const deleteNoveltyFile = createAsyncThunk (
  'home/deleteNoveltyFile',
  async (novelty_file_id) => {
    const response = await DeleteNoveltyFileApi(novelty_file_id);
    return response.data;
  }
);

export const postAsset = createAsyncThunk (
  'home/postAsset',
  async (asset) => {
    const response = await PostAssetApi(asset);
    return response.data;
  }
);

export const getAssets = createAsyncThunk (
  'home/getAssets',
  async (next_previous_page) => {
    const response = await GetAssetsApi(next_previous_page);
    return response.data;
  }
);

export const getAsset = createAsyncThunk (
  'home/getAsset',
  async (asset_id) => {
    const response = await GetAssetApi(asset_id);
    return response.data;
  }
);

export const patchAsset = createAsyncThunk (
  'home/patchAsset',
  async (asset) => {
    const response = await PatchAssetApi(asset);
    return response.data;
  }
);

export const searchUsers = createAsyncThunk (
  'home/searchUsers',
  async (word) => {
    const response = await SearchUsersApi(word);
    return response.data;
  }
);

export const searchAssets = createAsyncThunk (
  'home/searchAssets',
  async (word) => {
    const response = await SearchAssetApi(word);
    return response.data;
  }
);

export const postTransfer = createAsyncThunk (
  'home/postTransfer',
  async (transfer) => {
    const response = await PostTransferApi(transfer);
    return response.data;
  }
);

export const getTransfers = createAsyncThunk (
  'home/getTransfer',
  async (next_previous_page) => {
    if(typeof next_previous_page === 'object' && next_previous_page.query_params) {
        const response = await GetQueryTransfersApi(next_previous_page.query_params);
        return response.data;
    } else {
      const response = await GetTransfersApi(next_previous_page);
      return response.data;
    }
  }
);

export const patchTransfer = createAsyncThunk (
  'home/patchTransfer',
  async (transfer) => {
    const response = await PatchTransferApi(transfer);
    return response.data;
  }
);

export const getNoveltiesToExport = createAsyncThunk (
  'home/getNoveltiesToExport',
  async (export_query) => {
    const response = await getNoveltiesToExportApi(export_query);
    return response.data;
  }
);

export const deleteNovelty = createAsyncThunk (
  'home/deleteNovelty',
  async (novelty_id) => {
    const response = await deleteNoveltyApi(novelty_id);
    return response.data;
  }
);

export const deleteNovelties = createAsyncThunk (
  'home/deleteNovelties',
  async (novelties_array) => {
    const response = await deleteNoveltiesApi(novelties_array);
    return response.data;
  }
);

export const getTransfersFormLocations = createAsyncThunk (
  'home/getTransfersFormLocations',
  async () => {
    const response = await getTransfersFormLocationsApi();
    return response.data;
  }
);

export const postTransfersFormLocations = createAsyncThunk (
  'home/postTransfersFormLocations',
  async (location) => {
    const response = await postTransfersFormLocationsApi(location);
    return response.data;
  }
);

export const patchTransferWareHouseMan = createAsyncThunk (
  'home/patchTransferWareHouseMan',
  async (form_data) => {
    const response = await patchTransferWareHouseManApi(form_data);
    return response.data;
  }
);

export const patchUpdateUserRole = createAsyncThunk (
  'home/patchUpdateUserRole',
  async (form_data) => {
    const response = await patchUpdateUserRoleApi(form_data);
    return response.data;
  }
);

export const patchChangeUserPassword = createAsyncThunk (
  'home/patchChangeUserPassword',
  async (form_data) => {
    const response = await patchChangeUserPasswordApi(form_data);
    return response.data;
  }
);

export const getMapCoordinates = createAsyncThunk (
  'home/getAsset',
  async (data) => {
    const response = await GetMapCoordinatesApi(data);
    return response.data;
  }
);

export const HomeSlice = createSlice({
  name: 'home',
  initialState: {
    user: undefined,
    users_list: undefined,
    status: {
      user: {
        get_user_status: '',
        update_role_status: '',
        change_password_status: '',
        error: '',
      },
      users: {
        get_users_status: '',
        search_users_status: '',
        error: '',
      },
      roles_list: {
        get_roles_list_status: '',
        error: '',
      },
      create_user: {
        create_user_status: '',
        error: '',
      },
      update_password: {
        update_password_status: '',
        error: '',
      },
      novelty_types: {
        get_novelty_types_status: '',
        error: '',
      },
      novelty_form: {
        get_novelty_form_status: '',
        get_edit_novelty_status: '',
        post_novelty_form_status: '',
        patch_novelty_form_status: '',
        delete_novelty_file_status: '',
        error: '',
      },
      novelties: {
        get_novelties_status: '',
        error: '',
      },
      assets: {
        post_asset_status: '',
        get_assets_status: '',
        get_asset_status: '',
        patch_asset_status: '',
        search_assets_status: '',
      },
      transfers: {
        post_transfer_status: '',
        get_transfers_status: '',
        patch_transfer_status: '',
        get_transfers_locations_status: '',
        post_transfers_locations_status: '',
        patch_transfer_approval_status: '',
      },
      export_novelties: {
        get_novelties_export_status: '',
        error: '',
      },
      delete_novelty: {
        delete_novelty_status: '',
        error: '',
      },
      delete_novelties: {
        delete_novelties_status: '',
        error: '',
      },
      geomap_coordinates: {
        geomap_coordinates_status: '',
        error: '',
      }
    },
    user_roles: undefined,
    modals: {
      is_create_user: false,
      is_update_user_password: false,
      is_edit_novelty: false,
      is_carousel: false,
      is_asset: false,
      is_transfer: false,
      is_view_transfer: false,
      is_create_location: false,
      is_role_action: false,
    },
    form_question_types: undefined,
    is_toast_message: false,
    toast_message: '',
    toast_message_type: '',
    form_types: undefined,
    loader: false,
    owner_forms: undefined,
    novelty_types: undefined,
    novelty_form: undefined,
    novelties: undefined,
    edit_novelty_form: undefined,
    is_novelty_form: false,
    assets: undefined,
    asset: undefined,
    asset_action_type: '',
    search_users_result: undefined,
    search_assets_result: undefined,    
    transfers: undefined,
    novelties_to_export: undefined,
    transfers_form_locations: undefined,
    geomap_coordinates: {},
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setListOfUsers: (state, action) => {
      state.users_list = action.payload;
    },
    setIsCreateUser: (state, action) => {
      state.modals.is_create_user = action.payload;
    },
    setIsUpdateUserPassword: (state, action) => {
      state.modals.is_update_user_password = action.payload;
    },
    setOnCloseToastMessage: (state) => {      
      state.is_toast_message = false;
      state.toast_message = '';
      state.toast_message_type = '';
    },
    setIsEditNovelty: (state, action) => {
      state.modals.is_edit_novelty = action.payload;
    },
    setNoveltyForm: (state, action) => {
      state.novelty_form = action.payload;
    },
    setIsNoveltyForm: (state, action) => {
      state.is_novelty_form = action.payload;
    },
    setEditNoveltyForm: (state, action) => {
      state.edit_novelty_form = action.payload;
    },
    setIsCarouselModal: (state, action) => {
      state.modals.is_carousel = action.payload;
    },
    setEditNoveltyStatus: (state, action) => {
      state.status.novelty_form.delete_novelty_file_status = action.payload;
    },
    setIsAssetModal: (state, action) => {
      state.modals.is_asset = action.payload;
    },
    setAsset: (state, action) => {
      state.asset = action.payload;
    },
    setAssetActionType: (state, action) => {
      state.asset_action_type = action.payload;
    },
    setIsTranfer: (state, action) => {
      state.modals.is_transfer = action.payload;
    },
    setIsViewTransfer: (state, action) => {
      state.modals.is_view_transfer = action.payload;
    },
    setClearPostTransferStatus: (state, action) => {
      state.status.transfers.get_transfers_status = '';
      state.status.transfers.post_transfer_status = '';
      state.status.transfers.patch_transfer_status = '';
    },
    setNoveltiesToExport: (state) => {
      state.novelties_to_export = undefined;
    },
    setIsCreateLocation: (state, action) => {
      state.modals.is_create_location = action.payload;
    },
    setIsRoleAction: (state, action) => {
      state.modals.is_role_action = action.payload;
    },
    setClearGeomapCoordinates: (state, action) => {
      state.geomap_coordinates = {};
    }
  },
  extraReducers: {
    // get user me status
    [getUser.pending]: (state) => {
      state.status.user.get_user_status = 'loading';
    },
    [getUser.fulfilled]: (state, action) => {
      state.status.user.get_user_status = 'succeeded';
      state.user = action.payload;
    },
    [getUser.rejected]: (state, action) => {
      state.status.user.get_user_status = 'failed';
      state.status.user.error = action.error.message;
    },

    //get list of users status
    [getUsers.pending]: (state) => {
      state.status.users.get_users_status = 'loading';
    },
    [getUsers.fulfilled]: (state, action) => {
      state.status.users.get_users_status = 'succeeded';
      state.users_list = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.status.users.get_users_status = 'failed';
      state.status.users.error = action.error.message;
    },

    // get list of user roles status
    [getUserRoles.pending]: (state) => {
      state.status.roles_list.get_roles_list_status = 'loading';
    },
    [getUserRoles.fulfilled]: (state, action) => {
      state.status.roles_list.get_roles_list_status = 'succeeded';
      state.user_roles = action.payload;
    },
    [getUserRoles.rejected]: (state, action) => {
      state.status.roles_list.get_roles_list_status = 'failed';
      state.status.roles_list.error = action.error.message;
    },

    // post create user status
    [postCreateUser.pending]: (state) => {
      state.status.create_user.create_user_status = 'loading';
      state.loader = true;
    },
    [postCreateUser.fulfilled]: (state, action) => {
      state.status.create_user.create_user_status = 'succeeded';
      state.is_toast_message = true;
      state.toast_message = 'Usuario creado!';
      state.toast_message_type = 'primary';
      state.loader = false;
      state.modals.is_create_user = false;
    },
    [postCreateUser.rejected]: (state, action) => {
      state.status.create_user.create_user_status = 'failed';
      state.status.create_user.error = action.error.message;
      state.loader = false;
      if(action.payload.username) {
        state.is_toast_message = true;
        state.toast_message = `El usuario ${action.meta.arg.username} ya existe!`;
        state.toast_message_type = 'danger';
      } else {
        state.is_toast_message = true;
        state.toast_message = `Tenemos un problema!`;
        state.toast_message_type = 'danger';
      }
    },

    // patch update user password status
    [patchUpdateUserPassword.pending]: (state) => {
      state.status.update_password.update_password_status = 'loading';
      state.loader = true;
    },
    [patchUpdateUserPassword.fulfilled]: (state, action) => {
      state.status.update_password.update_password_status = 'succeeded';
      state.loader = false;
      state.modals.is_update_user_password = false;
      state.is_toast_message = true;
      state.toast_message = 'Contraseña actualizada!';
      state.toast_message_type = 'primary';
    },
    [patchUpdateUserPassword.rejected]: (state, action) => {
      state.status.update_password.update_password_status = 'failed';
      state.loader = false;
      state.status.update_password.error = action.error.message;
      state.is_toast_message = true;
      state.toast_message = 'Tenemos un problema!';
      state.toast_message_type = 'danger';
    },
    
    // get novelty types status
    [getNoveltyTypes.pending]: (state) => {
      state.status.novelty_types.get_novelty_types_status = 'loading';
    },
    [getNoveltyTypes.fulfilled]: (state, action) => {
      state.status.novelty_types.get_novelty_types_status = 'succeeded';
      state.novelty_types = action.payload;
    },
    [getNoveltyTypes.rejected]: (state, action) => {
      state.status.novelty_types.get_novelty_types_status = 'failed';
    },

    // get novelty form by type status
    [getNoveltyFormByType.pending]: (state) => {
      state.status.novelty_form.get_novelty_form_status = 'loading';
    },
    [getNoveltyFormByType.fulfilled]: (state, action) => {
      state.status.novelty_form.get_novelty_form_status = 'succeeded';
      state.novelty_form = action.payload;
      state.is_novelty_form = true;
    },
    [getNoveltyFormByType.rejected]: (state, action) => {
      state.status.novelty_form.get_novelty_form_status = 'failed';
    },

    // post novelty form status
    [postAnswersNovelty.pending]: (state) => {
      state.status.novelty_form.post_novelty_form_status = 'loading';
    },
    [postAnswersNovelty.fulfilled]: (state, action) => {
      state.status.novelty_form.post_novelty_form_status = 'succeeded';
      state.is_novelty_form = false;
      state.novelty_form = undefined;
      state.is_toast_message = true;
      state.toast_message = 'Novedad creada!';
      state.toast_message_type = 'primary';
    },
    [postAnswersNovelty.rejected]: (state, action) => {
      // TODO set error message when files exceeds the allowed size (backend) 
      state.status.novelty_form.post_novelty_form_status = 'failed';
      state.is_toast_message = true;
      state.toast_message = 'La carga de archivos supera 5 megas!';
      state.toast_message_type = 'danger';
    },

    // get novelties status
    [getNovelties.pending]: (state) => {
      state.status.novelties.get_novelties_status = 'loading';
    },
    [getNovelties.fulfilled]: (state, action) => {
      state.status.novelties.get_novelties_status = 'succeeded';
      state.novelties = action.payload;      
    },
    [getNovelties.rejected]: (state, action) => {
      state.status.novelties.get_novelties_status = 'failed';
    },

    // get novelties status
    [getFiltersNovelites.pending]: (state) => {
      state.status.novelties.get_novelties_status = 'loading';
    },
    [getFiltersNovelites.fulfilled]: (state, action) => {
      state.status.novelties.get_novelties_status = 'succeeded';
      state.novelties = action.payload;      
    },
    [getFiltersNovelites.rejected]: (state, action) => {
      state.status.novelties.get_novelties_status = 'failed';
    },
    
    // get novelties status
    [getEditNovelty.pending]: (state) => {
      state.status.novelty_form.get_edit_novelty_status = 'loading';
    },
    [getEditNovelty.fulfilled]: (state, action) => {
      state.status.novelty_form.get_edit_novelty_status = 'succeeded';
      state.edit_novelty_form = action.payload;      
    },
    [getEditNovelty.rejected]: (state, action) => {
      state.status.novelty_form.get_edit_novelty_status = 'failed';
    },    

    // edit novelty status
    [patchEditNovelty.pending]: (state) => {
      state.status.novelty_form.patch_novelty_form_status = 'loading';
    },
    [patchEditNovelty.fulfilled]: (state, action) => {
      state.status.novelty_form.patch_novelty_form_status = 'succeeded';
      state.is_toast_message = true;
      state.toast_message = 'Novedad editada!';
      state.toast_message_type = 'primary';
      state.edit_novelty_form = undefined;
      state.modals.is_edit_novelty = false;
    },
    [patchEditNovelty.rejected]: (state, action) => {
      state.status.novelty_form.patch_novelty_form_status = 'failed';
    },
    
    // delete novelty file status
    [deleteNoveltyFile.pending]: (state) => {
      state.status.novelty_form.delete_novelty_file_status = 'loading';
    },
    [deleteNoveltyFile.fulfilled]: (state, action) => {
      state.status.novelty_form.delete_novelty_file_status = 'succeeded';
    },
    [deleteNoveltyFile.rejected]: (state, action) => {
      state.status.novelty_form.delete_novelty_file_status = 'failed';
    },

    // create asset
    [postAsset.pending]: (state) => {
      state.status.assets.post_asset_status = 'loading';
    },
    [postAsset.fulfilled]: (state, action) => {
      state.status.assets.post_asset_status = 'succeeded';
      state.is_toast_message = true;
      state.toast_message = 'Activo creado!';
      state.toast_message_type = 'primary';
      state.modals.is_asset = false;
    },
    [postAsset.rejected]: (state, action) => {
      state.status.assets.post_asset_status = 'failed';
    },

    // get assets
    [getAssets.pending]: (state) => {
      state.status.assets.get_assets_status = 'loading';
    },
    [getAssets.fulfilled]: (state, action) => {
      state.status.assets.get_assets_status = 'succeeded';
      state.assets = action.payload;
    },
    [getAssets.rejected]: (state, action) => {
      state.status.assets.get_assets_status = 'failed';
    },

    
    // get asset by id
    [getAsset.pending]: (state) => {
      state.status.assets.get_asset_status = 'loading';
    },
    [getAsset.fulfilled]: (state, action) => {
      state.status.assets.get_asset_status = 'succeeded';
      state.asset = action.payload;
    },
    [getAsset.rejected]: (state, action) => {
      state.status.assets.get_asset_status = 'failed';
    },

    // get asset by id
    [patchAsset.pending]: (state) => {
      state.status.assets.patch_asset_status = 'loading';
    },
    [patchAsset.fulfilled]: (state, action) => {
      state.status.assets.patch_asset_status = 'succeeded';
      state.asset = action.payload;
      state.is_toast_message = true;
      state.toast_message = 'Activo editado!';
      state.toast_message_type = 'primary';
      state.modals.is_asset = false;
    },
    [patchAsset.rejected]: (state, action) => {
      state.status.assets.patch_asset_status = 'failed';
    },

    // search user
    [searchUsers.pending]: (state) => {
      state.status.users.search_users_status = 'loading';
    },
    [searchUsers.fulfilled]: (state, action) => {
      state.status.users.search_users_status = 'succeeded';
      state.search_users_result = action.payload;
    },
    [searchUsers.rejected]: (state, action) => {
      state.status.users.search_users_status = 'failed';
    },

    // search assets
    [searchAssets.pending]: (state) => {
      state.status.assets.search_assets_status = 'loading';
    },
    [searchAssets.fulfilled]: (state, action) => {
      state.status.assets.search_assets_status = 'succeeded';
      state.search_assets_result = action.payload;
    },
    [searchAssets.rejected]: (state, action) => {
      state.status.assets.search_assets_status = 'failed';
    },

    // post transfer
    [postTransfer.pending]: (state) => {
      state.status.transfers.post_transfer_status = 'loading';
    },
    [postTransfer.fulfilled]: (state, action) => {
      state.status.transfers.post_transfer_status = 'succeeded';
      state.is_toast_message = true;
      state.toast_message = 'Transferencia creada!';
      state.toast_message_type = 'primary';
    },
    [postTransfer.rejected]: (state, action) => {
      state.status.transfers.post_transfer_status = 'failed';
    },

    // get transfers
    [getTransfers.pending]: (state) => {
      state.status.transfers.get_transfers_status = 'loading';
    },
    [getTransfers.fulfilled]: (state, action) => {
      state.status.transfers.get_transfers_status = 'succeeded';
      state.transfers = action.payload;
    },
    [getTransfers.rejected]: (state, action) => {
      state.status.transfers.get_transfers_status = 'failed';
    },

    // patch transfers
    [patchTransfer.pending]: (state) => {
      state.status.transfers.patch_transfer_status = 'loading';
    },
    [patchTransfer.fulfilled]: (state, action) => {
      state.status.transfers.patch_transfer_status = 'succeeded';
      state.modals.is_transfer = false;
    },
    [patchTransfer.rejected]: (state, action) => {
      state.status.transfers.patch_transfer_status = 'failed';
    },

    // get novelties to export
    [getNoveltiesToExport.pending]: (state) => {
      state.status.export_novelties.get_novelties_export_status = 'loading';
    },
    [getNoveltiesToExport.fulfilled]: (state, action) => {
      state.status.export_novelties.get_novelties_export_status = 'succeeded';
      state.novelties_to_export = action.payload;
    },
    [getNoveltiesToExport.rejected]: (state, action) => {
      state.status.export_novelties.get_novelties_export_status = 'failed';
    },

    // delete novelty
    [deleteNovelty.pending]: (state) => {
      state.status.delete_novelty.delete_novelty_status = 'loading';
    },
    [deleteNovelty.fulfilled]: (state, action) => {
      state.status.delete_novelty.delete_novelty_status = 'succeeded';
    },
    [deleteNovelty.rejected]: (state, action) => {
      state.status.delete_novelty.delete_novelty_status = 'failed';
    },

    // delete novelties
    [deleteNovelties.pending]: (state) => {
      state.status.delete_novelties.delete_novelties_status = 'loading';
    },
    [deleteNovelties.fulfilled]: (state) => {
      state.status.delete_novelties.delete_novelties_status = 'succeeded';
    },
    [deleteNovelties.rejected]: (state) => {
      state.status.delete_novelties.delete_novelties_status = 'failed';
    },

    // get transfers form locations
    [getTransfersFormLocations.pending]: (state) => {
      state.status.transfers.get_transfers_locations_status = 'loading';
    },
    [getTransfersFormLocations.fulfilled]: (state, action) => {
      state.status.transfers.get_transfers_locations_status = 'succeeded';
      state.transfers_form_locations = action.payload;
    },
    [getTransfersFormLocations.rejected]: (state) => {
      state.status.transfers.get_transfers_locations_status = 'failed';
    },

    // post transfers form locations
    [postTransfersFormLocations.pending]: (state) => {
      state.status.transfers.post_transfers_locations_status = 'loading';
    },
    [postTransfersFormLocations.fulfilled]: (state, action) => {
      state.status.transfers.post_transfers_locations_status = 'succeeded';
    },
    [postTransfersFormLocations.rejected]: (state) => {
      state.status.transfers.post_transfers_locations_status = 'failed';
    },

    // patch transfer wareHouseMan approval
    [patchTransferWareHouseMan.pending]: (state) => {
      state.status.transfers.patch_transfer_approval_status = 'loading';
    },
    [patchTransferWareHouseMan.fulfilled]: (state, action) => {
      state.status.transfers.patch_transfer_approval_status = 'succeeded';
    },
    [patchTransferWareHouseMan.rejected]: (state) => {
      state.status.transfers.patch_transfer_approval_status = 'failed';
    },

    // patch update user role
    [patchUpdateUserRole.pending]: (state) => {
      state.status.user.update_role_status = 'loading';
    },
    [patchUpdateUserRole.fulfilled]: (state, action) => {
      state.status.user.update_role_status = 'succeeded';
      state.is_toast_message = true;
      state.toast_message = 'Rol actualizado!';
      state.toast_message_type = 'primary';
    },
    [patchUpdateUserRole.rejected]: (state) => {
      state.status.user.update_role_status = 'failed';
    },

    // patchChangeUserPassword
    [patchChangeUserPassword.pending]: (state) => {
      state.status.user.change_password_status = 'loading';
    },
    [patchChangeUserPassword.fulfilled]: (state, action) => {
      state.status.user.change_password_status = 'succeeded';
      state.is_toast_message = true;
      state.toast_message = 'Contraseña actualizada!';
      state.toast_message_type = 'primary';
    },
    [patchChangeUserPassword.rejected]: (state) => {
      state.status.user.change_password_status = 'failed';
    },

    // getMapCoordinates
    [getMapCoordinates.pending]: (state) => {
      state.status.geomap_coordinates.geomap_coordinates_status = 'loading';
    },
    [getMapCoordinates.fulfilled]: (state, action) => {
      state.status.geomap_coordinates.geomap_coordinates_status = 'succeeded';
      state.geomap_coordinates = action.payload;
    },
    [getMapCoordinates.rejected]: (state) => {
      state.status.geomap_coordinates.geomap_coordinates_status = 'failed';
    },
  }
});

// Action creators are generated for each case reducer function
export const { 
  setUser, 
  setListOfUsers,
  setIsCreateUser,
  setIsUpdateUserPassword,
  setOnCloseToastMessage,
  setIsEditNovelty,
  setNoveltyForm,
  setIsNoveltyForm,
  setEditNoveltyForm,
  setIsCarouselModal,
  setEditNoveltyStatus,
  setIsAssetModal,
  setAsset,
  setAssetActionType,
  setIsTranfer,
  setIsViewTransfer,
  setClearPostTransferStatus,
  setNoveltiesToExport,
  setIsCreateLocation,
  setIsRoleAction,
  setClearGeomapCoordinates,
 } = HomeSlice.actions

export default HomeSlice.reducer;
