// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-carousel-modal .carousel-item img {
    width: 50%;
}

.home-carousel-modal .carousel-control-next, .home-carousel-modal .carousel-control-prev {
    top: -65px;
    width: 4%;
    background: #6c757d;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 50%;
}

.home-carousel-modal .carousel-control-prev {
    right: 50px;
    left: unset;
}

.home-carousel-modal .carousel-control-next-icon, .home-carousel-modal .carousel-control-prev-icon {    
    width: 1rem;
    height: 2rem;    
}

.home-carousel-modal.carousel {
    min-height: 700px;
    display: flex;
    align-items: center;
}

.home-carousel-modal h4 {
    text-align: center;
}

.home-carousel-modal__actions {
    padding: 12px 0;
    gap: 12px;
    display: flex;
    justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-carousel-modal/HomeCarouselModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,SAAS;IACT,mBAAmB;IACnB,wBAAmB;IAAnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".home-carousel-modal .carousel-item img {\n    width: 50%;\n}\n\n.home-carousel-modal .carousel-control-next, .home-carousel-modal .carousel-control-prev {\n    top: -65px;\n    width: 4%;\n    background: #6c757d;\n    height: fit-content;\n    border-radius: 50%;\n}\n\n.home-carousel-modal .carousel-control-prev {\n    right: 50px;\n    left: unset;\n}\n\n.home-carousel-modal .carousel-control-next-icon, .home-carousel-modal .carousel-control-prev-icon {    \n    width: 1rem;\n    height: 2rem;    \n}\n\n.home-carousel-modal.carousel {\n    min-height: 700px;\n    display: flex;\n    align-items: center;\n}\n\n.home-carousel-modal h4 {\n    text-align: center;\n}\n\n.home-carousel-modal__actions {\n    padding: 12px 0;\n    gap: 12px;\n    display: flex;\n    justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
