// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#map { 
    position: absolute;
    top: 350px;
    bottom: 0;
    width: 100%;
}

.home-geomap__container {
    position: relative;
    width: 100%;
    height: 100%;
}

.home-geomap__filters {
    padding: 1rem; 
}

.home-geomap__form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 30%;
}

.home-geomap__form .btn {
    height: -moz-fit-content;
    height: fit-content;
}

.home-geomap__form-statics__container {
    display: flex;
    gap: 1rem;
}

.home-geomap__form-statics {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 5rem;
}

.home-geomap__form-statics__row {
    display: flex;
    align-items: center;
    gap: 12px;
}

.home-geomap__form-statics__row h5 {
    margin-bottom: 0;
}

.home-geomap__map__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15%;
    color: #19215c;
}

.home-geomap__map__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15%;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-geomap/HomeGeomapComponent.styles.css"],"names":[],"mappings":";;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,wBAAmB;IAAnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,UAAU;IACV,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":["\n\n#map { \n    position: absolute;\n    top: 350px;\n    bottom: 0;\n    width: 100%;\n}\n\n.home-geomap__container {\n    position: relative;\n    width: 100%;\n    height: 100%;\n}\n\n.home-geomap__filters {\n    padding: 1rem; \n}\n\n.home-geomap__form {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    width: 30%;\n}\n\n.home-geomap__form .btn {\n    height: fit-content;\n}\n\n.home-geomap__form-statics__container {\n    display: flex;\n    gap: 1rem;\n}\n\n.home-geomap__form-statics {\n    width: 70%;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: space-between;\n    padding-left: 5rem;\n}\n\n.home-geomap__form-statics__row {\n    display: flex;\n    align-items: center;\n    gap: 12px;\n}\n\n.home-geomap__form-statics__row h5 {\n    margin-bottom: 0;\n}\n\n.home-geomap__map__no-data {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-top: 15%;\n    color: #19215c;\n}\n\n.home-geomap__map__loader {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding-top: 15%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
