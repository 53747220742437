// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home__container {
  position: relative;
  top: 80px;
  height: 100%;
}

.fade.toast.home__toast.show {
  bottom: 10px;
  left: 10px;
  position: fixed;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1056;  
}

.home__create-novelty {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  margin: 32px;
}

.row {
  --bs-gutter-x: 0 !important;
}

.home__home-container {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom;  
}


@media (max-width: 991px) { 
  .home__container {
    height: unset;
  }

  .home__create-novelty {
    margin: 12px;
  }  
}

`, "",{"version":3,"sources":["webpack://./src/app/home/views/HomeView.styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,UAAU;EACV,eAAe;EACf,uBAAkB;EAAlB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yDAAsD;EACtD,4BAA4B;EAC5B,qBAAqB;EACrB,2BAA2B;AAC7B;;;AAGA;EACE;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".home__container {\n  position: relative;\n  top: 80px;\n  height: 100%;\n}\n\n.fade.toast.home__toast.show {\n  bottom: 10px;\n  left: 10px;\n  position: fixed;\n  width: fit-content;\n  z-index: 1056;  \n}\n\n.home__create-novelty {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  gap: 32px;\n  justify-content: center;\n  margin: 32px;\n}\n\n.row {\n  --bs-gutter-x: 0 !important;\n}\n\n.home__home-container {\n  background-image: url('../../../assets/images/bg.png');\n  background-repeat: no-repeat;\n  background-size: auto;\n  background-position: bottom;  \n}\n\n\n@media (max-width: 991px) { \n  .home__container {\n    height: unset;\n  }\n\n  .home__create-novelty {\n    margin: 12px;\n  }  \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
