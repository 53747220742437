// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-assets-list-table__container {
    margin: 32px;
    box-shadow: 0px 4px 10px 0px rgba(25, 33, 92, 0.10);
}

.home-assets-list-table__action {
    display: flex;
    justify-content: center;
    gap: 24px;
}

.home-assets-list-table__action svg {
    font-size: 1.2rem;
    cursor: pointer;
    color: #6c757d;
}

.home-assets-list-table__action svg:hover {
    color: #568fed;
}

.home-novelties-table__actions-container {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    padding: 12px 0;
}

.home-assets-list__header-card {
    display: flex;
    align-items: center;
    justify-content: space-between;    
}

.home-assets-list__header-card .input-group {
    width: 50%;    
}

#asset-filter svg {
    font-size: 1.3rem;
    cursor: pointer;
}

.home-assets-list__no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #6c757d;
    margin-top: 32px;
}

@media (max-width: 991px) { 
    .home-assets-list-table__container {
        margin: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-assets-list/HomeAssetsListComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mDAAmD;AACvD;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".home-assets-list-table__container {\n    margin: 32px;\n    box-shadow: 0px 4px 10px 0px rgba(25, 33, 92, 0.10);\n}\n\n.home-assets-list-table__action {\n    display: flex;\n    justify-content: center;\n    gap: 24px;\n}\n\n.home-assets-list-table__action svg {\n    font-size: 1.2rem;\n    cursor: pointer;\n    color: #6c757d;\n}\n\n.home-assets-list-table__action svg:hover {\n    color: #568fed;\n}\n\n.home-novelties-table__actions-container {\n    display: flex;\n    justify-content: flex-end;\n    gap: 12px;\n    padding: 12px 0;\n}\n\n.home-assets-list__header-card {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;    \n}\n\n.home-assets-list__header-card .input-group {\n    width: 50%;    \n}\n\n#asset-filter svg {\n    font-size: 1.3rem;\n    cursor: pointer;\n}\n\n.home-assets-list__no-results {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    color: #6c757d;\n    margin-top: 32px;\n}\n\n@media (max-width: 991px) { \n    .home-assets-list-table__container {\n        margin: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
