// React
import React from 'react';

// Boostrap
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

// React Icons
import {MdDeleteForever} from 'react-icons/md'

// Styles
import './CustomDropdownSelectComponent.styles.css'

const CustomDropdownSelectComponent = (props) => {
    // Component states
    const [value, setValue] = React.useState(props.defaultValue ? props.defaultValue : '');
    const [showOptions, setShowOptions] = React.useState(false);
    const [loader, setLoader] = React.useState(false);

    React.useEffect(() => {
        if(props.options.length === 0) {
            setLoader(true)
        } else {
            setLoader(false)
        }
    }, [props.options]);

    React.useEffect(() => {
        if(props.clearAfterSubmit) {
            setValue('');
            if(props.returnClear) {
                props.returnClear();
            }            
        }
    }, [props.clearAfterSubmit]);

    const handleSearchInput = (input_value) => {
        setValue(undefined);
        if(props.maxLength && input_value.length > 0 && input_value.length <= 3){
            setShowOptions(true);
            props.handleSearchInput(input_value)
        } else {
            setShowOptions(true);
            props.handleSearchInput(input_value)
        }      
    }

    const handleSelectOption = (option) => {
        if(props.handleSelectOption) {
            props.handleSelectOption(option);
        }
        if(props.clearValue) {
            setValue('');
        } else {
            setValue(option.value);
        }
        setShowOptions(false);
    }

  return (
    <div className='custom-dropdown-select'>
        <Form.Group className="mb-1 custom-dropdown-select__container">
            <Form.Control 
                type="text"
                placeholder={props.placeholder ? props.placeholder : "Buscar..."}
                name={props.name}
                id={props.id}
                value={value}
                onChange={(e) => { handleSearchInput(e.target.value) }}
                maxLength={props.maxLength ? props.maxLength : undefined}
                required={props.required} 
                disabled={props.disabled}
                //defaultValue={props.defaultValue ? props.defaultValue : value}
            />
            { props.noveltyAction !== 'view_novelty' && 
                <div 
                    className='custom-dropdown-select__clear'
                    onClick={() => {
                        props.handleClearSearchArray();
                        setValue('');
                        setShowOptions(false);
                    }}
                >
                    <MdDeleteForever  />
                </div>
            }

        </Form.Group>

        <Dropdown.Menu show={showOptions}>
            { props?.options.length > 0 && props?.options.map( (option, index) => (
                    <Dropdown.Item key={index} onClick={() => { handleSelectOption(option) }}  eventKey={index+1}>{option.value}</Dropdown.Item>
                ))
            }
            { loader && props.options.length >= 0 &&
                <Dropdown.Item eventKey="1" className='custom-dropdown-select__msg'>Cargando...</Dropdown.Item>
            }
        </Dropdown.Menu>
    </div>
  )
}

export default CustomDropdownSelectComponent

