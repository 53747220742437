// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-assets-modal__btns-action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/home/components/home-assets-modal/HomeAssetsModalComponent.styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,SAAS;AACb","sourcesContent":[".home-assets-modal__btns-action {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
