// React
import React from "react";

// React redux
import { useSelector, useDispatch } from 'react-redux';
import { getAssets, searchAssets } from '../../../home/slice/HomeSlice';

// Bootstrap
import Table from 'react-bootstrap/Table';
import { Button } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

// Icons
import { MdModeEdit, MdSearch, /* MdDelete, */ MdClear } from 'react-icons/md';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

// Styles
import './HomeAssetsListComponent.styles.css'


function HomeAssetsListComponent(props) {

   	// React home redux states
    const dispatch = useDispatch();
	const assets = useSelector(state => state.home.assets);
    const search_assets_result = useSelector(state => state.home.search_assets_result);

    // Componet states
    const [assetsArray, setAssetsArray] = React.useState([]);
    const [word, setWord] = React.useState('');

    React.useEffect(() => {
        if(assets) {
            setAssetsArray(assets);
        }   
    }, [assets]);

    React.useEffect(() => {
        if(search_assets_result) {
            setAssetsArray(search_assets_result);
        }   
    }, [search_assets_result]);

    const handleSearchAssets = (value) => {
        setWord(value);
        dispatch(searchAssets(value));
    }

    const handleClearSearch = () => {
        setWord('')
        dispatch(getAssets());
    }



    return (
        <div className="home-assets-list-table__container">            
            <Card>
                <Card.Header className="home-assets-list__header-card">
                    <h5>Lista de Activos</h5>
                    <InputGroup>
                        <Form.Control
                            placeholder="Buscar activos"
                            aria-label="Buscar activos"
                            aria-describedby="asset-filter"
                            onChange={ (e) => {handleSearchAssets(e.target.value)} }
                            value={word}
                        />
                        <InputGroup.Text id="asset-filter">{ word !== '' ? <MdClear onClick={ () => {handleClearSearch()} } /> : <MdSearch />}</InputGroup.Text>
                    </InputGroup>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Activo</th>
                                <th>Acciones</th>   
                            </tr>
                        </thead>
                        { assetsArray.results?.length > 0 &&
                            <tbody>
                                { assetsArray?.results?.map((asset) => (
                                    <tr key={asset.id}>
                                        <td>{asset.code}</td>
                                        <td>{asset.name}</td>
                                        <td>
                                            <div className="home-assets-list-table__action">
                                                <MdModeEdit onClick={() => { props.handleAssetsTableAction(asset.id, 'edit_asset') }} />
                                                <MdSearch onClick={() => { props.handleAssetsTableAction(asset.id, 'view_asset') }} />
                                                {/* <MdDelete onClick={() => { console.log('delete_asset') }}/> */}
                                            </div>
                                        </td>
                                    </tr>
                                ))}                                
                            </tbody> 
                        }                                            
                    </Table>
                    { assetsArray.results?.length === 0 &&
                        <div className="home-assets-list__no-results"><h5>No hay resultados</h5></div>
                    }
                    <div className="home-novelties-table__actions-container">
                        { assetsArray?.previous && <Button variant="primary" onClick={() => { dispatch(getAssets(assetsArray.previous)) }}>Anterior</Button> }
                        { assetsArray?.next && <Button variant="primary" onClick={() => { dispatch(getAssets(assetsArray.next)) }}>Siguiente</Button> }
                    </div>
                </Card.Body>
            </Card>
            
        </div>
    )
}

export default HomeAssetsListComponent;