import React from "react";

// React redux
import { useDispatch } from 'react-redux';
import { patchTransferWareHouseMan } from "../../slice/HomeSlice";

// Bootstrap
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Components
import ModalComponent from "../../../../shared/components/modal-web/ModalComponent";

// Styles
import './HomeRoleActionModalComponent.styles.css'

export default function HomeRoleActionModalComponent (props) {

    // React redux states
	const dispatch = useDispatch();

	// Component states
    const [counter, setCounter] = React.useState(0);
    const [onChangeCheck, setOnChangeCheck] = React.useState(false);


    const submitForm = (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			const formElement = document.querySelector('#roleActionForm');
			const formData = new FormData(formElement);
			const formDataJSON = Object.fromEntries(formData);
            let obj = {
                transfer_id: props.transferId,
                body: {
                    authorized_exit_guard: onChangeCheck,
                    observations_warehouseman: formDataJSON.observations_warehouseman,
                }
            }
            dispatch(patchTransferWareHouseMan(obj));
            setCounter(0);
            props.onHide();
		}
    }

    const handleOnChange = (e) => {
        setOnChangeCheck(e.target.checked)
    }


    return (
        <ModalComponent 
            show={props.show}
            onHide={props.onHide}
            title='Autorizar salida'
        >
            <Form
                id="roleActionForm" 
                onSubmit={submitForm}                
            >       
                <Form.Check
                    type='checkbox'
                    id='role-action-check'
                    label='Autorizo salida'
                    className="mt-3"
                    name="authorized_exit_guard"
                    onChange={handleOnChange}
                    required
                />
                <Form.Group className="mb-3 mt-3">
                    <div className="home-finalize-transfer-modal__label">
                        <Form.Label>Observaciones</Form.Label>
                        <span>{`${counter}/200`}</span>
                    </div>
                    <Form.Control 
                        maxLength={200}
                        as="textarea"
                        rows={3}
                        name='observations_warehouseman'
                        onChange={(e) => setCounter(e.target.value.length)}
                        required
                    />
                </Form.Group>

                <div className="home-finalize-transfer-modal__btns-action">
                    <Button 
                        variant="secondary"
                        onClick={() => {
                            props.onHide();
                            setCounter(0);
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button variant="primary" type="submit">Guardar</Button>
                </div>
            </Form>
        </ModalComponent>
    )
}