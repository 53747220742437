// React
import React from "react";

// Bootstrap
import { Form } from "react-bootstrap";

// Moment
import * as moment from 'moment';

// Styles
import './HomeDynamicInputComponent.styles.css';


function HomeDynamicInputComponent ({
    form_question,
    id,
    caption,
    is_required,
    answers,
    noveltyAction,
    onChange,
}) {

    const [defaultValue, setDefaultValue] = React.useState(null);
    const [inputType, setInputType] = React.useState();
    const current_date = moment().format("YYYY-MM-DD");

    React.useEffect(() => {
        if(answers) {            
            switch (answers.question_type) {
                case 'Date':
                    setDefaultValue(answers.date_field);
                    break;
                case 'Text':
                    setDefaultValue(answers.text_answer);
                    break;           
                default:
                    break;
            }
        }
        
    }, [answers]);

    React.useState(() => {
        let set_input_type = 'text';
        if(form_question?.jsonSchema && form_question.jsonSchema.only_numbers) {
            set_input_type = 'number';
        } else {
            set_input_type = 'text';
        }
        if(form_question?.jsonSchema && form_question.jsonSchema.date_format) {
            if(noveltyAction !== 'edit_novelty' && noveltyAction !== 'view_novelty' && form_question.jsonSchema.dateIsDefault && form_question.jsonSchema.dateIsDefault === true ) {
                setDefaultValue(current_date);
            }
            if(form_question.jsonSchema.date_format === 'Date') {
                set_input_type = 'date';
            }
            if(form_question?.jsonSchema.date_format === 'DateTime') {
                set_input_type = 'datetime-local';
            }
            if(form_question?.jsonSchema.date_format === 'Time') {
                set_input_type = 'time';
            }            
        }
        setInputType(set_input_type);

    }, [form_question?.jsonSchema])



    const handleOnChange = (event) => {
        if(onChange) {
            onChange(event);
        }
    }

    const handleKeyDown = (e) => {
        if(inputType === 'date' || inputType === 'time') {
            e.preventDefault();
        }
    }
    
    return (    
        <Form.Group className="mb-3" key={id}>
            <Form.Label>{caption}</Form.Label>
            <Form.Control 
                type={inputType}
                name={id}
                required={is_required}
                defaultValue={defaultValue}
                readOnly={ noveltyAction === 'view_novelty' ? true : false}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
            />  
            <Form.Control.Feedback type="invalid">
                Campo requerido
            </Form.Control.Feedback>          
        </Form.Group>            
    )
}

export default HomeDynamicInputComponent
