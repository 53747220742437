// React
import React from "react";

// Icons
import { MdOutlineCancel, MdCloudUpload, MdFilePresent } from 'react-icons/md';
import { VscFilePdf } from "react-icons/vsc";
import { IoMdCloseCircle } from "react-icons/io";

// Bootstrap
import { Form } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// Styles
import './HomeDynamicInputFileComponent.styles.css'

function HomeDynamicInputFileComponent ({
    id,
    caption,
    handleFiles,
    is_required,
    multiple,
    noveltyAction,
    answers,
    handleViewFiles,
    form_data,
    handleDeleteNoveltyFile,
}) {

    const fileInput = React.createRef();
    const dataTransfer = new DataTransfer();

    // Component states
    const [filesArray, setFilesArray] = React.useState([]);

    const handleSpliceFile = (file_name) => {        
        const indexToRemove = filesArray.findIndex(file => file.name === file_name);        
        if (indexToRemove > -1) {
            filesArray.splice(indexToRemove, 1);
            for (let i = 0; i < filesArray.length; i++) {
                dataTransfer.items.add(filesArray[i]);
            }
            const fileList = dataTransfer.files;
            handleFiles(fileList);
        }
    }

    const renderTooltip = (props, file_name) => {
        return (
            <Tooltip id="button-tooltip" {...props}>{file_name}</Tooltip>
      )};

    const handleOnFiles = (current_files) => {
        const currentFiles = Array.from(current_files);
        let newCurrentFiles = [];
        if(filesArray.length === 0) {
            setFilesArray(currentFiles);
            handleFiles(current_files);
        } else {
            for (let i = 0; i < filesArray.length; i++) {
                dataTransfer.items.add(filesArray[i]);
            }
            for (let i = 0; i < current_files.length; i++) {
                if(filesArray.some(file => file.name !== current_files[i].name)) {   
                    dataTransfer.items.add(current_files[i]);
                    newCurrentFiles.push(current_files[i]);
                } else {
                    console.log('el archivo', current_files[i].name, ' ya existe ')
                }       
            }
            const new_array = [...filesArray, ...newCurrentFiles]
            const fileList = dataTransfer.files;
            setFilesArray(new_array);
            handleFiles(fileList);
        }
    }

    
    return (    
        <Form.Group className="mb-3">
            <Form.Label>{caption}</Form.Label>
            <div className="home-dynamic-input-file__custom">
                { noveltyAction !== 'view_novelty' && <div className="home-dynamic-input-file__custom-label__container" ><MdCloudUpload/><label className="home-dynamic-input-file__custom-label" htmlFor="inputGroupFile">Subir archivos</label></div> }
                { noveltyAction === 'view_novelty' && <div className="home-dynamic-input-file__custom-label__container" ><MdFilePresent/><label className="home-dynamic-input-file__custom-label">Archivos</label></div> }
                <input 
                    style={{display: "none"}}
                    type="file"
                    multiple={true}
                    id="inputGroupFile"
                    name={id}
                    onChange={() => { handleOnFiles(fileInput.current.files) }}
                    ref={fileInput}
                    required={ noveltyAction === 'edit_novelty' ? false : is_required}
                    accept="image/*,.pdf"
                />
                <div className="home-dynamic-input-file__custom__files-array" >
                    { noveltyAction !== 'view_novelty' && noveltyAction !== 'edit_novelty' &&
                        <div className="home-dynamic-input-file__custom__files-array__container">
                            { filesArray
                                ?
                                filesArray?.map( file => ( <div key={file.name} className="home-dynamic-input-file__custom__files-array__file">{file.name}<MdOutlineCancel onClick={() => {handleSpliceFile(file.name)}} /></div>))
                                :
                                <div>No hay archivos seleccionados</div>
                            }
                        </div>
                    }
                    { (noveltyAction === 'view_novelty' || noveltyAction === 'edit_novelty' ) &&
                        <div className="home-dynamic-input-file__custom__files-array__preview">
                            { answers?.file_document?.map((file, index) => (
                                <div className="home-dynamic-input-file__custom__files-array__preview-container" key={index}>
                                        { file.name_file.includes('.pdf')
                                            ?
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 300 }}
                                                overlay={(props) => renderTooltip(props, file.name_file)}
                                            >   
                                                <div>
                                                    <div className="home-dynamic-input-file__custom__files-array__preview-container__file">
                                                        <VscFilePdf 
                                                            key={file.name_file} 
                                                            onClick={() => { handleViewFiles(answers?.file_document, index, form_data) }}
                                                            className="home-dynamic-input-file__custom__files-array__preview-container__file__pdf"
                                                        />
                                                        { noveltyAction === 'edit_novelty' && <IoMdCloseCircle onClick={() => { handleDeleteNoveltyFile(file.novelty_file_id) }} className="home-dynamic-input-file__custom__files-array__preview-container__file__delete" />}
                                                    </div>
                                                    <div className="home-dynamic-input-file__custom__files-array__preview-container__file-name">{file.name_file}</div>
                                                </div>
                                            </OverlayTrigger>
                                            :
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={(props) => renderTooltip(props, file.name_file)}
                                            >   
                                                <div>
                                                    <div className="home-dynamic-input-file__custom__files-array__preview-container__file">
                                                        <img alt={`img_${index}`} key={file.name_file} src={file.url} onClick={() => { handleViewFiles(answers?.file_document, index, form_data) }}/>
                                                        { noveltyAction === 'edit_novelty' && <IoMdCloseCircle onClick={() => { handleDeleteNoveltyFile(file.novelty_file_id) }} className="home-dynamic-input-file__custom__files-array__preview-container__file__delete" />}
                                                    </div>
                                                    <div className="home-dynamic-input-file__custom__files-array__preview-container__file-name">{file.name_file}</div>
                                                </div>
                                            </OverlayTrigger>
                                        }
                                </div>
                                ))
                            }
                            { filesArray &&
                                filesArray?.map( file => ( <div key={file.name} className="home-dynamic-input-file__custom__files-array__file">{file.name}<MdOutlineCancel onClick={() => {handleSpliceFile(file.name)}} /></div>))
                            }
                        </div>
                    }
                </div>
            </div>
            <Form.Control.Feedback type="invalid">Campo obligatorio.</Form.Control.Feedback>
        </Form.Group>
    )
}

export default HomeDynamicInputFileComponent
