// React
import React from "react";

// React redux
import { useSelector } from 'react-redux';

// Moment
import * as moment from 'moment';

// Icons
import { MdOutlineBorderColor, MdOutlineFormatListNumbered, MdLibraryAdd, MdListAlt, MdSyncAlt, MdOutlineEditCalendar } from 'react-icons/md';

// Locale
import 'moment/locale/en-au';
import 'moment/locale/es';

import './HomeDashboardComponent.styles.css';


function HomeDashboardComponent (props) {

    // Component states
    const [currentTime, setCurrentTime] = React.useState(new Date());

    // React home redux states
	const home_user = useSelector(state => state.home.user);

    React.useEffect(() => {
        setCurrentTime(new Date());
        
      }, []);

    return (
        <div className="home-dashboard__container">
            <h4 className="home-dashboard__title">Panel de Control Interglobal</h4>   
            <div className="home-dashboard__card title">                
                    <h5>{`Bienvenido ${home_user?.username}!`}</h5>
                    <span>{moment(currentTime).format("MMM D YYYY")}</span>                    
            </div> 
            <div className="home-dashboard__card-container">                
                { (home_user?.role === 'Supervisor' || home_user?.role === 'User' || home_user?.role === 'Admin') &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('create_novelty')}}>                
                        <MdOutlineBorderColor />
                        <h5>Crear Novedad</h5>
                    </div>
                }
                { home_user?.role === 'Formatos' &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('create_format')}}>                
                        <MdOutlineBorderColor />
                        <h5>Crear Formato</h5>
                    </div>
                }
                { (home_user?.role === 'Supervisor' || home_user?.role === 'User' || home_user?.role === 'Admin') &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('novelties_list')}}>                
                        <MdOutlineFormatListNumbered />
                        <h5>Lista Novedades</h5>                
                    </div>
                }
                { (home_user?.role === 'Despachador' || home_user?.role === 'Recibidor' || home_user?.role === 'Admin') &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('new_asset')}}>                
                        <MdLibraryAdd />
                        <h5>Crear Activo</h5>                
                    </div>
                }
                { (home_user?.role === 'Despachador' || home_user?.role === 'Recibidor' || home_user?.role === 'Admin') &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('list_assets')}}>                
                        <MdListAlt />
                        <h5>Lista Activos</h5>                
                    </div>
                }
                { (home_user?.role === 'Despachador' || home_user?.role === 'Recibidor' || home_user?.role === 'Admin') &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('transfer_asset')}}>                
                        <MdSyncAlt />
                        <h5>Crear Traslado</h5>                
                    </div>
                }
                { (home_user?.role === 'Despachador' || home_user?.role === 'Recibidor' || home_user?.role === 'Admin' || home_user?.role === 'Vigilante') &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('manage_transfer')}}>                
                        <MdOutlineEditCalendar />
                        <h5>Gestionar Traslado</h5>                
                    </div>
                }
                { home_user?.role === 'Admin' &&
                    <div className="home-dashboard__card" onClick={() => {props.handleNavbarLink('geo_map')}}>                
                        <MdOutlineEditCalendar />
                        <h5>Motorizados</h5>                
                    </div>
                }
            </div>
        </div>
    )

}

export default HomeDashboardComponent;